import React, { useState, useEffect } from "react";
import { motion } from 'framer-motion';
import { storage, db } from '../firebaseconf';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { ref as dbRef, set, get } from 'firebase/database';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

function MimicVideoUpload() {
  const { user } = useAuth();
  const [mimicVideo, setMimicVideo] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVideoURL = async () => {
      const dbReference = dbRef(db, `users/${user.uid}/profilecv/mimicVideo`);
      const snapshot = await get(dbReference);
      if (snapshot.exists()) {
        setVideoPreview(snapshot.val());
      }
    };
    fetchVideoURL();
  }, [user.uid]);

  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setMimicVideo(file);
      setVideoPreview(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async () => {
    if (!mimicVideo) {
      alert('Lütfen bir mimik videosu seçin.');
      return;
    }

    setUploading(true);
    setUploadProgress(0);

    try {
      const storageRef = ref(storage, `mimicVideos/${user.uid}/${mimicVideo.name}`);
      const uploadTask = uploadBytesResumable(storageRef, mimicVideo);

      uploadTask.on('state_changed', 
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        }, 
        (error) => {
          console.error('Upload error:', error);
          alert('Mimik videosu yüklenirken bir hata oluştu.');
          setUploading(false);
        }, 
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          const dbReference = dbRef(db, `users/${user.uid}/profilecv/mimicVideo`);
          await set(dbReference, downloadURL);
          alert('Mimik videosu başarıyla yüklendi ve kaydedildi!');
          setUploading(false);
          setVideoPreview(downloadURL);
        }
      );
    } catch (error) {
      console.error('Error:', error);
      alert('Bir hata oluştu. Lütfen tekrar deneyin.');
      setUploading(false);
    }
  };

  return (
    <div className="relative flex size-full min-h-screen flex-col bg-white group/design-root overflow-x-hidden" style={{ fontFamily: "'Be Vietnam Pro', 'Noto Sans', sans-serif" }}>
      <div className="flex items-center bg-neutral-50 p-4 pb-2 justify-between">
        <div className="text-[#141414] flex size-12 shrink-0 items-center" onClick={() => navigate(-1)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
            <path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path>
          </svg>
        </div>
        <h2 className="text-[#141414] text-lg font-bold leading-tight tracking-[-0.015em] flex-1 text-center">Mimik Videosu</h2>
        <div className="flex w-12 items-center justify-end">
          <button onClick={handleSubmit} disabled={uploading || !mimicVideo} className="text-neutral-500 text-base font-bold leading-normal tracking-[0.015em] shrink-0">
            Kaydet
          </button>
        </div>
      </div>

      <div className="p-4">
        <motion.div
          className="relative flex items-center justify-center bg-[#141414] bg-cover bg-center aspect-video rounded-xl p-4"
          style={{
            backgroundImage: videoPreview && !uploading ? `url(${videoPreview})` : "none",
          }}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          onClick={() => document.getElementById('mimic-video').click()}
        >
          {uploading ? (
            <div className="text-white text-center">
              <p>Mimik videosu yükleniyor...</p>
              <p>{uploadProgress.toFixed(2)}%</p>
            </div>
          ) : videoPreview ? (
            <video src={videoPreview} className="w-full h-full rounded-lg" />
          ) : (
            <button className="flex shrink-0 items-center justify-center rounded-full size-16 bg-black/40 text-white">
              <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
                <path d="M240,128a15.74,15.74,0,0,1-7.6,13.51L88.32,229.65a16,16,0,0,1-16.2.3A15.86,15.86,0,0,1,64,216.13V39.87a15.86,15.86,0,0,1,8.12-13.82,16,16,0,0,1,16.2.3L232.4,114.49A15.74,15.74,0,0,1,240,128Z"></path>
              </svg>
            </button>
          )}
        </motion.div>
      </div>

      <div className="flex justify-center">
        <div className="flex flex-1 gap-3 flex-wrap px-4 py-3 max-w-[480px] justify-center">
          <input
            id="mimic-video"
            type="file"
            accept="video/*"
            onChange={handleVideoChange}
            className="hidden"
          />
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => document.getElementById('mimic-video').click()}
            className="flex min-w-[84px] max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-12 px-5 bg-[#f0f0f0] text-[#141414] text-base font-bold leading-normal tracking-[0.015em] grow"
          >
            <span className="truncate">Mimik Videosu Seç</span>
          </motion.button>
        </div>
      </div>
    </div>
  );
}

export default MimicVideoUpload;

