import React from 'react';

const StepProgress = ({ currentStep, totalSteps }) => (
  <div className="relative pt-1 px-4">
    <div className="flex mb-2 items-center justify-between">
      <div className="text-sm font-semibold inline-block py-1 px-2 uppercase rounded-full text-stone-600 bg-stone-200">
        {currentStep + 1} / {totalSteps}
      </div>
    </div>
    <div className="flex h-2 mb-4">
      <div
        className="bg-stone-600 h-full rounded-full"
        style={{ width: `${((currentStep + 1) / totalSteps) * 100}%` }}
      />
    </div>
  </div>
);

export default StepProgress;
