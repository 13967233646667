import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { db } from '../firebaseconf';
import { ref, set, get } from 'firebase/database';
import CitySelect from '../components/CitySelect';
import CVHeader from '../components/CVHeader';
import StepProgress from '../components/StepProgress';
import FormField from '../components/FormField';
import SkillsSelector from '../components/SkillsSelector';

const CVPage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({
    positions: [],
    birthDate: '',
    city: '',
    gender: '',
    height: '',
    weight: '',
    bodySize: '',
    shoeSize: '',
    hairColor: '',
    hairStyle: '',
    beardStyle: '',
    skinTone: '',
    eyeColor: '',
    institution: '',
    department: '',
    startYear: '',
    endYear: '',
    skills: [],
  });

  useEffect(() => {
    const fetchCVData = async () => {
      try {
        const userProfilePath = `users/${user.uid}/profilecv/globalcv`;
        const snapshot = await get(ref(db, userProfilePath));
        if (snapshot.exists()) {
          const data = snapshot.val();
          setFormData(prevData => ({
            ...prevData,
            ...data
          }));
        }
      } catch (error) {
        console.error('CV verisi çekilirken hata oluştu:', error);
      }
    };

    fetchCVData();
  }, [user.uid]);

  const handleChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSkillToggle = (skill) => {
    setFormData(prev => ({
      ...prev,
      skills: prev.skills.includes(skill)
        ? prev.skills.filter(s => s !== skill)
        : [...prev.skills, skill]
    }));
  };

  const steps = [
    {
      title: "Kişisel Bilgiler",
      fields: [
        { 
          name: "positions", 
          label: "Pozisyonlar", 
          type: "multiSelect",
          options: [
            { id: 'actor', label: 'Oyuncu' },
            { id: 'model', label: 'Model' },
            { id: 'supporting_actor', label: 'Yardımcı Oyuncu' }
          ],
          required: true,
          description: "Birden fazla seçim yapabilirsiniz"
        },
        { 
          name: "tcNo", 
          label: "TC Kimlik No", 
          type: "text", 
          required: true,
          pattern: "^[0-9]{11}$",
          maxLength: 11,
          minLength: 11,
          placeholder: "11 haneli TC Kimlik No giriniz"
        },
        { 
          name: "birthDate", 
          label: "Doğum Tarihi", 
          type: "date",
          required: true 
        },
        { 
          name: "city", 
          label: "Şehir", 
          type: "city",
          required: true 
        },
        { 
          name: "gender", 
          label: "Cinsiyet", 
          type: "select", 
          options: ["Erkek", "Kadın", "Diğer", "Belirtmek İstemiyorum"],
          required: true 
        },
        { 
          name: "height", 
          label: "Boy (cm)", 
          type: "select", 
          options: Array.from({length: 81}, (_, i) => (140 + i).toString()),
          required: true 
        },
        { 
          name: "weight", 
          label: "Kilo (kg)", 
          type: "select", 
          options: Array.from({length: 101}, (_, i) => (40 + i).toString()),
          required: true 
        },
      ]
    },
    {
      title: "Fiziksel Özellikler",
      fields: [
        { name: "bodySize", label: "Beden", type: "select", options: ["XS", "S", "M", "L", "XL", "XXL"] },
        { name: "shoeSize", label: "Ayakkabı Numarası", type: "select", options: Array.from({length: 15}, (_, i) => (35 + i).toString()) },
        { name: "hairColor", label: "Saç Rengi", type: "select", options: ["Siyah", "Kahverengi", "Sarı", "Kızıl", "Gri"] },
        { name: "hairStyle", label: "Saç Şekli", type: "select", options: ["Düz", "Dalgalı", "Kıvırcık", "Kel"] },
        { name: "beardStyle", label: "Sakal Şekli", type: "select", options: ["Yok", "Kısa", "Orta", "Uzun"] },
        { name: "skinTone", label: "Ten Rengi", type: "select", options: ["Açık", "Orta", "Koyu"] },
        { name: "eyeColor", label: "Göz Rengi", type: "select", options: ["Kahverengi", "Mavi", "Yeşil", "Ela"] },
      ]
    },
    {
      title: "Eğitim Bilgileri",
      fields: [
        { name: "institution", label: "Kurum / Okul", type: "text" },
        { name: "department", label: "Bölüm", type: "text" },
        { name: "startYear", label: "Başlangıç Yılı", type: "select", options: Array.from({length: 50}, (_, i) => (1980 + i).toString()) },
        { name: "endYear", label: "Bitiş Yılı", type: "select", options: Array.from({length: 50}, (_, i) => (1980 + i).toString()) },
      ]
    },
    {
      title: "Yetenekler",
      skills: ['Akordeon', 'Bağlama', 'Bateri', 'Gitar', 'Flüt', 'Piyano', 'Vokal']
    }
  ];

  const renderStep = () => {
    const currentStep = steps[step];
    return (
      <div>
        <h2 className="text-2xl font-bold mb-6">{currentStep.title}</h2>
        {currentStep.fields && currentStep.fields.map(field => (
          <div key={field.name} className="mb-4">
            <FormField
              field={field}
              value={formData[field.name]}
              onChange={handleChange}
            />
          </div>
        ))}
        {step === 3 && (
          <SkillsSelector
            skills={currentStep.skills}
            selectedSkills={formData.skills}
            onToggle={handleSkillToggle}
          />
        )}
      </div>
    );
  };

  const handleSave = async () => {
    const allRequiredFields = steps.flatMap(step => 
      (step.fields || []).filter(field => field.required)
    );
    
    const hasEmptyRequiredFields = allRequiredFields.some(field => {
      if (field.name === 'positions') {
        return formData[field.name].length === 0;
      }
      return !formData[field.name];
    });
    
    if (hasEmptyRequiredFields) {
      alert('Lütfen tüm zorunlu alanları doldurunuz');
      return;
    }

    // TC Kimlik kontrolü
    if (formData.tcNo && formData.tcNo.length !== 11) {
      alert('TC Kimlik No 11 haneli olmalıdır');
      return;
    }

    try {
      // Veriyi kaydetme işlemi
      const userProfilePath = `users/${user.uid}/profilecv/globalcv`;
      await set(ref(db, userProfilePath), formData);
      console.log('Veri başarıyla kaydedildi:', userProfilePath, formData);
      
      // Başarılı kaydetme sonrası kullanıcıya bildirim göster
      alert('CV başarıyla kaydedildi!');
      
      // Profil sayfasına yönlendir
      navigate('/profile'); // Profil sayfanızın yolu '/profile' olarak varsayılmıştır
    } catch (error) {
      console.error('Veri kaydetme hatası:', error);
      // Hata durumunda kullanıcıya bir bildirim göster
      alert('CV kaydedilirken bir hata oluştu. Lütfen tekrar deneyin.');
    }
  };

  const handleNext = () => {
    const currentStepFields = steps[step].fields || [];
    const requiredFields = currentStepFields.filter(field => field.required);
    
    const hasEmptyRequiredFields = requiredFields.some(field => {
      if (field.name === 'positions') {
        return formData[field.name].length === 0;
      }
      return !formData[field.name];
    });
    
    if (hasEmptyRequiredFields) {
      alert('Lütfen tüm zorunlu alanları doldurunuz');
      return;
    }

    // TC Kimlik kontrolü
    if (step === 0 && formData.tcNo && formData.tcNo.length !== 11) {
      alert('TC Kimlik No 11 haneli olmalıdır');
      return;
    }

    if (step < steps.length - 1) {
      setStep(step + 1);
    }
  };

  const handlePrevious = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const getAdditionalFields = () => {
    const additionalFields = [];
    
    if (formData.positions.includes('Model')) {
      additionalFields.push(
        { name: "modelExperience", label: "Modellik Deneyimi", type: "select", options: ["1 yıldan az", "1-3 yıl", "3+ yıl"] }
      );
    }
    
    if (formData.positions.includes('Oyuncu')) {
      additionalFields.push(
        { name: "actingExperience", label: "Oyunculuk Deneyimi", type: "select", options: ["1 yıldan az", "1-3 yıl", "3+ yıl"] }
      );
    }
    
    return additionalFields;
  };

  return (
    <div className="relative flex size-full min-h-screen flex-col bg-white group/design-root overflow-x-hidden">
      <CVHeader onBack={() => navigate(-1)} onSave={handleSave} />
      <StepProgress currentStep={step} totalSteps={steps.length} />
      
      <div className="bg-white p-6 rounded-lg shadow-sm mb-20">
        {renderStep()}
        
        <div className="flex justify-between mt-6">
          {step > 0 && (
            <button
              onClick={handlePrevious}
              className="px-4 py-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300"
            >
              Geri
            </button>
          )}
          {step < steps.length - 1 ? (
            <button
              onClick={handleNext}
              className={`px-4 py-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 ${step === 0 ? 'ml-auto' : ''}`}
            >
              İleri
            </button>
          ) : (
            <button
              onClick={handleSave}
              className={`px-4 py-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 ${step === 0 ? 'ml-auto' : ''}`}
            >
              Kaydet
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CVPage;
