import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

const About = () => {
  const navigate = useNavigate();

  return (
    <motion.div 
      className="min-h-screen bg-white flex flex-col"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <motion.div 
        className="flex items-center bg-white p-4 pb-2 justify-between"
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <motion.div
          className="text-[#141414] flex size-12 shrink-0 items-center cursor-pointer"
          onClick={() => navigate(-1)}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24px"
            height="24px"
            fill="currentColor"
            viewBox="0 0 256 256"
          >
            <path d="M224,128a8,8,0,0,1-8,8H59.31l58.35,58.34a8,8,0,0,1-11.32,11.32l-72-72a8,8,0,0,1,0-11.32l72-72a8,8,0,0,1,11.32,11.32L59.31,120H216A8,8,0,0,1,224,128Z"></path>
          </svg>
        </motion.div>
        <h2 className="text-[#141414] text-lg font-bold leading-tight tracking-[-0.015em] flex-1 text-center pr-12">
          Hakkımızda
        </h2>
      </motion.div>

      <motion.div 
        className="flex-1 p-4 md:p-6 overflow-y-auto"
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <p className="mb-4">
          [Şirket Adı] olarak, kullanıcılarımıza en iyi deneyimi sunmayı hedefliyoruz. 
          Uygulamamız, kullanıcıların ihtiyaçlarına yönelik tasarlanmış ve sürekli olarak 
          geliştirilmektedir. Amacımız, kullanıcılarımızın hayatını kolaylaştırmak ve 
          onlara değerli hizmetler sunmaktır.
        </p>

        <h2 className="text-xl font-semibold mb-2">Misyonumuz</h2>
        <p className="mb-4">
          Kullanıcılarımızın ihtiyaçlarını anlamak ve onlara en iyi çözümleri sunmak. 
          Yenilikçi ve kullanıcı dostu bir platform oluşturarak, herkesin erişebileceği 
          hizmetler sunmayı amaçlıyoruz.
        </p>

        <h2 className="text-xl font-semibold mb-2">Vizyonumuz</h2>
        <p className="mb-4">
          Teknolojinin gücünü kullanarak, kullanıcılarımızın hayatını kolaylaştıran 
          çözümler geliştirmek. Sektörümüzde lider bir konuma gelmek ve kullanıcılarımızın 
          güvenini kazanmak.
        </p>

        <h2 className="text-xl font-semibold mb-2">Değerlerimiz</h2>
        <ul className="list-disc list-inside mb-4">
          <li>Dürüstlük</li>
          <li>Yenilikçilik</li>
          <li>Müşteri Odaklılık</li>
          <li>Takım Çalışması</li>
          <li>Sürdürülebilirlik</li>
        </ul>

        <h2 className="text-xl font-semibold mb-2">İletişim</h2>
        <p>
          Bizimle iletişime geçmek için lütfen <a href="/contact" className="text-blue-500 underline">İletişim</a> sayfamızı ziyaret edin.
        </p>
      </motion.div>
    </motion.div>
  );
};

export default About;
