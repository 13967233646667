import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

const Help = () => {
  const navigate = useNavigate();

  const menuItems = [
    { title: 'Hakkımızda', path: '/about' },
    { title: 'Sıkça Sorulan Sorular', path: '/faq' },
    { title: 'Kullanım Koşulları', path: '/terms' },
    { title: 'İletişim', path: '/contact' },
    { title: 'Şikayet ve Öneri', path: '/feedback' },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { 
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { 
      y: 0, 
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100
      }
    }
  };

  return (
    <motion.div
      className="relative flex min-h-screen flex-col bg-white overflow-x-hidden"
      style={{ fontFamily: '"Public Sans", "Noto Sans", sans-serif' }}
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      {/* Header */}
      <motion.div 
        className="flex items-center bg-white p-4 pb-2 justify-between"
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <motion.div
          className="text-[#141414] flex size-12 shrink-0 items-center cursor-pointer"
          onClick={() => navigate(-1)}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24px"
            height="24px"
            fill="currentColor"
            viewBox="0 0 256 256"
          >
            <path d="M224,128a8,8,0,0,1-8,8H59.31l58.35,58.34a8,8,0,0,1-11.32,11.32l-72-72a8,8,0,0,1,0-11.32l72-72a8,8,0,0,1,11.32,11.32L59.31,120H216A8,8,0,0,1,224,128Z"></path>
          </svg>
        </motion.div>
        <h2 className="text-[#141414] text-lg font-bold leading-tight tracking-[-0.015em] flex-1 text-center pr-12">
          Yardım Merkezi
        </h2>
      </motion.div>

      {/* Menu Items */}
      {menuItems.map((item, index) => (
        <motion.div
          key={index}
          className="flex items-center gap-4 bg-white px-4 min-h-14 justify-between cursor-pointer"
          onClick={() => navigate(item.path)}
          variants={itemVariants}
          whileHover={{ backgroundColor: "#f0f0f0" }}
          whileTap={{ scale: 0.98 }}
        >
          <p className="text-[#141414] text-base font-normal leading-normal flex-1 truncate">
            {item.title}
          </p>
          <motion.div 
            className="shrink-0"
            whileHover={{ x: 5 }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
          >
            <div
              className="text-[#141414] flex size-7 items-center justify-center"
            >
               <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24px"
                height="24px"
                fill="currentColor"
                viewBox="0 0 256 256"
              >
                <path d="M181.66,133.66l-80,80a8,8,0,0,1-11.32-11.32L164.69,128,90.34,53.66a8,8,0,0,1,11.32-11.32l80,80A8,8,0,0,1,181.66,133.66Z"></path>
              </svg>
            </div>
          </motion.div>
        </motion.div>
      ))}
    </motion.div>
  );
};

export default Help;
