import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { motion } from 'framer-motion';

const db = getDatabase();

const Campaigns = () => {
  const [campaigns, setCampaigns] = useState([]);
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (!user || !user.uid) {
      navigate('/login');
      return;
    }

    const campaignsRef = ref(db, 'campaigns');
    const unsubscribe = onValue(campaignsRef, (snapshot) => {
      const data = snapshot.val();
      const loadedCampaigns = [];
      for (let id in data) {
        loadedCampaigns.push({ id, ...data[id] });
      }
      setCampaigns(loadedCampaigns.reverse());
    });

    return () => unsubscribe();
  }, [user, navigate]);

  const handleCampaignClick = (id) => {
    navigate(`/campaigns/${id}`);
  };

  return (
    <motion.div 
      className="min-h-screen bg-neutral-50 flex flex-col"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <motion.div 
        className="flex items-center bg-white p-4 pb-2 justify-between shadow-sm"
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <motion.div
          className="text-[#141414] flex size-12 shrink-0 items-center cursor-pointer"
          onClick={() => navigate(-1)}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24px"
            height="24px"
            fill="currentColor"
            viewBox="0 0 256 256"
          >
            <path d="M224,128a8,8,0,0,1-8,8H59.31l58.35,58.34a8,8,0,0,1-11.32,11.32l-72-72a8,8,0,0,1,0-11.32l72-72a8,8,0,0,1,11.32,11.32L59.31,120H216A8,8,0,0,1,224,128Z"></path>
          </svg>
        </motion.div>
        <h2 className="text-[#141414] text-lg font-bold leading-tight tracking-[-0.015em] flex-1 text-center pr-12">
          Kampanyalar
        </h2>
      </motion.div>

      <motion.div 
        className="flex-1 overflow-y-auto px-4 py-6"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3 }}
      >
        {campaigns.length > 0 ? (
          campaigns.map((campaign, index) => (
            <motion.div 
              key={campaign.id} 
              className="bg-white rounded-lg shadow-sm mb-4 overflow-hidden cursor-pointer"
              onClick={() => handleCampaignClick(campaign.id)}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <img 
                src={campaign.imageUrl} 
                alt={campaign.title} 
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h3 className="text-lg font-semibold mb-2">{campaign.title}</h3>
                <p className="text-sm text-gray-600 mb-2">{campaign.description}</p>
                <div className="flex justify-between items-center text-sm">
                  <span className="text-blue-600 font-medium">
                    {new Date(campaign.startDate).toLocaleDateString()} - {new Date(campaign.endDate).toLocaleDateString()}
                  </span>
                  <span className={`px-2 py-1 rounded-full ${campaign.isActive ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                    {campaign.isActive ? 'Aktif' : 'Sona Erdi'}
                  </span>
                </div>
              </div>
            </motion.div>
          ))
        ) : (
          <motion.p 
            className="text-neutral-500 text-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
          >
            Şu anda aktif kampanya bulunmamaktadır.
          </motion.p>
        )}
      </motion.div>
    </motion.div>
  );
};

export default Campaigns;

