import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
  HiOutlineHome,
  HiOutlineUsers,
  HiOutlineUser,
  HiOutlineBriefcase,
  HiOutlineCog,
  HiOutlineChartBar,
  HiOutlineBell,
  HiOutlineCash,
  HiOutlineChevronLeft,
  HiOutlineCalendar,
  HiOutlineDocumentReport,
  HiOutlineLightningBolt,
  HiOutlineMail,
  HiOutlineShieldCheck,
  HiOutlinePhotograph,
  HiOutlineClipboardList
} from 'react-icons/hi';
import KlaketLogo from '../assets/logo.svg';
import AdminHeader from './AdminHeader';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const AdminLayout = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [showProjectMenu, setShowProjectMenu] = useState(false);

  const menuItems = [
    {
      title: 'Genel Bakış',
      icon: <HiOutlineHome size={22} />,
      path: '/admin/dashboard',
    },
    {
      title: 'Kullanıcı Yönetimi',
      icon: <HiOutlineUsers size={22} />,
      path: '/admin/users',
      badge: '12'
    },
    {
      title: 'Proje Yönetimi',
      icon: <HiOutlineBriefcase size={22} />,
      hasSubmenu: true,
      badge: 'Yeni',
      submenuItems: [
        {
          title: 'Tüm Projeler',
          path: '/admin/projects',
        },
        {
          title: 'Yeni Proje',
          path: '/admin/projects/add',
        },
        {
          title: 'Proje Kategorileri',
          path: '/admin/projects/categories',
        }
      ]
    },
    {
      title: 'Seçili Kullanıcılar',
      icon: <HiOutlineUser size={22} />,
      path: '/admin/selected-users',
    },
    {
      title: 'Medya Galerisi',
      icon: <HiOutlinePhotograph size={22} />,
      path: '/admin/media',
    },
    {
      title: 'Etkinlik Takvimi',
      icon: <HiOutlineCalendar size={22} />,
      path: '/admin/calendar',
    },
    {
      title: 'Duyurular',
      icon: <HiOutlineBell size={22} />,
      path: '/admin/announcements',
      badge: '3'
    },
    {
      title: 'Mesajlaşma',
      icon: <HiOutlineMail size={22} />,
      path: '/admin/messages',
      badge: '5'
    },
    {
      title: 'Raporlar & Analizler',
      icon: <HiOutlineDocumentReport size={22} />,
      path: '/admin/reports',
    },
    {
      title: 'İzinler & Roller',
      icon: <HiOutlineShieldCheck size={22} />,
      path: '/admin/permissions',
    },
    {
      title: 'Görev Yönetimi',
      icon: <HiOutlineClipboardList size={22} />,
      path: '/admin/tasks',
    },
    {
      title: 'Sistem Ayarları',
      icon: <HiOutlineCog size={22} />,
      path: '/admin/settings',
    },
  ];

  const renderMenuItem = (item) => (
    <div key={item.title} className="relative">
      {item.hasSubmenu ? (
        <div>
          <button
            onClick={() => setShowProjectMenu(!showProjectMenu)}
            className={`w-full flex items-center px-4 py-3 transition-all relative group
              ${location.pathname.includes(item.submenuItems[0].path.split('/')[2]) 
                ? 'bg-black text-white rounded-xl' 
                : 'text-gray-600 hover:bg-gray-50 rounded-xl'}`}
          >
            <div className="flex items-center gap-3 flex-1">
              {item.icon}
              {!isCollapsed && (
                <span className="text-sm font-medium">
                  {item.title}
                </span>
              )}
            </div>
            {!isCollapsed && item.badge && (
              <span className={`text-xs px-2 py-1 rounded-full 
                ${location.pathname.includes(item.submenuItems[0].path.split('/')[2])
                  ? 'bg-white text-black'
                  : 'bg-gray-100 text-gray-600'}`}>
                {item.badge}
              </span>
            )}
            {!isCollapsed && (
              <span className={`ml-2 transform transition-transform ${showProjectMenu ? 'rotate-180' : ''}`}>
                ▼
              </span>
            )}
          </button>
          
          {showProjectMenu && !isCollapsed && (
            <div className="ml-4 mt-1 space-y-1">
              {item.submenuItems.map((subItem) => (
                <button
                  key={subItem.path}
                  onClick={() => navigate(subItem.path)}
                  className={`w-full text-left py-2.5 px-4 text-sm rounded-lg transition-colors
                    ${location.pathname === subItem.path 
                      ? 'bg-gray-100 text-black font-medium' 
                      : 'text-gray-600 hover:bg-gray-50'}`}
                >
                  {subItem.title}
                </button>
              ))}
            </div>
          )}
        </div>
      ) : (
        <button
          onClick={() => navigate(item.path)}
          className={`w-full flex items-center px-4 py-3 transition-all relative group
            ${location.pathname === item.path 
              ? 'bg-black text-white rounded-xl' 
              : 'text-gray-600 hover:bg-gray-50 rounded-xl'}`}
        >
          <div className="flex items-center gap-3 flex-1">
            {item.icon}
            {!isCollapsed && (
              <span className="text-sm font-medium">
                {item.title}
              </span>
            )}
          </div>
          {!isCollapsed && item.badge && (
            <span className={`text-xs px-2 py-1 rounded-full
              ${location.pathname === item.path
                ? 'bg-white text-black'
                : 'bg-gray-100 text-gray-600'}`}>
              {item.badge}
            </span>
          )}
        </button>
      )}
    </div>
  );

  return (
    <div className="flex min-h-screen bg-gray-50">
      {/* Sidebar */}
      <div className={`fixed left-0 top-0 h-screen bg-white shadow-sm transition-all duration-300 z-20
        ${isCollapsed ? 'w-20' : 'w-72'}`}
      >
        {/* Logo */}
        <div className="h-20 flex items-center justify-between px-6 border-b border-gray-100">
          {!isCollapsed && (
            <img src={KlaketLogo} alt="Logo" className="h-8" />
          )}
          <button
            onClick={() => setIsCollapsed(!isCollapsed)}
            className="p-2 hover:bg-gray-50 rounded-lg"
          >
            <HiOutlineChevronLeft 
              size={20} 
              className={`transform transition-transform ${isCollapsed ? 'rotate-180' : ''}`}
            />
          </button>
        </div>

        {/* Menu */}
        <div className="p-4 space-y-1">
          {menuItems.map(item => renderMenuItem(item))}
        </div>
      </div>

      {/* Main Content */}
      <div className={`flex-1 ${isCollapsed ? 'ml-20' : 'ml-72'} transition-all duration-300`}>
        <AdminHeader />
        <main className="p-6">
          {children}
        </main>
      </div>
    </div>
  );
};

export default AdminLayout;
