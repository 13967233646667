function AgencyRegister() {
    return (
      <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center">
        <h1 className="text-4xl font-bold mb-8">Ajans Kayıt</h1>
        <form className="bg-white p-6 rounded shadow-md w-80">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Ajans Adı</label>
            <input
              type="text"
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">E-Posta</label>
            <input
              type="email"
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Şifre</label>
            <input
              type="password"
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
          <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded w-full">
            Kayıt Ol
          </button>
        </form>
      </div>
    );
  }
  
  export default AgencyRegister;
  