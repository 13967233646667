import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './context/AuthContext'; // useAuth'i içe aktar
import Profile from './pages/Profile';
import Home from './pages/Home'; // Oyuncu ve Ajans seçimi için
import Register from './pages/Register';
import ProfilePictureAndName from './pages/ProfilePictureAndName'; // Yeni bileşeni içe aktar
import Login from './pages/Login'; // Login sayfasını içe aktar
import BottomNavBar from './components/BottomNavBar';
import AgencyRegister from './pages/AgencyRegister'; // Ajans kayıt sayfası
import Announcements from './pages/Announcements'; // Duyurular sayfasını içe aktar
import AnnouncementDetail from './pages/AnnouncementDetail'; // Duyuru detay sayfasını içe aktar
import Players from './pages/Players'; // Oyuncular sayfasını içe aktar
import PlayerDetail from './pages/PlayerDetail'; // Oyuncu detay sayfasını içe aktar
import SplashScreen from './components/SplashScreen'; // SplashScreen bileşenini içe aktar
import Projects from './pages/Projects'; // Projeler sayfasını içe aktar
import AddProject from './pages/admin/AddProject'; // Yeni projeler ekleme sayfasını içe aktar
import Settings from './pages/Settings'; // Ayarlar sayfasını içe aktar
import CVPage from './pages/CVPage'; // CV sayfasını içe aktar
import Kolaj from './pages/kolaj'; // Kolaj sayfasını içe aktar
import ResetPassword from './ResetPassword';
import Help from './pages/Help'; // Yardım sayfasını içe aktar
import FAQ from './pages/FAQ'; // FAQ sayfasını içe aktar
import Feedback from './pages/Feedback'; // Feedback sayfasını içe aktar
import Terms from './pages/Terms'; // Terms sayfasını içe aktar
import Contact from './pages/Contact'; // Contact sayfasını içe aktar
import About from './pages/About'; // About sayfasını içe aktar
import ProjectDetails from './pages/ProjectDetails';
import IntroVideoUpload from './pages/IntroVideoUpload';
import ShowreelVideoUpload from './pages/ShowreelVideoUpload';
import MimicVideoUpload from './pages/MimicVideoUpload';
import UserManagement from './pages/UserManagement';
import ProjectManagement from './pages/ProjectManagement';
import EditProject from './pages/EditProject';
import AnnouncementPage from './pages/admin/AnnouncementPage';
import Campaigns from './pages/Campaigns';
import ProfileApproval from './pages/ProfileApproval';
import AdminApproval from './pages/admin/AdminApproval';
import SelectedUsers from './pages/SelectedUsers';  
import BankDetail from './pages/BankDetail';
import DataProcessing from './pages/DataProcessing';
import TermsOfService from './pages/TermsOfService';
import DesktopHeader from './components/DesktopHeader';
import AdminLayout from './components/AdminLayout';
import AdminDashboard from './pages/admin/Dashboard';
import AdminUsers from './pages/admin/Users';
import AdminProjects from './pages/admin/Projects';
import AdminCalendar from './pages/admin/calendar';
import AdminAnnouncements from './pages/admin/AdminAnnouncements';
import ProjectAdd from './pages/admin/ProjectAdd';
import AdminUserDetail from './pages/admin/AdminUserDetail';
import AdminSelectedUsers from './pages/admin/AdminSelectedUsers';
import { Toaster } from 'react-hot-toast';





// AppContent bileşeni - useLocation burada kullanılabilir

const AppContent = () => {
  const [isSplashVisible, setSplashVisible] = useState(true);
  const location = useLocation();

  const handleSplashFinish = () => {
    setSplashVisible(false);
  };

  const isAdminRoute = () => {
    return location.pathname.startsWith('/admin');
  };

  return (
    <>
      {!isAdminRoute() && <DesktopHeader />}
      
        <>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/announcements" element={<Announcements />} />
            <Route path="/project-management" element={<ProjectManagement />} />
            <Route path="/user-management" element={<UserManagement />} />
            <Route path="/selected-users" element={<SelectedUsers />} />
            <Route path="/bank-detail" element={<BankDetail />} />
            <Route path="/duyurular/:id" element={<AnnouncementDetail />} />
            <Route path="/player-register" element={<Register />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/agency-register" element={<AgencyRegister />} />
            <Route path="/profile" element={<ProtectedRoute component={Profile} />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/profile-picture-and-name" element={<ProtectedRoute component={ProfilePictureAndName} />} />
            <Route path="/mimic-video-upload" element={<ProtectedRoute component={MimicVideoUpload} />} />
            <Route path="/showreel-video-upload" element={<ProtectedRoute component={ShowreelVideoUpload} />} />
            <Route path="/intro-video-upload" element={<ProtectedRoute component={IntroVideoUpload} />} />
            <Route path="/login" element={<Login />} />
            <Route path="/players" element={<Players />} />
            <Route path="/kolaj" element={<ProtectedRoute component={Kolaj} />} />
            <Route path="/help" element={<Help />} />
            <Route path="/players/:id" element={<PlayerDetail />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/add-project" element={<AddProject />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/cv" element={<ProtectedRoute component={CVPage} />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/projects/:id" element={<ProjectDetails />} />
            <Route path="/edit-project/:projectId" element={<EditProject />} />
            <Route path="*" element={<h1>404 - Sayfa Bulunamadı</h1>} />
            <Route path="/announcement-page" element={<AnnouncementPage />} />
            <Route path="/campaigns" element={<Campaigns />} />
            <Route path="/profile-approval" element={<ProfileApproval />} />
            <Route path="/admin-approval" element={<AdminApproval />} />
            <Route path="/data-processing" element={<DataProcessing />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/admin/*" element={
              <AdminLayout>
                <Routes>
                  <Route path="dashboard" element={<AdminDashboard />} />
                  <Route path="users" element={<AdminUsers />} />
                  <Route path="users/:id" element={<AdminUserDetail />} />
                  <Route path="selected-users" element={<AdminSelectedUsers />} />
                  <Route path="projects" element={<AdminProjects />} />
                  <Route path="calendar" element={<AdminCalendar />} />
                  <Route path="announcements" element={<AdminAnnouncements />} />
                  <Route path="projects/add" element={<ProjectAdd />} />
                </Routes>
              </AdminLayout>
            } />
          </Routes>
          {!isAdminRoute() && <BottomNavBarWrapper />}
        </>
    </>
  );
};

// Ana App bileşeni
const App = () => {
  return (
    <Router>
      <Toaster />
      <AppContent />
    </Router>
  );
};

// Kullanıcı giriş yapmamışsa Login sayfasına yönlendir
const ProtectedRoute = ({ component: Component }) => {
  const { user } = useAuth(); // Kullanıcı bilgilerini al

  return user ? <Component /> : <Navigate to="/login" />; // Kullanıcı varsa bileşeni göster, yoksa Login sayfasına yönlendir
};

// BottomNavBar'ı sadece giriş yapanlar için göster ve sadece mobil cihazlarda görünür yap
const BottomNavBarWrapper = () => {
  const { user } = useAuth(); // Kullanıcı bilgilerini al

  return user ? (
    <div className="mobile-bottom-nav">
      <BottomNavBar />
    </div>
  ) : null; // Kullanıcı varsa göster
};

export default App;
