import React, { useEffect, useState } from 'react';
import { 
  HiOutlineUsers, 
  HiOutlineBriefcase, 
  HiOutlineSpeakerphone,
  HiOutlineUserGroup,
  HiOutlineCheck,
  HiOutlineLocationMarker,
} from 'react-icons/hi';
import { db } from '../../firebaseconf'; // Firebase yapılandırmanıza göre import edin
import { ref, onValue } from 'firebase/database';

const AdminDashboard = () => {
  const [stats, setStats] = useState({
    totalUsers: 0,
    adminUsers: 0,
    productionUsers: 0,
    regularUsers: 0,
    approvedProfiles: 0,
    totalProjects: 0,
    uniqueCities: 0,
    totalAnnouncements: 0
  });

  useEffect(() => {
    const fetchData = () => {
      const dataRef = ref(db);
      onValue(dataRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          // Kullanıcı istatistikleri
          const users = data.users || {};
          const totalUsers = Object.keys(users).length;
          const adminUsers = Object.values(users).filter(user => user.role === 'admin').length;
          const productionUsers = Object.values(users).filter(user => user.role === 'production').length;
          const regularUsers = Object.values(users).filter(user => user.role === 'user').length;
          const approvedProfiles = Object.values(users).filter(user => 
            user.profilecv?.approvalStatus === 'approved'
          ).length;

          // Proje istatistikleri
          const projects = data.projects || {};
          const totalProjects = Object.keys(projects).length;
          const uniqueCities = [...new Set(Object.values(projects).map(project => project.city))].length;

          // Duyuru istatistikleri
          const announcements = data.announcements || {};
          const totalAnnouncements = Object.keys(announcements).length;

          setStats({
            totalUsers,
            adminUsers,
            productionUsers,
            regularUsers,
            approvedProfiles,
            totalProjects,
            uniqueCities,
            totalAnnouncements
          });
        }
      });
    };

    fetchData();
  }, []);

  const statCards = [
    {
      title: 'Toplam Kullanıcı',
      value: stats.totalUsers,
      icon: <HiOutlineUsers size={24} />,
    },
    {
      title: 'Onaylı Profiller',
      value: stats.approvedProfiles,
      icon: <HiOutlineCheck size={24} />,
    },
    {
      title: 'Aktif Projeler',
      value: stats.totalProjects,
      icon: <HiOutlineBriefcase size={24} />,
    },
    {
      title: 'Duyurular',
      value: stats.totalAnnouncements,
      icon: <HiOutlineSpeakerphone size={24} />,
    },
  ];

  const detailCards = [
    {
      title: 'Kullanıcı Dağılımı',
      icon: <HiOutlineUserGroup size={24} />,
      details: [
        { label: 'Admin', value: stats.adminUsers },
        { label: 'Yapım Şirketi', value: stats.productionUsers },
        { label: 'Oyuncu', value: stats.regularUsers },
      ]
    },
    {
      title: 'Proje Lokasyonları',
      icon: <HiOutlineLocationMarker size={24} />,
      details: [
        { label: 'Aktif Şehir', value: stats.uniqueCities },
        { label: 'Toplam Proje', value: stats.totalProjects },
      ]
    },
  ];

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold text-black">Dashboard</h1>
      
      {/* Ana İstatistikler */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {statCards.map((card, index) => (
          <div 
            key={index}
            className="bg-white rounded-xl p-6 shadow-sm border border-gray-200 hover:bg-gray-50 transition-colors"
          >
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm font-medium text-gray-600">{card.title}</p>
                <p className="text-2xl font-bold text-black mt-2">{card.value}</p>
              </div>
              <div className="p-3 rounded-lg bg-gray-100">
                {card.icon}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Detaylı İstatistikler */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {detailCards.map((card, index) => (
          <div 
            key={index}
            className="bg-white rounded-xl p-6 shadow-sm border border-gray-200 hover:bg-gray-50 transition-colors"
          >
            <div className="flex items-center gap-3 mb-4">
              <div className="p-3 rounded-lg bg-gray-100">
                {card.icon}
              </div>
              <h3 className="text-lg font-semibold text-black">{card.title}</h3>
            </div>
            
            <div className="space-y-4">
              {card.details.map((detail, idx) => (
                <div key={idx} className="flex items-center justify-between">
                  <span className="text-sm text-gray-600">{detail.label}</span>
                  <span className="text-lg font-semibold text-black">{detail.value}</span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminDashboard;
