import React, { useState } from 'react';
import { db } from '../../firebaseconf';
import { ref, push } from 'firebase/database';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { HiOutlineArrowLeft, HiOutlineCamera } from 'react-icons/hi';

const AddProject = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [formData, setFormData] = useState({
    title: '',
    date: '',
    startTime: '',
    endTime: '',
    location: '',
    city: '',
    projectType: '',
    image: null,
    requirements: {
      hairColor: '',
      eyeColor: '',
      height: '',
      weight: '',
      shoeSize: ''
    },
    peopleNeeded: {
      men: 0,
      women: 0,
      children: 0
    }
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setFormData(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setFormData(prev => ({
        ...prev,
        image: e.target.files[0]
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const storage = getStorage();

    try {
      let imageUrl = '';
      if (formData.image) {
        const imageRef = storageRef(storage, `projects/${formData.image.name}`);
        await uploadBytes(imageRef, formData.image);
        imageUrl = await getDownloadURL(imageRef);
      }

      const projectData = {
        ...formData,
        imageUrl,
        userId: user.uid,
        createdAt: new Date().toISOString(),
        status: 'active'
      };

      delete projectData.image;

      await push(ref(db, 'projects'), projectData);
      navigate('/admin/projects');
    } catch (error) {
      console.error('Proje ekleme hatası:', error);
    }
  };

  const inputClasses = "w-full px-4 py-3 border border-gray-200 rounded-lg focus:outline-none focus:border-black bg-white";
  const labelClasses = "block text-sm font-medium text-gray-900 mb-1";
  const sectionClasses = "space-y-4 bg-white p-6 rounded-lg border border-gray-200";

  return (
    <div className="max-w-3xl mx-auto pb-20">
      {/* Header */}
      <div className="sticky top-0 z-10 bg-white border-b border-gray-200 px-4 py-4">
        <div className="flex items-center justify-between">
          <button 
            onClick={() => navigate(-1)}
            className="p-2 hover:bg-gray-100 rounded-lg"
          >
            <HiOutlineArrowLeft size={20} />
          </button>
          <h1 className="text-lg font-bold">Yeni Proje</h1>
          <button
            onClick={handleSubmit}
            className="text-sm font-medium hover:bg-gray-100 px-4 py-2 rounded-lg"
          >
            Kaydet
          </button>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6 p-4">
        {/* Temel Bilgiler */}
        <section className={sectionClasses}>
          <h2 className="text-lg font-semibold mb-4">Temel Bilgiler</h2>
          
          <div className="space-y-4">
            <div>
              <label className={labelClasses}>Proje Adı</label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                className={inputClasses}
                required
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className={labelClasses}>Tarih</label>
                <input
                  type="date"
                  name="date"
                  value={formData.date}
                  onChange={handleInputChange}
                  className={inputClasses}
                  required
                />
              </div>
              <div>
                <label className={labelClasses}>Proje Tipi</label>
                <select
                  name="projectType"
                  value={formData.projectType}
                  onChange={handleInputChange}
                  className={inputClasses}
                  required
                >
                  <option value="">Seçiniz</option>
                  <option value="Reklam Filmi">Reklam Filmi</option>
                  <option value="Dizi">Dizi</option>
                  <option value="Film">Film</option>
                  <option value="Katalog">Katalog</option>
                </select>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className={labelClasses}>Başlangıç Saati</label>
                <input
                  type="time"
                  name="startTime"
                  value={formData.startTime}
                  onChange={handleInputChange}
                  className={inputClasses}
                  required
                />
              </div>
              <div>
                <label className={labelClasses}>Bitiş Saati</label>
                <input
                  type="time"
                  name="endTime"
                  value={formData.endTime}
                  onChange={handleInputChange}
                  className={inputClasses}
                  required
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className={labelClasses}>Şehir</label>
                <input
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleInputChange}
                  className={inputClasses}
                  required
                />
              </div>
              <div>
                <label className={labelClasses}>Lokasyon</label>
                <input
                  type="text"
                  name="location"
                  value={formData.location}
                  onChange={handleInputChange}
                  className={inputClasses}
                  required
                />
              </div>
            </div>
          </div>
        </section>

        {/* Görsel */}
        <section className={sectionClasses}>
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-semibold">Proje Görseli</h2>
            <label className="cursor-pointer p-2 hover:bg-gray-100 rounded-lg">
              <input 
                type="file" 
                accept="image/*"
                onChange={handleImageChange}
                className="hidden" 
              />
              <HiOutlineCamera size={24} />
            </label>
          </div>
          {formData.image && (
            <div className="mt-2 text-sm text-gray-600">
              Seçilen dosya: {formData.image.name}
            </div>
          )}
        </section>

        {/* Oyuncu Gereksinimleri */}
        <section className={sectionClasses}>
          <h2 className="text-lg font-semibold mb-4">Oyuncu Gereksinimleri</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className={labelClasses}>Saç Rengi</label>
              <select
                name="requirements.hairColor"
                value={formData.requirements.hairColor}
                onChange={handleInputChange}
                className={inputClasses}
              >
                <option value="">Seçiniz</option>
                <option value="Siyah">Siyah</option>
                <option value="Kahverengi">Kahverengi</option>
                <option value="Sarı">Sarı</option>
                <option value="Kızıl">Kızıl</option>
                <option value="Gri">Gri</option>
              </select>
            </div>

            <div>
              <label className={labelClasses}>Göz Rengi</label>
              <select
                name="requirements.eyeColor"
                value={formData.requirements.eyeColor}
                onChange={handleInputChange}
                className={inputClasses}
              >
                <option value="">Seçiniz</option>
                <option value="Kahverengi">Kahverengi</option>
                <option value="Mavi">Mavi</option>
                <option value="Yeşil">Yeşil</option>
                <option value="Ela">Ela</option>
              </select>
            </div>

            <div>
              <label className={labelClasses}>Boy (cm)</label>
              <select
                name="requirements.height"
                value={formData.requirements.height}
                onChange={handleInputChange}
                className={inputClasses}
              >
                <option value="">Seçiniz</option>
                {[...Array(81)].map((_, i) => (
                  <option key={i} value={150 + i}>{150 + i} cm</option>
                ))}
              </select>
            </div>

            <div>
              <label className={labelClasses}>Kilo (kg)</label>
              <select
                name="requirements.weight"
                value={formData.requirements.weight}
                onChange={handleInputChange}
                className={inputClasses}
              >
                <option value="">Seçiniz</option>
                {[...Array(101)].map((_, i) => (
                  <option key={i} value={40 + i}>{40 + i} kg</option>
                ))}
              </select>
            </div>

            <div>
              <label className={labelClasses}>Ayakkabı Numarası</label>
              <select
                name="requirements.shoeSize"
                value={formData.requirements.shoeSize}
                onChange={handleInputChange}
                className={inputClasses}
              >
                <option value="">Seçiniz</option>
                {[...Array(21)].map((_, i) => (
                  <option key={i} value={35 + i}>{35 + i}</option>
                ))}
              </select>
            </div>
          </div>
        </section>

        {/* İhtiyaç Duyulan Kişi Sayısı */}
        <section className={sectionClasses}>
          <h2 className="text-lg font-semibold mb-4">İhtiyaç Duyulan Kişi Sayısı</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <label className={labelClasses}>Erkek</label>
              <input
                type="number"
                name="peopleNeeded.men"
                value={formData.peopleNeeded.men}
                onChange={handleInputChange}
                min="0"
                className={inputClasses}
              />
            </div>

            <div>
              <label className={labelClasses}>Kadın</label>
              <input
                type="number"
                name="peopleNeeded.women"
                value={formData.peopleNeeded.women}
                onChange={handleInputChange}
                min="0"
                className={inputClasses}
              />
            </div>

            <div>
              <label className={labelClasses}>Çocuk</label>
              <input
                type="number"
                name="peopleNeeded.children"
                value={formData.peopleNeeded.children}
                onChange={handleInputChange}
                min="0"
                className={inputClasses}
              />
            </div>
          </div>
        </section>

        {/* Submit Button */}
        <div className="fixed bottom-0 left-0 right-0 p-4 bg-white border-t border-gray-200">
          <button
            type="submit"
            className="w-full bg-black text-white py-3 rounded-lg hover:bg-gray-800 transition-colors"
          >
            Projeyi Oluştur
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddProject;
