import React, { useEffect, useState } from 'react';
import { db } from '../../firebaseconf';
import { ref, onValue, remove, update } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { 
  HiOutlineSearch,
  HiOutlineCalendar,
  HiOutlineLocationMarker,
  HiOutlineUserGroup,
  HiOutlineClock,
  HiOutlineTrash,
  HiOutlinePencil,
  HiOutlineEye,
  HiOutlineExclamationCircle,
  HiOutlineFilter,
  HiOutlinePlus,
  HiOutlineRefresh,
  HiOutlineDownload
} from 'react-icons/hi';
import Modal from 'react-modal';

const AdminProjects = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterCity, setFilterCity] = useState('all');
  const [filterType, setFilterType] = useState('all');
  const [filterStatus, setFilterStatus] = useState('all');
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  useEffect(() => {
    const projectsRef = ref(db, 'projects');
    onValue(projectsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const projectsArray = Object.entries(data).map(([id, project]) => ({
          id,
          ...project,
          createdAt: project.createdAt || new Date().toISOString()
        })).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setProjects(projectsArray);
        setFilteredProjects(projectsArray);
      }
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    let result = [...projects];

    if (searchTerm) {
      result = result.filter(project => 
        project.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        project.location?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        project.city?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        project.projectType?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (filterCity !== 'all') {
      result = result.filter(project => project.city === filterCity);
    }

    if (filterType !== 'all') {
      result = result.filter(project => project.projectType === filterType);
    }

    if (filterStatus !== 'all') {
      result = result.filter(project => project.status === filterStatus);
    }

    setFilteredProjects(result);
  }, [searchTerm, filterCity, filterType, filterStatus, projects]);

  const handleDelete = async (projectId) => {
    try {
      await remove(ref(db, `projects/${projectId}`));
      setIsDeleteModalOpen(false);
      setSelectedProject(null);
    } catch (error) {
      console.error('Proje silme hatası:', error);
    }
  };

  const cities = [...new Set(projects.map(project => project.city))].filter(Boolean);
  const projectTypes = [...new Set(projects.map(project => project.projectType))].filter(Boolean);

  return (
    <div className="min-h-screen bg-gray-50 p-6">
      {/* Üst Bar */}
      <div className="flex justify-between items-center mb-8">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Projeler</h1>
          <p className="text-sm text-gray-500 mt-1">Toplam {filteredProjects.length} proje</p>
        </div>
        <div className="flex items-center gap-3">
          <button 
            onClick={() => navigate('/admin/projects/add')}
            className="flex items-center px-4 py-2 bg-black text-white rounded-xl hover:bg-gray-800 transition-colors"
          >
            <HiOutlinePlus className="mr-2" size={20} />
            Yeni Proje
          </button>
        </div>
      </div>

      {/* Filtreler */}
      <div className="bg-white p-4 rounded-xl shadow-sm mb-6">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <div className="relative">
            <HiOutlineSearch className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
            <input
              type="text"
              placeholder="Proje ara..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-black/5"
            />
          </div>

          <select
            value={filterCity}
            onChange={(e) => setFilterCity(e.target.value)}
            className="px-4 py-2 border border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-black/5"
          >
            <option value="all">Tüm Şehirler</option>
            {cities.map(city => (
              <option key={city} value={city}>{city}</option>
            ))}
          </select>

          <select
            value={filterType}
            onChange={(e) => setFilterType(e.target.value)}
            className="px-4 py-2 border border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-black/5"
          >
            <option value="all">Tüm Proje Tipleri</option>
            {projectTypes.map(type => (
              <option key={type} value={type}>{type}</option>
            ))}
          </select>

          <select
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
            className="px-4 py-2 border border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-black/5"
          >
            <option value="all">Tüm Durumlar</option>
            <option value="active">Aktif</option>
            <option value="completed">Tamamlandı</option>
            <option value="cancelled">İptal Edildi</option>
          </select>
        </div>
      </div>

      {/* Proje Listesi */}
      {isLoading ? (
        <div className="flex items-center justify-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-black"></div>
        </div>
      ) : (
        <div className="bg-white rounded-xl shadow-sm overflow-hidden">
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="bg-gray-50">
                  <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Proje</th>
                  <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Tarih & Saat</th>
                  <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Lokasyon</th>
                  <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Tip</th>
                  <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Durum</th>
                  <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Kişi</th>
                  <th className="px-6 py-4 text-right text-sm font-medium text-gray-500">İşlemler</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {filteredProjects.map((project) => (
                  <tr key={project.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4">
                      <div className="flex items-center gap-3">
                        <div className="w-12 h-12 rounded-xl bg-gray-100 overflow-hidden">
                          {project.imageUrl ? (
                            <img 
                              src={project.imageUrl} 
                              alt={project.title} 
                              className="w-full h-full object-cover"
                            />
                          ) : (
                            <div className="w-full h-full flex items-center justify-center">
                              <HiOutlineExclamationCircle className="text-gray-400" size={20} />
                            </div>
                          )}
                        </div>
                        <div>
                          <div className="font-medium text-gray-900">{project.title}</div>
                          <div className="text-sm text-gray-500">#{project.id.slice(0, 8)}</div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="space-y-1">
                        <div className="flex items-center text-sm text-gray-600">
                          <HiOutlineCalendar className="mr-2" size={16} />
                          {project.date}
                        </div>
                        <div className="flex items-center text-sm text-gray-500">
                          <HiOutlineClock className="mr-2" size={16} />
                          {project.startTime} - {project.endTime}
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="space-y-1">
                        <div className="text-sm text-gray-900">{project.city}</div>
                        <div className="flex items-center text-sm text-gray-500">
                          <HiOutlineLocationMarker className="mr-1" size={16} />
                          {project.location}
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                        {project.projectType}
                      </span>
                    </td>
                    <td className="px-6 py-4">
                      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                        ${project.status === 'active' ? 'bg-green-100 text-green-800' : 
                          project.status === 'completed' ? 'bg-blue-100 text-blue-800' : 
                          'bg-red-100 text-red-800'}`}>
                        {project.status === 'active' ? 'Aktif' :
                         project.status === 'completed' ? 'Tamamlandı' : 'İptal Edildi'}
                      </span>
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex items-center text-sm text-gray-600">
                        <HiOutlineUserGroup className="mr-2" size={16} />
                        {project.peopleNeeded?.total || 0}
                      </div>
                    </td>
                    <td className="px-6 py-4 text-right">
                      <div className="flex items-center justify-end gap-2">
                        <button
                          onClick={() => navigate(`/admin/projects/${project.id}`)}
                          className="p-2 text-gray-500 hover:bg-gray-100 rounded-lg transition-colors"
                          title="Görüntüle"
                        >
                          <HiOutlineEye size={18} />
                        </button>
                        <button
                          onClick={() => navigate(`/admin/projects/${project.id}/edit`)}
                          className="p-2 text-gray-500 hover:bg-gray-100 rounded-lg transition-colors"
                          title="Düzenle"
                        >
                          <HiOutlinePencil size={18} />
                        </button>
                        <button
                          onClick={() => {
                            setSelectedProject(project);
                            setIsDeleteModalOpen(true);
                          }}
                          className="p-2 text-red-500 hover:bg-red-50 rounded-lg transition-colors"
                          title="Sil"
                        >
                          <HiOutlineTrash size={18} />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* Silme Modal */}
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
        className="bg-white rounded-xl p-6 max-w-md mx-auto mt-24"
        overlayClassName="fixed inset-0 bg-black/50"
      >
        <h2 className="text-xl font-bold mb-4">Projeyi Sil</h2>
        <p className="text-gray-600 mb-6">
          "{selectedProject?.title}" projesini silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.
        </p>
        <div className="flex justify-end gap-3">
          <button
            onClick={() => setIsDeleteModalOpen(false)}
            className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
          >
            İptal
          </button>
          <button
            onClick={() => handleDelete(selectedProject?.id)}
            className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
          >
            Sil
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default AdminProjects;
