import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database'; // Realtime Database fonksiyonlarını ekle
import { useNavigate } from 'react-router-dom'; // Yönlendirme için kullan
import { useAuth } from '../context/AuthContext'; // Kullanıcı bilgilerini almak için
import { motion } from 'framer-motion';

const db = getDatabase(); // Realtime Database'i başlat

const Duyurular = () => {
  const [duyurular, setDuyurular] = useState([]);
  const navigate = useNavigate(); // Yönlendirme için kullan
  const { user } = useAuth(); // Kullanıcı bilgilerini al

  useEffect(() => {
    // Kullanıcı yoksa giriş sayfasına yönlendir
    if (!user || !user.uid) {
      navigate('/login'); // Giriş sayfasına yönlendir
      return; // Fonksiyondan çık
    }

    const duyurularRef = ref(db, 'announcements'); // Duyuruların bulunduğu referans
    const unsubscribe = onValue(duyurularRef, (snapshot) => {
      const data = snapshot.val();
      const yuklenenDuyurular = [];
      for (let id in data) {
        yuklenenDuyurular.push({ 
          id, 
          ...data[id], 
          okundu: data[id].okuyanlar ? data[id].okuyanlar.includes(user.uid) : false 
        });
      }
      setDuyurular(yuklenenDuyurular.reverse()); // En son duyuruları başa getir
    });

    return () => unsubscribe();
  }, [user, navigate]); // user ve navigate bağımlılıkları eklendi

  const handleDuyuruClick = (id) => {
    // Duyuruyu okundu olarak işaretle
    navigate(`/duyurular/${id}`); // Duyuru detay sayfasına yönlendir
  };

  return (
    <motion.div 
      className="min-h-screen bg-neutral-50 flex flex-col"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <motion.div 
        className="flex items-center bg-white p-4 pb-2 justify-between shadow-sm"
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <motion.div
          className="text-[#141414] flex size-12 shrink-0 items-center cursor-pointer"
          onClick={() => navigate(-1)}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
       
        </motion.div>
        <h2 className="text-[#141414] text-lg font-bold leading-tight tracking-[-0.015em] flex-1 text-center pr-12">
          Duyurular
        </h2>
      </motion.div>

      <motion.h2 
        className="text-[#141414] text-[22px] font-bold leading-tight tracking-[-0.015em] px-4 pb-3 pt-5"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
      >
        En Son
      </motion.h2>

      <motion.div 
        className="flex-1 overflow-y-auto px-4"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3 }}
      >
        {duyurular.length > 0 ? (
          duyurular.map((duyuru, index) => (
            <motion.div 
              key={duyuru.id} 
              className="flex items-center gap-4 bg-white rounded-lg shadow-sm mb-4 p-4 cursor-pointer relative"
              onClick={() => handleDuyuruClick(duyuru.id)}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              {!duyuru.okundu && (
                <div className="absolute top-2 right-2 w-3 h-3 bg-blue-500 rounded-full"></div>
              )}
              <div className="text-[#141414] flex items-center justify-center rounded-lg bg-[#ededed] shrink-0 size-12">
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
                  <path d="M221.8,175.94C216.25,166.38,208,139.33,208,104a80,80,0,1,0-160,0c0,35.34-8.26,62.38-13.81,71.94A16,16,0,0,0,48,200H88.81a40,40,0,0,0,78.38,0H208a16,16,0,0,0,13.8-24.06ZM128,216a24,24,0,0,1-22.62-16h45.24A24,24,0,0,1,128,216ZM48,184c7.7-13.24,16-43.92,16-80a64,64,0,1,1,128,0c0,36.05,8.28,66.73,16,80Z"></path>
                </svg>
              </div>
              <div className="flex-1">
                <p className="text-[#141414] text-base font-medium leading-normal">{duyuru.title}</p>
                <p className="text-neutral-500 text-sm font-normal leading-normal">{duyuru.createdAt}</p>
              </div>
              <div className="text-[#141414] flex size-7 items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
                  <path d="M181.66,133.66l-80,80a8,8,0,0,1-11.32-11.32L164.69,128,90.34,53.66a8,8,0,0,1,11.32-11.32l80,80A8,8,0,0,1,181.66,133.66Z"></path>
                </svg>
              </div>
            </motion.div>
          ))
        ) : (
          <motion.p 
            className="text-neutral-500 px-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
          >
            Henüz duyuru yok.
          </motion.p>
        )}
      </motion.div>
    </motion.div>
  );
};

export default Duyurular;
