import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const FilterModal = ({ isOpen, onClose, onApplyFilters }) => {
  const [filters, setFilters] = useState({
    ageRange: { min: '', max: '' },
    gender: '',
    hairColor: '',
    height: { min: '', max: '' },
    city: '',
  });

  const handleApplyFilters = () => {
    onApplyFilters(filters);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-[#1F1F1F] bg-opacity-20 backdrop-blur-sm flex items-center justify-center z-50"
        onClick={onClose}
      >
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 50, opacity: 0 }}
          className="bg-[#F1F0F0] rounded-2xl p-6 max-w-md w-full mx-4 shadow-lg border border-[#A2A1A1]"
          onClick={e => e.stopPropagation()}
        >
          <div className="flex flex-col">
            <h3 className="text-lg font-medium text-[#1F1F1F] mb-4">
              Filtreleme Seçenekleri
            </h3>

            {/* Yaş Aralığı */}
            <div className="mb-4">
              <label className="text-sm text-[#4A494A] mb-2 block">Yaş Aralığı</label>
              <div className="flex gap-2">
                <input
                  type="number"
                  placeholder="Min"
                  className="w-1/2 p-2 rounded-lg border border-[#A2A1A1] bg-white"
                  value={filters.ageRange.min}
                  onChange={(e) => setFilters({
                    ...filters,
                    ageRange: { ...filters.ageRange, min: e.target.value }
                  })}
                />
                <input
                  type="number"
                  placeholder="Max"
                  className="w-1/2 p-2 rounded-lg border border-[#A2A1A1] bg-white"
                  value={filters.ageRange.max}
                  onChange={(e) => setFilters({
                    ...filters,
                    ageRange: { ...filters.ageRange, max: e.target.value }
                  })}
                />
              </div>
            </div>

            {/* Cinsiyet */}
            <div className="mb-4">
              <label className="text-sm text-[#4A494A] mb-2 block">Cinsiyet</label>
              <select
                className="w-full p-2 rounded-lg border border-[#A2A1A1] bg-white"
                value={filters.gender}
                onChange={(e) => setFilters({ ...filters, gender: e.target.value })}
              >
                <option value="">Tümü</option>
                <option value="male">Erkek</option>
                <option value="female">Kadın</option>
              </select>
            </div>

            {/* Saç Rengi */}
            <div className="mb-4">
              <label className="text-sm text-[#4A494A] mb-2 block">Saç Rengi</label>
              <select
                className="w-full p-2 rounded-lg border border-[#A2A1A1] bg-white"
                value={filters.hairColor}
                onChange={(e) => setFilters({ ...filters, hairColor: e.target.value })}
              >
                <option value="">Tümü</option>
                <option value="black">Siyah</option>
                <option value="brown">Kahverengi</option>
                <option value="blonde">Sarı</option>
                <option value="red">Kızıl</option>
                <option value="white">Beyaz</option>
              </select>
            </div>

            {/* Boy Aralığı */}
            <div className="mb-4">
              <label className="text-sm text-[#4A494A] mb-2 block">Boy Aralığı (cm)</label>
              <div className="flex gap-2">
                <input
                  type="number"
                  placeholder="Min"
                  className="w-1/2 p-2 rounded-lg border border-[#A2A1A1] bg-white"
                  value={filters.height.min}
                  onChange={(e) => setFilters({
                    ...filters,
                    height: { ...filters.height, min: e.target.value }
                  })}
                />
                <input
                  type="number"
                  placeholder="Max"
                  className="w-1/2 p-2 rounded-lg border border-[#A2A1A1] bg-white"
                  value={filters.height.max}
                  onChange={(e) => setFilters({
                    ...filters,
                    height: { ...filters.height, max: e.target.value }
                  })}
                />
              </div>
            </div>

            {/* Şehir */}
            <div className="mb-6">
              <label className="text-sm text-[#4A494A] mb-2 block">Şehir</label>
              <input
                type="text"
                placeholder="Şehir giriniz"
                className="w-full p-2 rounded-lg border border-[#A2A1A1] bg-white"
                value={filters.city}
                onChange={(e) => setFilters({ ...filters, city: e.target.value })}
              />
            </div>

            {/* Butonlar */}
            <div className="flex gap-3">
              <button
                onClick={onClose}
                className="w-1/2 py-2.5 rounded-xl border border-[#535453] text-[#1F1F1F]"
              >
                İptal
              </button>
              <button
                onClick={handleApplyFilters}
                className="w-1/2 py-2.5 rounded-xl bg-[#292929] text-[#F1F0F0] hover:bg-[#4A494A]"
              >
                Uygula
              </button>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default FilterModal;
