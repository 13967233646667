import React, { useEffect, useState } from 'react';
import { getDatabase, ref, get, remove } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaSpinner, FaUser, FaTrash, FaFileExcel } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { useAuth } from '../context/AuthContext';
import * as XLSX from 'xlsx';

const db = getDatabase();

const calculateAge = (birthDate) => {
  if (!birthDate) return null;
  const today = new Date();
  const birthDateObj = new Date(birthDate);
  let age = today.getFullYear() - birthDateObj.getFullYear();
  const monthDiff = today.getMonth() - birthDateObj.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
    age--;
  }
  return age;
};

function SelectedUsers() {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const fetchSelectedUsers = async () => {
      if (!user) {
        navigate('/login');
        return;
      }

      try {
        const selectedPlayersRef = ref(db, `users/${user.uid}/selectedPlayers`);
        const snapshot = await get(selectedPlayersRef);
        
        if (snapshot.exists()) {
          const selectedPlayers = Object.values(snapshot.val());
          setSelectedUsers(selectedPlayers);
        }
      } catch (error) {
        console.error("Veri çekerken hata oluştu:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSelectedUsers();
  }, [user, navigate]);

  const handleDeleteUser = async (userId, e) => {
    e.stopPropagation();
    try {
      await remove(ref(db, `users/${user.uid}/selectedPlayers/${userId}`));
      setSelectedUsers(prev => prev.filter(user => user.id !== userId));
    } catch (error) {
      console.error("Silme işlemi başarısız:", error);
    }
  };

  const handleClearAll = async () => {
    if (window.confirm('Tüm seçili oyuncuları silmek istediğinize emin misiniz?')) {
      try {
        await remove(ref(db, `users/${user.uid}/selectedPlayers`));
        setSelectedUsers([]);
      } catch (error) {
        console.error("Toplu silme işlemi başarısız:", error);
      }
    }
  };

  const filteredUsers = selectedUsers.filter(user =>
    `${user.firstName} ${user.lastName}`.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleExportToExcel = () => {
    // Excel için veri hazırlama
    const excelData = filteredUsers.map((user, index) => ({
      'Sıra No': index + 1,
      'Ad Soyad': `${user.firstName} ${user.lastName}`,
      'TC Kimlik': user.tcno || 'Belirtilmemiş',
      'Pozisyon': user.position || 'Belirtilmemiş',
      'Yaş': user.birthDate ? calculateAge(user.birthDate) : 'Belirtilmemiş',
      'Şehir': user.city || 'Belirtilmemiş',
      'Telefon': user.phone || 'Belirtilmemiş'
    }));

    // Excel dosyası oluşturma
    const ws = XLSX.utils.json_to_sheet(excelData);

    // Sütun genişliklerini ayarla
    const wscols = [
      { wch: 8 },    // Sıra No
      { wch: 30 },   // Ad Soyad
      { wch: 15 },   // TC Kimlik
      { wch: 20 },   // Pozisyon
      { wch: 8 },    // Yaş
      { wch: 15 },   // Şehir
      { wch: 15 }    // Telefon
    ];
    ws['!cols'] = wscols;

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Seçili Oyuncular");

    // Dosyayı indirme
    XLSX.writeFile(wb, `secili_oyuncular_${new Date().toLocaleDateString('tr-TR')}.xlsx`);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-[#F1F0F0]">
        <FaSpinner className="animate-spin text-4xl text-[#4A494A]" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#F1F0F0]">
      <motion.div 
        className="sticky top-0 z-10 flex items-center bg-white p-4 justify-between shadow-sm border-b border-[#A2A1A1]/20"
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
      >
        <motion.div
          onClick={() => navigate(-1)}
          className="text-[#1F1F1F] cursor-pointer p-2"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <FaArrowLeft size={20} />
        </motion.div>
        
        <h2 className="text-[#1F1F1F] text-lg font-semibold">
          Seçilen Oyuncular ({filteredUsers.length})
        </h2>

        <div className="flex items-center gap-2">
          {selectedUsers.length > 0 && (
            <>
              <motion.button 
                onClick={handleExportToExcel}
                className="text-[#4A494A] hover:text-[#1F1F1F] p-2"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                title="Excel'e Aktar"
              >
                <FaFileExcel size={20} />
              </motion.button>
              <motion.button 
                onClick={handleClearAll}
                className="text-[#4A494A] hover:text-[#1F1F1F] p-2"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                title="Tümünü Sil"
              >
                <FaTrash size={20} />
              </motion.button>
            </>
          )}
        </div>
      </motion.div>

      <div className="max-w-4xl mx-auto p-4 space-y-4">
        {selectedUsers.length > 0 && (
          <input
            type="text"
            placeholder="Oyuncu ara..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full p-3 rounded-lg bg-white border border-[#A2A1A1]/20 focus:outline-none focus:ring-2 focus:ring-[#4A494A] text-[#1F1F1F] placeholder-[#A2A1A1]"
          />
        )}

        <div className="space-y-3">
          {filteredUsers.length === 0 ? (
            <div className="text-center py-8 text-[#4A494A]">
              {searchTerm ? 'Aranan oyuncu bulunamadı.' : 'Henüz seçili oyuncu bulunmuyor.'}
            </div>
          ) : (
            filteredUsers.map((user) => (
              <motion.div
                key={user.id}
                onClick={() => navigate(`/players/${user.id}`)}
                className="bg-white rounded-lg p-4 shadow-sm border border-[#A2A1A1]/20 cursor-pointer"
                whileHover={{ scale: 1.01 }}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
              >
                <div className="flex items-center gap-4">
                  {user.profileImage ? (
                    <img
                      src={user.profileImage}
                      alt={user.firstName}
                      className="w-16 h-16 rounded-lg object-cover border border-[#F1F0F0]"
                    />
                  ) : (
                    <div className="w-16 h-16 rounded-lg bg-[#F1F0F0] flex items-center justify-center">
                      <FaUser className="text-[#A2A1A1]" size={24} />
                    </div>
                  )}
                  
                  <div className="flex-1 min-w-0">
                    <h3 className="text-[#1F1F1F] font-medium truncate">
                      {user.firstName} {user.lastName}
                    </h3>
                    {user.position && (
                      <p className="text-sm text-[#4A494A] truncate mt-1">
                        {user.position}
                      </p>
                    )}
                    <div className="flex items-center gap-2 mt-1 text-xs text-[#535453]">
                      {user.birthDate && (
                        <span>{calculateAge(user.birthDate)} yaş</span>
                      )}
                      {user.city && (
                        <>
                          <span>•</span>
                          <span>{user.city}</span>
                        </>
                      )}
                    </div>
                  </div>

                  <motion.button
                    onClick={(e) => handleDeleteUser(user.id, e)}
                    className="text-[#4A494A] hover:text-[#1F1F1F] p-2"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                  >
                    <FaTrash size={16} />
                  </motion.button>
                </div>
              </motion.div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}

export default SelectedUsers;
