import React, { useState, useEffect } from 'react';
import { getDatabase, ref, get, update } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { motion } from 'framer-motion';
import { FaCheckCircle, FaTimesCircle, FaSpinner, FaArrowLeft, FaClock, FaExclamationCircle } from 'react-icons/fa';

const db = getDatabase();

function ProfileApproval() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProfileData = async () => {
      if (user) {
        const userRef = ref(db, `users/${user.uid}/profilecv`);
        try {
          const snapshot = await get(userRef);
          if (snapshot.exists()) {
            const data = snapshot.val();
            console.log("Fetched profile data:", data); // Debug log
            setProfileData(data);
          } else {
            console.log("No profile data found"); // Debug log
          }
        } catch (error) {
          console.error("Profil verisi çekilirken hata oluştu:", error);
          setError("Profil bilgileri yüklenirken bir hata oluştu.");
        } finally {
          setLoading(false);
        }
      }
    };

    fetchProfileData();
  }, [user]);

  const isProfileComplete = () => {
    console.log("Checking profile completion:", profileData); // Debug log
    return profileData && 
           profileData.globalcv && 
           profileData.kolaj && 
           profileData.introVideo;
  };

  const handleSubmitForApproval = async () => {
    if (!isProfileComplete()) {
      setError("Lütfen tüm zorunlu alanları doldurun.");
      return;
    }

    setSubmitting(true);
    setError(null);

    try {
      const userRef = ref(db, `users/${user.uid}/profilecv`);
      await update(userRef, {
        approvalStatus: 'pending',
        approvalRequestDate: new Date().toISOString()
      });
      console.log("Approval request sent successfully"); // Debug log
      alert("Profiliniz onay için gönderildi. Yönetici incelemesinden sonra size bilgi verilecektir.");
      navigate('/profile');
    } catch (error) {
      console.error("Onay gönderilirken hata oluştu:", error);
      setError("Onay gönderilirken bir hata oluştu. Lütfen daha sonra tekrar deneyin.");
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-[#F1F0F0]">
        <FaSpinner className="animate-spin text-4xl text-[#292929]" />
      </div>
    );
  }

  const isComplete = isProfileComplete();
  console.log("Is profile complete:", isComplete); // Debug log

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="min-h-screen bg-[#F1F0F0]"
    >
      {/* Header */}
      <motion.div 
        className="flex items-center bg-white p-4 pb-2 justify-between shadow-sm border-b border-[#A2A1A1]/20"
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <motion.div
          className="text-[#1F1F1F] flex size-12 shrink-0 items-center cursor-pointer"
          onClick={() => navigate(-1)}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <FaArrowLeft size={24} />
        </motion.div>
        <h2 className="text-[#1F1F1F] text-lg font-bold leading-tight tracking-[-0.015em] flex-1 text-center pr-12">
          Profil Onayı
        </h2>
      </motion.div>

      {/* Onay Durumu Mesajları */}
      {profileData?.approvalStatus === 'approved' && (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="bg-white border border-[#A2A1A1]/20 text-[#1F1F1F] p-4 mb-4 mx-4 mt-4 rounded-lg shadow-sm"
        >
          <div className="flex items-center">
            <FaCheckCircle className="text-green-500 mr-2" size={20} />
            <p className="font-medium">Hesabınız onaylanmıştır!</p>
          </div>
          <p className="text-sm text-[#4A494A] mt-2">
            Onaylanma Tarihi: {new Date(profileData.approvalDate).toLocaleDateString('tr-TR')}
          </p>
        </motion.div>
      )}

      {profileData?.approvalStatus === 'pending' && (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="bg-white border border-[#A2A1A1]/20 text-[#1F1F1F] p-4 mb-4 mx-4 mt-4 rounded-lg shadow-sm"
        >
          <div className="flex items-center">
            <FaClock className="text-[#4A494A] mr-2" size={20} />
            <p className="font-medium">Hesabınız onay beklemektedir.</p>
          </div>
          <p className="text-sm text-[#4A494A] mt-2">
            Başvuru Tarihi: {new Date(profileData.approvalRequestDate).toLocaleDateString('tr-TR')}
          </p>
        </motion.div>
      )}

      {profileData?.approvalStatus === 'rejected' && (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="bg-white border border-[#A2A1A1]/20 text-[#1F1F1F] p-4 mb-4 mx-4 mt-4 rounded-lg shadow-sm"
        >
          <div className="flex items-start">
            <FaExclamationCircle className="text-red-500 mr-2 mt-1" size={20} />
            <div>
              <p className="font-medium">Hesabınız onaylanmadı.</p>
              <p className="text-sm text-[#4A494A] mt-2">
                Red Sebebi: {profileData.rejectionReason}
              </p>
              <p className="text-sm text-[#4A494A] mt-1">
                Red Tarihi: {new Date(profileData.approvalDate).toLocaleDateString('tr-TR')}
              </p>
              <p className="text-sm text-[#4A494A] mt-2">
                Lütfen gerekli düzeltmeleri yapıp tekrar başvurunuz.
              </p>
            </div>
          </div>
        </motion.div>
      )}

      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.3 }}
        className="max-w-md mx-auto bg-white rounded-lg shadow-sm border border-[#A2A1A1]/20 p-6 mt-4 m-4"
      >
        <div className="space-y-4 mb-6">
          <ProfileItem
            label="CV"
            completed={!!profileData?.globalcv}
          />
          <ProfileItem
            label="Kolaj"
            completed={!!profileData?.kolaj}
          />
          <ProfileItem
            label="Tanıtım Videosu"
            completed={!!profileData?.introVideo}
          />
        </div>

        {error && (
          <p className="text-red-500 mb-4 text-sm">{error}</p>
        )}

        <button
          onClick={handleSubmitForApproval}
          disabled={!isComplete || submitting || profileData?.approvalStatus === 'approved' || profileData?.approvalStatus === 'pending'}
          className={`w-full py-3 px-4 rounded-lg font-medium transition-colors duration-200 ${
            isComplete && !submitting && profileData?.approvalStatus !== 'approved' && profileData?.approvalStatus !== 'pending'
              ? 'bg-[#292929] hover:bg-[#4A494A] text-white'
              : 'bg-[#F1F0F0] text-[#A2A1A1] cursor-not-allowed'
          }`}
        >
          {submitting ? (
            <FaSpinner className="animate-spin inline mr-2" />
          ) : null}
          {profileData?.approvalStatus === 'approved' 
            ? 'Hesap Onaylandı' 
            : profileData?.approvalStatus === 'pending'
            ? 'Onay Bekleniyor'
            : profileData?.approvalStatus === 'rejected'
            ? 'Tekrar Başvur'
            : 'Yöneticiye Onaya Gönder'}
        </button>
      </motion.div>
    </motion.div>
  );
}

function ProfileItem({ label, completed }) {
  return (
    <div className="flex items-center justify-between p-4 bg-[#F1F0F0] rounded-lg border border-[#A2A1A1]/10">
      <span className="text-[#1F1F1F] font-medium">{label}</span>
      {completed ? (
        <FaCheckCircle className="text-[#4A494A]" size={20} />
      ) : (
        <FaTimesCircle className="text-[#A2A1A1]" size={20} />
      )}
    </div>
  );
}

export default ProfileApproval;
