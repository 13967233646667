import React, { useState, useEffect } from 'react';
import { getDatabase, ref, get, update } from 'firebase/database';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { motion } from 'framer-motion';

const EditProject = () => {
  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [location, setLocation] = useState('');
  const [city, setCity] = useState('');
  const [projectType, setProjectType] = useState('');
  const [image, setImage] = useState(null);
  const [currentImageUrl, setCurrentImageUrl] = useState('');
  const [hairColor, setHairColor] = useState('');
  const [eyeColor, setEyeColor] = useState('');
  const [height, setHeight] = useState('');
  const [weight, setWeight] = useState('');
  const [shoeSize, setShoeSize] = useState('');
  const [loading, setLoading] = useState(true);
  const [imagePreview, setImagePreview] = useState(null);
  const [menNeeded, setMenNeeded] = useState(0);
  const [womenNeeded, setWomenNeeded] = useState(0);
  const [childrenNeeded, setChildrenNeeded] = useState(0);

  const navigate = useNavigate();
  const { user } = useAuth();
  const { projectId } = useParams();

  useEffect(() => {
    const fetchProject = async () => {
      const db = getDatabase();
      const projectRef = ref(db, `projects/${projectId}`);
      try {
        const snapshot = await get(projectRef);
        if (snapshot.exists()) {
          const projectData = snapshot.val();
          setTitle(projectData.title);
          setDate(projectData.date);
          setStartTime(projectData.startTime || '');
          setEndTime(projectData.endTime || '');
          setLocation(projectData.location);
          setCity(projectData.city);
          setProjectType(projectData.projectType);
          setCurrentImageUrl(projectData.imageUrl);
          setHairColor(projectData.requirements?.hairColor || '');
          setEyeColor(projectData.requirements?.eyeColor || '');
          setHeight(projectData.requirements?.height || '');
          setWeight(projectData.requirements?.weight || '');
          setShoeSize(projectData.requirements?.shoeSize || '');
          setMenNeeded(projectData.peopleNeeded?.men || 0);
          setWomenNeeded(projectData.peopleNeeded?.women || 0);
          setChildrenNeeded(projectData.peopleNeeded?.children || 0);
        } else {
          console.error("Proje bulunamadı");
          navigate('/project-management');
        }
      } catch (error) {
        console.error("Proje yüklenirken hata oluştu:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProject();
  }, [projectId, navigate]);

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      // Resim önizlemesi için URL oluştur
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const db = getDatabase();
    const storage = getStorage();

    try {
      let imageUrl = currentImageUrl;
      if (image) {
        const imageRef = storageRef(storage, `projectImages/${image.name}`);
        await uploadBytes(imageRef, image);
        imageUrl = await getDownloadURL(imageRef);
      }

      const projectRef = ref(db, `projects/${projectId}`);
      await update(projectRef, {
        title,
        date,
        startTime,
        endTime,
        location,
        city,
        projectType,
        imageUrl,
        peopleNeeded: {
          men: menNeeded,
          women: womenNeeded,
          children: childrenNeeded
        },
        requirements: {
          hairColor,
          eyeColor,
          height,
          weight,
          shoeSize
        },
        updatedAt: new Date().toISOString()
      });

      alert("Proje başarıyla güncellendi!");
      navigate('/project-management');
    } catch (error) {
      console.error("Proje güncellenirken hata oluştu:", error);
      alert("Proje güncellenirken bir hata oluştu.");
    }
  };

  if (loading) {
    return <div>Yükleniyor...</div>;
  }

  return (
    <div
      className="relative flex min-h-screen flex-col bg-neutral-50 overflow-x-hidden pb-20"
      style={{ fontFamily: '"Work Sans", "Noto Sans", sans-serif' }}
    >
      <div className="flex items-center bg-neutral-50 p-4 pb-2 justify-between">
        <div className="text-[#141414] flex size-12 shrink-0 items-center cursor-pointer" onClick={() => navigate(-1)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
            <path d="M224,128a8,8,0,0,1-8,8H59.31l58.35,58.34a8,8,0,0,1-11.32,11.32l-72-72a8,8,0,0,1,0-11.32l72-72a8,8,0,0,1,11.32,11.32L59.31,120H216A8,8,0,0,1,224,128Z"></path>
          </svg>
        </div>
        <h2 className="text-[#141414] text-lg font-bold leading-tight tracking-[-0.015em] flex-1 text-center">Proje Düzenle</h2>
        <div className="flex w-12 items-center justify-end">
          <button onClick={handleSubmit} className="text-neutral-500 text-base font-bold leading-normal tracking-[0.015em] shrink-0">
            Kaydet
          </button>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4 px-4">
        {/* Proje Başlığı */}
        <div className="flex flex-col">
          <label className="text-[#141414] text-base font-medium leading-normal pb-2">Proje</label>
          <input
            placeholder="Başlık"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="form-input rounded-xl bg-[#ededed] h-14 p-4"
          />
        </div>

        {/* Bitiş Tarihi */}
        <div className="flex flex-col">
          <label className="text-[#141414] text-base font-medium leading-normal pb-2">Bitiş Tarihi</label>
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            className="form-input rounded-xl bg-[#ededed] h-14 p-4"
          />
        </div>

        {/* Başlangıç Saati */}
        <div className="flex flex-col">
          <label className="text-[#141414] text-base font-medium leading-normal pb-2">Başlangıç Saati</label>
          <input
            type="time"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            className="form-input rounded-xl bg-[#ededed] h-14 p-4"
          />
        </div>

        {/* Bitiş Saati */}
        <div className="flex flex-col">
          <label className="text-[#141414] text-base font-medium leading-normal pb-2">Bitiş Saati</label>
          <input
            type="time"
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
            className="form-input rounded-xl bg-[#ededed] h-14 p-4"
          />
        </div>

        {/* Konum */}
        <div className="flex flex-col">
          <label className="text-[#141414] text-base font-medium leading-normal pb-2">Konum</label>
          <input
            placeholder="Konum"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            className="form-input rounded-xl bg-[#ededed] h-14 p-4"
          />
        </div>

        {/* Şehir */}
        <div className="flex flex-col">
          <label className="text-[#141414] text-base font-medium leading-normal pb-2">Şehir</label>
          <input
            placeholder="Şehir"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            className="form-input rounded-xl bg-[#ededed] h-14 p-4"
          />
        </div>

        {/* Resim Ekle */}
        <div className="flex flex-col">
          <label className="text-[#141414] text-base font-medium leading-normal pb-2">Resim Ekle</label>
          <div className="flex items-center space-x-4">
            <label className="cursor-pointer flex items-center justify-center w-32 h-32 bg-[#ededed] rounded-xl overflow-hidden">
              <input type="file" accept="image/*" onChange={handleImageChange} className="hidden" />
              {imagePreview || currentImageUrl ? (
                <img 
                  src={imagePreview || currentImageUrl} 
                  alt="Proje görseli" 
                  className="w-full h-full object-cover"
                />
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
                  <path d="M208,56H180.28L166.65,35.56A8,8,0,0,0,160,32H96a8,8,0,0,0-6.65,3.56L75.71,56H48A24,24,0,0,0,24,80V192a24,24,0,0,0,24,24H208a24,24,0,0,0,24-24V80A24,24,0,0,0,208,56Zm8,136a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V80a8,8,0,0,1,8-8H80a8,8,0,0,0,6.66-3.56L100.28,48h55.43l13.63,20.44A8,8,0,0,0,176,72h32a8,8,0,0,1,8,8ZM128,88a44,44,0,1,0,44,44A44.05,44.05,0,0,0,128,88Zm0,72a28,28,0,1,1,28-28A28,28,0,0,1,128,160Z"></path>
                </svg>
              )}
            </label>
          </div>
        </div>

        {/* Proje Türü */}
        <div className="flex flex-col">
          <label className="text-[#141414] text-base font-medium leading-normal pb-2">Proje Türü</label>
          <select
            value={projectType}
            onChange={(e) => setProjectType(e.target.value)}
            className="form-select rounded-xl bg-[#ededed] h-14 p-4"
          >
            <option value="">Seçiniz</option>
            <option value="Reklam Filmi">Reklam Filmi</option>
            <option value="Yerli Dizi">Yerli Dizi</option>
            <option value="Yabancı Dizi">Yabancı Dizi</option>
            <option value="Yabancı Film">Yabancı Film</option>
          </select>
        </div>

        <h2 className="text-[#141414] text-lg font-semibold mt-6 mb-2">İhtiyaç Duyulan Kişi Sayısı</h2>

        {/* Erkek Sayısı */}
        <div className="flex flex-col">
          <label className="text-[#141414] text-base font-medium leading-normal pb-2">Erkek Sayısı</label>
          <input
            type="number"
            min="0"
            value={menNeeded}
            onChange={(e) => setMenNeeded(parseInt(e.target.value))}
            className="form-input rounded-xl bg-[#ededed] h-14 p-4"
          />
        </div>

        {/* Kadın Sayısı */}
        <div className="flex flex-col">
          <label className="text-[#141414] text-base font-medium leading-normal pb-2">Kadın Sayısı</label>
          <input
            type="number"
            min="0"
            value={womenNeeded}
            onChange={(e) => setWomenNeeded(parseInt(e.target.value))}
            className="form-input rounded-xl bg-[#ededed] h-14 p-4"
          />
        </div>

        {/* Çocuk Sayısı */}
        <div className="flex flex-col">
          <label className="text-[#141414] text-base font-medium leading-normal pb-2">Çocuk Sayısı</label>
          <input
            type="number"
            min="0"
            value={childrenNeeded}
            onChange={(e) => setChildrenNeeded(parseInt(e.target.value))}
            className="form-input rounded-xl bg-[#ededed] h-14 p-4"
          />
        </div>

        <h2 className="text-[#141414] text-lg font-semibold mt-6 mb-2">Oyuncu Özellikleri (Opsiyonel)</h2>

        {/* Saç Rengi */}
        <div className="flex flex-col">
          <label className="text-[#141414] text-base font-medium leading-normal pb-2">Saç Rengi</label>
          <select
            value={hairColor}
            onChange={(e) => setHairColor(e.target.value)}
            className="form-select rounded-xl bg-[#ededed] h-14 p-4"
          >
            <option value="">Seçiniz</option>
            <option value="siyah">Siyah</option>
            <option value="kahverengi">Kahverengi</option>
            <option value="sarı">Sarı</option>
            <option value="kızıl">Kızıl</option>
            <option value="gri">Gri</option>
          </select>
        </div>

        {/* Göz Rengi */}
        <div className="flex flex-col">
          <label className="text-[#141414] text-base font-medium leading-normal pb-2">Göz Rengi</label>
          <select
            value={eyeColor}
            onChange={(e) => setEyeColor(e.target.value)}
            className="form-select rounded-xl bg-[#ededed] h-14 p-4"
          >
            <option value="">Seçiniz</option>
            <option value="kahverengi">Kahverengi</option>
            <option value="mavi">Mavi</option>
            <option value="yeşil">Yeşil</option>
            <option value="ela">Ela</option>
          </select>
        </div>

        {/* Boy */}
        <div className="flex flex-col">
          <label className="text-[#141414] text-base font-medium leading-normal pb-2">Boy (cm)</label>
          <select
            value={height}
            onChange={(e) => setHeight(e.target.value)}
            className="form-select rounded-xl bg-[#ededed] h-14 p-4"
          >
            <option value="">Seçiniz</option>
            {[...Array(81)].map((_, i) => (
              <option key={i} value={150 + i}>{150 + i} cm</option>
            ))}
          </select>
        </div>

        {/* Kilo */}
        <div className="flex flex-col">
          <label className="text-[#141414] text-base font-medium leading-normal pb-2">Kilo (kg)</label>
          <select
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
            className="form-select rounded-xl bg-[#ededed] h-14 p-4"
          >
            <option value="">Seçiniz</option>
            {[...Array(101)].map((_, i) => (
              <option key={i} value={40 + i}>{40 + i} kg</option>
            ))}
          </select>
        </div>

        {/* Ayak Numarası */}
        <div className="flex flex-col">
          <label className="text-[#141414] text-base font-medium leading-normal pb-2">Ayak Numarası</label>
          <select
            value={shoeSize}
            onChange={(e) => setShoeSize(e.target.value)}
            className="form-select rounded-xl bg-[#ededed] h-14 p-4"
          >
            <option value="">Seçiniz</option>
            {[...Array(21)].map((_, i) => (
              <option key={i} value={35 + i}>{35 + i}</option>
            ))}
          </select>
        </div>

        {/* Proje Güncelle Butonu */}
        <div className="pt-6">
          <button type="submit" className="w-full bg-neutral-100 text-[#141414] font-bold py-3 px-4 rounded-xl">
            Proje Güncelle
          </button>
        </div>
      </form>

      <div className="h-20 bg-neutral-50"></div>
    </div>
  );
};

export default EditProject;
