import React, { useState } from 'react';
import { auth } from '../src/firebaseconf'; // Firebase auth servisini içe aktar
import { sendPasswordResetEmail } from 'firebase/auth'; // Şifre sıfırlama fonksiyonunu içe aktar
import { Container, TextField, Button, Typography, Box, IconButton } from '@mui/material'; 
import { ArrowBack } from '@mui/icons-material'; 
import { useNavigate } from 'react-router-dom'; 

function ResetPassword() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setSuccess(true);
      setError(null);
    } catch (error) {
      setError(error.message);
      setSuccess(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh', justifyContent: 'space-around' }}>
      <IconButton onClick={() => navigate(-1)} sx={{ alignSelf: 'flex-start', mb: 2, color: 'black' }}>
        <ArrowBack />
      </IconButton>

      <form className="bg-white p-6 rounded-lg w-full" onSubmit={handleResetPassword}>
        <Typography variant="h5" align="center" gutterBottom>
          Şifre Sıfırlama
        </Typography>

        {error && <Typography color="error" variant="body2" align="center">{error}</Typography>}
        {success && <Typography color="success.main" variant="body2" align="center">Şifre sıfırlama e-postası gönderildi!</Typography>}

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="E-Posta"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ 
            mt: 3, 
            mb: 2, 
            backgroundColor: 'var(--koyu-komur-gri)', // Yeni renk değişkenini kullan
            '&:hover': { backgroundColor: 'var(--yogun-antrasit)' }, // Yeni renk değişkenini kullan
            fontWeight: 'bold', 
            padding: '12px', 
          }} 
        >
          Şifreyi Sıfırla
        </Button>
      </form>

      <Box sx={{ mt: 4, textAlign: 'center', borderTop: '1px solid #e0e0e0', pt: 2 }}>
        <Typography variant="body2" color="textSecondary">
          2024 Klaket ©
        </Typography>
      </Box>
    </Container>
  );
}

export default ResetPassword;