import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { FaBell, FaUser, FaSignOutAlt } from 'react-icons/fa';
import KlaketLogo from '../assets/logo.svg';

const DesktopHeader = () => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error("Çıkış yapılırken hata:", error);
    }
  };

  return (
    <div className="hidden md:block border-b border-[#4A494A]/10">
      <div className="max-w-7xl mx-auto px-8">
        <div className="flex items-center justify-between h-20">
          {/* Sol Kısım - Logo ve Menü */}
          <div className="flex items-center gap-8">
            {/* Logo */}
            <div 
              className="cursor-pointer"
              onClick={() => navigate('/')}
            >
              <img 
                src={KlaketLogo} 
                alt="Klaket Logo" 
                className="h-8"
              />
            </div>

            {/* Ana Menü */}
            <nav className="flex items-center gap-6">
              <button 
                onClick={() => navigate('/dashboard')}
                className="text-[#292929] hover:text-[#4A494A] text-sm font-medium transition-colors"
              >
                Ana Sayfa
              </button>
              <button 
                onClick={() => navigate('/projects')}
                className="text-[#292929] hover:text-[#4A494A] text-sm font-medium transition-colors"
              >
                Projeler
              </button>
              <button 
                onClick={() => navigate('/campaigns')}
                className="text-[#292929] hover:text-[#4A494A] text-sm font-medium transition-colors"
              >
                Kampanyalar
              </button>
            </nav>
          </div>

          {/* Sağ Kısım - Kullanıcı Menüsü */}
          <div className="flex items-center gap-6">
            {/* Bildirimler */}
            <button className="text-[#292929] hover:text-[#4A494A] transition-colors relative">
              <FaBell size={20} />
              <span className="absolute -top-1 -right-1 bg-red-500 text-[#F1F0F0] text-xs w-4 h-4 flex items-center justify-center rounded-full">
                2
              </span>
            </button>

            {/* Kullanıcı Menüsü */}
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-3 cursor-pointer" onClick={() => navigate('/profile')}>
                {/* Profil Fotoğrafı */}
                <div className="size-8 rounded-full bg-[#4A494A] flex items-center justify-center text-[#F1F0F0] overflow-hidden">
                  {user?.profileImage ? (
                    <img 
                      src={user.profileImage} 
                      alt="Profile" 
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <FaUser size={16} />
                  )}
                </div>
                {/* Kullanıcı Adı */}
                <div className="flex flex-col">
                  <span className="text-[#292929] text-sm font-medium leading-tight">
                    {user?.firstName} {user?.lastName}
                  </span>
                  <span className="text-[#4A494A] text-xs leading-tight">
                    {user?.role === 'admin' ? 'Yönetici' : 
                     user?.role === 'production' ? 'Yapım' : 'Kullanıcı'}
                  </span>
                </div>
              </div>

              {/* Çıkış Butonu */}
              <button 
                onClick={handleLogout}
                className="text-[#4A494A] hover:text-red-500 transition-colors"
              >
                <FaSignOutAlt size={20} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopHeader;
