import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onValue, get } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useAuth } from '../context/AuthContext';

const db = getDatabase();

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('personal');
  const [userProfile, setUserProfile] = useState(null);
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (user) {
        const userRef = ref(db, `users/${user.uid}/profilecv/globalcv`);

        try {
          const userSnapshot = await get(userRef);
          const userData = userSnapshot.val();

          if (userData) {
            console.log('Global CV:', userData);
            setUserProfile(userData);
          } else {
            console.log('Kullanıcı profili bulunamadı');
          }
        } catch (error) {
          console.error("Kullanıcı profili yüklenirken hata oluştu:", error);
        }
      }
    };

    fetchUserProfile();
  }, [user]);

  useEffect(() => {
    const projectsRef = ref(db, 'projects');
    onValue(projectsRef, (snapshot) => {
      const data = snapshot.val();
      const loadedProjects = [];
      for (let id in data) {
        loadedProjects.push({ id, ...data[id] });
      }
      setProjects(loadedProjects);
      setLoading(false);
    });
  }, []);

  const isProjectSuitableForUser = (project, userProfile) => {
    if (!project.requirements || !userProfile) return true;

    const { requirements } = project;

    console.log('Project Requirements:', requirements);
    console.log('User Profile:', userProfile);

    // Yaş kontrolü
    if (userProfile.birthDate) {
      const birthDate = new Date(userProfile.birthDate);
      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      console.log('User Age:', age);

      // 18 yaşından küçükse ve proje çocuk oyuncu aramıyorsa uygun değil
      if (age < 18 && !requirements.childActor) return false;

      // 18 yaşından büyükse ve proje çocuk oyuncu arıyorsa uygun değil
      if (age >= 18 && requirements.childActor) return false;
    }

    // Boy kontrolü
    if (requirements.height && userProfile.height) {
      console.log('Required Height:', requirements.height);
      console.log('User Height:', userProfile.height);
      
      const minHeight = parseInt(requirements.height) - 5;
      const maxHeight = parseInt(requirements.height) + 5;
      console.log('Min Height:', minHeight);
      console.log('Max Height:', maxHeight);
      
      if (parseInt(userProfile.height) < minHeight || parseInt(userProfile.height) > maxHeight) {
        console.log('Height does not match');
        return false;
      }
    }

    // Kilo kontrolü
    if (requirements.weight && userProfile.weight) {
      console.log('Required Weight:', requirements.weight);
      console.log('User Weight:', userProfile.weight);
      
      const minWeight = parseInt(requirements.weight) - 5;
      const maxWeight = parseInt(requirements.weight) + 5;
      console.log('Min Weight:', minWeight);
      console.log('Max Weight:', maxWeight);
      
      if (parseInt(userProfile.weight) < minWeight || parseInt(userProfile.weight) > maxWeight) {
        console.log('Weight does not match');
        return false;
      }
    }

    // Saç rengi kontrolü
    if (requirements.hairColor && userProfile.hairColor) {
      console.log('Required Hair Color:', requirements.hairColor);
      console.log('User Hair Color:', userProfile.hairColor);
      if (requirements.hairColor !== userProfile.hairColor) {
        console.log('Hair color does not match');
        return false;
      }
    }

    // Göz rengi kontrolü
    if (requirements.eyeColor && userProfile.eyeColor) {
      console.log('Required Eye Color:', requirements.eyeColor);
      console.log('User Eye Color:', userProfile.eyeColor);
      if (requirements.eyeColor !== userProfile.eyeColor) {
        console.log('Eye color does not match');
        return false;
      }
    }

    // Ayakkabı numarası kontrolü
    if (requirements.shoeSize && userProfile.shoeSize) {
      console.log('Required Shoe Size:', requirements.shoeSize);
      console.log('User Shoe Size:', userProfile.shoeSize);
      if (requirements.shoeSize !== userProfile.shoeSize) {
        console.log('Shoe size does not match');
        return false;
      }
    }

    console.log('User is suitable for the project');
    return true;
  };

  useEffect(() => {
    if (user && userProfile) {
      let suitableProjects = [];
      let otherProjects = [];

      projects.forEach(project => {
        if (project.userId === user.uid) {
          // Kullanıcının kendi projeleri
          suitableProjects.push(project);
        } else if (isProjectSuitableForUser(project, userProfile)) {
          // Kullanıcıya uygun projeler
          suitableProjects.push(project);
        } else {
          // Kullanıcıya uygun olmayan projeler
          otherProjects.push(project);
        }
      });

      if (filter === 'personal') {
        setFilteredProjects(suitableProjects);
      } else if (filter === 'other') {
        setFilteredProjects(otherProjects);
      } else if (filter === 'concluded') {
        setFilteredProjects(projects.filter(project => project.status === 'concluded'));
      }
    }
  }, [filter, projects, user, userProfile]);

  const handleProjectClick = (id) => {
    navigate(`/projects/${id}`);
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { 
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { 
      y: 0, 
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100
      }
    }
  };

  return (
    <motion.div 
      className="relative flex min-h-screen flex-col bg-[#FFFFFF] justify-between overflow-x-hidden" 
      style={{ fontFamily: '"Work Sans", "Noto Sans", sans-serif' }}
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <div>
        <motion.div 
          className="flex items-center bg-[#FFFFFF] p-4 pb-2 justify-between"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="text-black text-lg font-bold leading-tight tracking-[-0.015em] flex-1 text-center pl-12">
            Projeler
          </h2>
          <div className="flex w-12 items-center justify-end">
            <motion.button 
              className="flex max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-12 bg-transparent text-black gap-2 text-base font-bold leading-normal tracking-[0.015em] min-w-0 p-0"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <div className="text-black" data-icon="MagnifyingGlass" data-size="24px" data-weight="regular">
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
                  <path d="M229.66,218.34l-50.07-50.06a88.11,88.11,0,1,0-11.31,11.31l50.06,50.07a8,8,0,0,0,11.32-11.32ZM40,112a72,72,0,1,1,72,72A72.08,72.08,0,0,1,40,112Z"></path>
                </svg>
              </div>
            </motion.button>
          </div>
        </motion.div>

        <motion.div 
          className="pb-3"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
        >
          <div className="flex border-b border-[#DEDEDE] px-4 gap-8">
            {['personal', 'other', 'concluded'].map((filterType, index) => (
              <motion.a 
                key={filterType}
                className={`flex flex-col items-center justify-center border-b-[3px] ${filter === filterType ? 'border-b-black text-black' : 'border-b-transparent text-[#6B6B6B]'} pb-[13px] pt-4`} 
                onClick={() => setFilter(filterType)}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                initial={{ y: -20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.3 + index * 0.1 }}
              >
                <p className={`${filter === filterType ? 'text-black' : 'text-[#6B6B6B]'} text-sm font-bold leading-normal tracking-[0.015em]`}>
                  {filterType === 'personal' ? 'Projelerim' : filterType === 'other' ? 'Diğer Projeler' : 'Tamamlanan Projeler'}
                </p>
              </motion.a>
            ))}
          </div>
        </motion.div>

        {loading ? (
          <motion.div 
            className="flex justify-center items-center h-64"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <p>Yükleniyor...</p>
          </motion.div>
        ) : filteredProjects.length > 0 ? (
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            {filteredProjects.map((project) => (
              <motion.div 
                className="p-4" 
                key={project.id}
                variants={itemVariants}
              >
                <div className="flex items-stretch justify-between gap-4 rounded-xl">
                  <div className="flex flex-[2_2_0px] flex-col gap-4">
                    <div className="flex flex-col gap-1">
                     
                      <p className="text-black text-base font-bold leading-tight">{project.title}</p>
                      <p className="text-[#6B6B6B] text-sm font-normal leading-normal">
                        {project.projectType && (
                          <span className="mr-2 text-gray-400">{project.projectType}</span>
                        )}
                        {project.city && project.location && (
                          <span>{project.city},<br></br> {project.location}</span>
                        )}
                        {!project.city && project.location && (
                          <span>{project.location}</span>
                        )}
                        {project.city && !project.location && (
                          <span>{project.city}</span>
                        )}
                      </p>
                      <p className="text-[#6B6B6B] text-sm font-normal leading-normal">{project.date}</p>
                    </div>
                    <motion.button 
                      className="flex min-w-[84px] max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-8 px-4 flex-row-reverse bg-[#EEEEEE] text-black text-sm font-medium leading-normal w-fit"
                      onClick={() => handleProjectClick(project.id)}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <span className="truncate">Projeyi Görüntüle</span>
                    </motion.button>
                  </div>
                  <motion.div
                    className="w-full aspect-video rounded-xl flex-1 overflow-hidden"
                    whileHover={{ scale: 1.05 }}
                    transition={{ type: "spring", stiffness: 300 }}
                  >
                    <img
                      src={project.imageUrl || 'https://via.placeholder.com/150'}
                      alt={project.title}
                      className="w-full h-full object-fit"
                    />
                  </motion.div>
                </div>
              </motion.div>
            ))}
          </motion.div>
        ) : (
          <motion.div 
            className="flex justify-center items-center h-64"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <p>Uygun içerik bulunamadı.</p>
          </motion.div>
        )}
      </div>
    </motion.div>
  );
};

export default Projects;
