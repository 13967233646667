import React, { useState, useEffect } from 'react';
import { useSpring, animated } from "@react-spring/web";
import Svg1 from '../assets/svg1';
import Svg2 from '../assets/svg2';
import './SplashScreen.css';

function SplashScreen({ onFinish }) {
  const [isVisible, setIsVisible] = useState(true);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    const animationInterval = setInterval(() => {
      setToggle(prev => !prev);
    }, 1000); // Her 1 saniyede bir SVG değişir

    const timer = setTimeout(() => {
      setIsVisible(false);
      onFinish();
    }, 1500);

    return () => {
      clearTimeout(timer);
      clearInterval(animationInterval);
    };
  }, [onFinish]);

  // Animasyon ayarları
  const props = useSpring({
    opacity: toggle ? 1 : 0,
    transform: toggle ? "scale(1)" : "scale(1)",
    config: { duration: 100 },
  });

  return (
    <>
      {isVisible && (
        <div className="klaket-splash">
          <div style={{ position: "relative", width: "300px", height: "300px" }}>
            {/* İlk SVG */}
            <animated.div style={{ ...props, position: "absolute" }}>
              <Svg2 />
            </animated.div>

            {/* İkinci SVG */}
            <animated.div
              style={{
                ...props,
                opacity: toggle ? 0 : 1,
                transform: toggle ? "scale(1)" : "scale(1)",
                position: "absolute",
              }}
            >
              <Svg1 />
            </animated.div>
          </div>
        </div>
      )}
    </>
  );
}

export default SplashScreen;
