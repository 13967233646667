import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const AlertModal = ({ isOpen, onClose, message }) => {
  if (!isOpen) return null;

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-[#1F1F1F] bg-opacity-20 backdrop-blur-sm flex items-center justify-center z-50"
        onClick={onClose}
      >
        <motion.div
          initial={{ scale: 0.5, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.5, opacity: 0 }}
          className="bg-[#F1F0F0] rounded-2xl p-6 max-w-sm w-full mx-4 shadow-lg border border-[#A2A1A1]"
          onClick={e => e.stopPropagation()}
        >
          <div className="flex flex-col items-center text-center">
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-[#2C2C2D] mb-4">
              <svg 
                className="h-6 w-6 text-[#F1F0F0]" 
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth="2" 
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" 
                />
              </svg>
            </div>
            <h3 className="text-lg font-medium text-[#1F1F1F] mb-2">
              Bilgi
            </h3>
            <p className="text-sm text-[#4A494A] mb-6">
              {message}
            </p>
            <button
              onClick={onClose}
              className="w-full inline-flex justify-center rounded-xl border border-[#535453] px-4 py-2.5 bg-[#292929] text-sm font-medium text-[#F1F0F0] hover:bg-[#4A494A] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#A2A1A1] transition-colors duration-200"
            >
              Anladım
            </button>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default AlertModal;
