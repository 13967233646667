import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { getDatabase, ref, push, onValue, remove } from 'firebase/database';
import { useAuth } from '../../context/AuthContext';

const db = getDatabase();

const AnnouncementPage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [announcements, setAnnouncements] = useState([]);
  const [newAnnouncement, setNewAnnouncement] = useState({ title: '', content: '' });

  useEffect(() => {
    if (!user || !user.uid) {
      navigate('/login');
      return;
    }

    const announcementsRef = ref(db, 'announcements');
    const unsubscribe = onValue(announcementsRef, (snapshot) => {
      const data = snapshot.val();
      const loadedAnnouncements = [];
      for (const key in data) {
        loadedAnnouncements.push({ id: key, ...data[key] });
      }
      setAnnouncements(loadedAnnouncements.reverse());
    });

    return () => unsubscribe();
  }, [user, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAnnouncement(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newAnnouncement.title && newAnnouncement.content) {
      const announcementsRef = ref(db, 'announcements');
      await push(announcementsRef, {
        ...newAnnouncement,
        createdAt: new Date().toISOString(),
        createdBy: user.uid,
        okuyanlar: []
      });
      setNewAnnouncement({ title: '', content: '' });
    }
  };

  const handleDelete = async (id) => {
    const announcementRef = ref(db, `announcements/${id}`);
    await remove(announcementRef);
  };

  return (
    <motion.div 
      className="min-h-screen bg-white flex flex-col"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <motion.div 
        className="sticky top-0 z-10 flex items-center bg-white p-4 pb-2 justify-between shadow-sm border-b border-[#A2A1A1]/20"
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <motion.div
          className="text-[#1F1F1F] flex size-12 shrink-0 items-center cursor-pointer"
          onClick={() => navigate(-1)}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24px"
            height="24px"
            fill="currentColor"
            viewBox="0 0 256 256"
          >
            <path d="M224,128a8,8,0,0,1-8,8H59.31l58.35,58.34a8,8,0,0,1-11.32,11.32l-72-72a8,8,0,0,1,0-11.32l72-72a8,8,0,0,1,11.32,11.32L59.31,120H216A8,8,0,0,1,224,128Z"></path>
          </svg>
        </motion.div>
        <h2 className="text-[#1F1F1F] text-lg font-bold leading-tight tracking-[-0.015em] flex-1 text-center pr-12">
          Duyurular
        </h2>
      </motion.div>

      <motion.div 
        className="flex-1 max-w-4xl mx-auto w-full p-4 md:p-6 overflow-y-auto"
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <form onSubmit={handleSubmit} className="mb-8 bg-white p-6 rounded-lg shadow-sm border border-[#A2A1A1]/20">
          <input
            type="text"
            name="title"
            value={newAnnouncement.title}
            onChange={handleInputChange}
            placeholder="Duyuru Başlığı"
            className="w-full p-3 mb-4 rounded-lg bg-[#F1F0F0] border-none focus:ring-2 focus:ring-[#4A494A] text-[#1F1F1F] placeholder-[#A2A1A1]"
            required
          />
          <textarea
            name="content"
            value={newAnnouncement.content}
            onChange={handleInputChange}
            placeholder="Duyuru İçeriği"
            className="w-full p-3 mb-4 rounded-lg bg-[#F1F0F0] border-none focus:ring-2 focus:ring-[#4A494A] text-[#1F1F1F] placeholder-[#A2A1A1] min-h-[120px]"
            rows="4"
            required
          />
          <button
            type="submit"
            className="w-full bg-[#292929] text-white p-3 rounded-lg hover:bg-[#4A494A] transition-colors duration-300 font-medium"
          >
            Duyuru Yayınla
          </button>
        </form>

        <div className="space-y-4">
          {announcements.map((announcement) => (
            <motion.div
              key={announcement.id}
              className="bg-white p-6 rounded-lg shadow-sm border border-[#A2A1A1]/20"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              <div className="flex justify-between items-start mb-4">
                <h3 className="font-semibold text-lg text-[#1F1F1F]">
                  {announcement.title}
                </h3>
                <button
                  onClick={() => handleDelete(announcement.id)}
                  className="text-[#4A494A] hover:text-[#1F1F1F] transition-colors p-2"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                  </svg>
                </button>
              </div>
              <p className="text-[#4A494A] mb-3 leading-relaxed">
                {announcement.content}
              </p>
              <p className="text-sm text-[#A2A1A1]">
                {new Date(announcement.createdAt).toLocaleString()}
              </p>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </motion.div>
  );
};

export default AnnouncementPage;
