import React, { useState, useEffect } from 'react';

const CitySelect = ({ value, onChange }) => {
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await fetch('https://turkiyeapi.dev/api/v1/provinces');
        const data = await response.json();
        
        if (data.status === "OK") {
          // Şehirleri alfabetik sıraya göre düzenle
          const sortedCities = data.data.sort((a, b) => 
            a.name.localeCompare(b.name, 'tr')
          );
          setCities(sortedCities);
        }
      } catch (error) {
        console.error('Şehirler yüklenirken hata:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCities();
  }, []);

  if (loading) {
    return (
      <select className="w-full p-2 border border-gray-300 rounded-md">
        <option value="">Yükleniyor...</option>
      </select>
    );
  }

  return (
    <select
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
    >
      <option value="">Şehir seçiniz</option>
      {cities.map((city) => (
        <option key={city.id} value={city.name}>
          {city.name}
        </option>
      ))}
    </select>
  );
};

export default CitySelect;
