import React, { useEffect, useState } from 'react';
import { getDatabase, ref, get, update, set } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import toast from 'react-hot-toast';
import { 
  HiOutlineUser,
  HiOutlineSearch,
  HiOutlineCheck,
  HiOutlineStar,
  HiOutlineEye,
  HiOutlineUserGroup,
  HiOutlineUserCircle,
  HiOutlineExclamationCircle
} from 'react-icons/hi';

const toastStyle = {
  style: {
    background: 'black',
    color: 'white',
    borderRadius: '8px',
    padding: '16px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    border: 'none',
    minWidth: '300px',
  },
  className: 'bg-black',
  position: 'bottom-right',
  duration: 2000,
};

const AdminUsers = () => {
  const { user: adminUser } = useAuth();
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showAdmins, setShowAdmins] = useState(false);
  const [selectedPlayers, setSelectedPlayers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const db = getDatabase();
      const usersRef = ref(db, 'users');
      const selectedRef = ref(db, `users/${adminUser.uid}/selectedPlayers`);

      try {
        const [usersSnap, selectedSnap] = await Promise.all([
          get(usersRef),
          get(selectedRef)
        ]);

        if (usersSnap.exists()) {
          const usersData = Object.entries(usersSnap.val())
            .map(([id, userData]) => ({
              id,
              ...userData
            }))
            .filter(user => user.id !== adminUser.uid);
          
          setUsers(usersData);
          setFilteredUsers(usersData.filter(user => user.role !== 'admin'));
        }

        if (selectedSnap.exists()) {
          setSelectedPlayers(Object.keys(selectedSnap.val()));
        }
      } catch (error) {
        console.error("Veri çekerken hata:", error);
      }
    };

    fetchUsers();
  }, [adminUser.uid]);

  const handleSelectPlayer = async (userId, userName) => {
    const db = getDatabase();
    const selectedRef = ref(db, `users/${adminUser.uid}/selectedPlayers/${userId}`);

    try {
      if (selectedPlayers.includes(userId)) {
        await set(selectedRef, null);
        setSelectedPlayers(prev => prev.filter(id => id !== userId));
        toast.custom(
          <div className="flex items-center gap-2 bg-black">
            <HiOutlineStar className="w-5 h-5 text-white" />
            <span className="text-white font-medium">{userName} seçimden kaldırıldı</span>
          </div>,
          toastStyle
        );
      } else {
        await set(selectedRef, true);
        setSelectedPlayers(prev => [...prev, userId]);
        toast.custom(
          <div className="flex items-center gap-2 bg-black">
            <HiOutlineStar className="w-5 h-5 text-white" />
            <span className="text-white font-medium">{userName} seçildi</span>
          </div>,
          toastStyle
        );
      }
    } catch (error) {
      console.error("Seçim hatası:", error);
      toast.custom(
        <div className="flex items-center gap-2 bg-black">
          <HiOutlineExclamationCircle className="w-5 h-5 text-white" />
          <span className="text-white font-medium">Bir hata oluştu</span>
        </div>,
        toastStyle
      );
    }
  };

  return (
    <div className="min-h-screen bg-white p-6">
      {/* Header */}
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold">Kullanıcılar</h1>
          <div className="flex gap-4">
            <button
              onClick={() => {
                setShowAdmins(false);
                setFilteredUsers(users.filter(user => user.role !== 'admin'));
              }}
              className={`px-4 py-2 rounded-lg transition-colors ${
                !showAdmins ? 'bg-black text-white' : 'bg-white text-black border border-gray-200'
              }`}
            >
              <HiOutlineUserGroup className="inline-block mr-2" />
              Kullanıcılar
            </button>
            <button
              onClick={() => {
                setShowAdmins(true);
                setFilteredUsers(users.filter(user => user.role === 'admin'));
              }}
              className={`px-4 py-2 rounded-lg transition-colors ${
                showAdmins ? 'bg-black text-white' : 'bg-white text-black border border-gray-200'
              }`}
            >
              <HiOutlineUserCircle className="inline-block mr-2" />
              Adminler
            </button>
          </div>
        </div>

        {/* Arama */}
        <div className="mb-6">
          <div className="relative">
            <HiOutlineSearch className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
            <input
              type="text"
              placeholder="İsim veya email ile ara..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-1 focus:ring-black"
            />
          </div>
        </div>

        {/* Kullanıcı Listesi */}
        <div className="bg-white border border-gray-200 rounded-lg overflow-hidden">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Kullanıcı
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Email
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Rol
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  İşlemler
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {filteredUsers
                .filter(user => 
                  user.firstName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  user.lastName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  user.email?.toLowerCase().includes(searchTerm.toLowerCase())
                )
                .map(user => (
                  <tr key={user.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="h-10 w-10 rounded-full bg-gray-100 flex items-center justify-center">
                          {user.profileImage ? (
                            <img src={user.profileImage} alt="" className="h-10 w-10 rounded-full object-cover" />
                          ) : (
                            <HiOutlineUser className="h-5 w-5 text-gray-400" />
                          )}
                        </div>
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">
                            {user.firstName} {user.lastName}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {user.email}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="px-2 py-1 text-xs rounded-full bg-gray-100">
                        {user.role === 'admin' ? 'Admin' : 'Kullanıcı'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <div className="flex justify-end gap-2">
                        {user.role !== 'admin' && (
                          <button
                            onClick={() => handleSelectPlayer(user.id, `${user.firstName} ${user.lastName}`)}
                            className={`p-2 rounded-lg transition-colors ${
                              selectedPlayers.includes(user.id)
                                ? 'bg-black text-white'
                                : 'text-gray-400 hover:bg-gray-100'
                            }`}
                            title={selectedPlayers.includes(user.id) ? 'Seçimi Kaldır' : 'Seç'}
                          >
                            <HiOutlineStar size={18} />
                          </button>
                        )}
                        <button
                          onClick={() => navigate(`/admin/users/${user.id}`)}
                          className="p-2 text-gray-400 hover:bg-gray-100 rounded-lg"
                          title="Görüntüle"
                        >
                          <HiOutlineEye size={18} />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AdminUsers;
