import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const Settings = () => {
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const { logout } = useAuth();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error("Çıkış yaparken hata:", error);
      alert("Çıkış yaparken bir hata oluştu.");
    }
  };

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);
  const handleConfirmLogout = () => {
    handleLogout();
    handleCloseDialog();
  };

  return (
    <div className="relative flex min-h-screen flex-col bg-neutral-50 overflow-x-hidden" style={{ fontFamily: '"Work Sans", "Noto Sans", sans-serif' }}>
           <div className="flex items-center bg-[#FFFFFF] p-4 pb-2 justify-between">
          <div className="text-black flex size-12 shrink-0 items-center" onClick={() => navigate(-1)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
              <path d="M224,128a8,8,0,0,1-8,8H59.31l58.35,58.34a8,8,0,0,1-11.32,11.32l-72-72a8,8,0,0,1,0-11.32l72-72a8,8,0,0,1,11.32,11.32L59.31,120H216A8,8,0,0,1,224,128Z"></path>
            </svg>
          </div>
          <h2 className="text-black text-lg font-bold leading-tight tracking-[-0.015em] flex-1 text-center pr-12">
            Ayarlar
          </h2>
        </div>


      {/* Banka Bilgileri */}
      <div className="flex items-center gap-4 bg-neutral-50 px-4 min-h-14 justify-between" onClick={() => navigate('/bank-details')}>
        <div className="flex items-center gap-4">
          <div className="text-[#141414] flex items-center justify-center rounded-lg bg-[#ededed] shrink-0 size-10">
            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
              <path d="M240,208H224V96a16,16,0,0,0-16-16H144V32a16,16,0,0,0-24.88-13.32L39.12,72A16,16,0,0,0,32,85.34V208H16a8,8,0,0,0,0,16H240a8,8,0,0,0,0-16ZM208,96V208H144V96ZM48,85.34,128,32V208H48ZM112,112v16a8,8,0,0,1-16,0V112a8,8,0,1,1,16,0Zm-32,0v16a8,8,0,0,1-16,0V112a8,8,0,1,1,16,0Zm0,56v16a8,8,0,0,1-16,0V168a8,8,0,0,1,16,0Zm32,0v16a8,8,0,0,1-16,0V168a8,8,0,0,1,16,0Z"></path>
            </svg>
          </div>
          <p className="text-[#141414] text-base font-normal leading-normal flex-1 truncate">Banka Bilgileri</p>
        </div>
        <div className="shrink-0">
          <div className="text-[#141414] flex size-7 items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
              <path d="M181.66,133.66l-80,80a8,8,0,0,1-11.32-11.32L164.69,128,90.34,53.66a8,8,0,0,1,11.32-11.32l80,80A8,8,0,0,1,181.66,133.66Z"></path>
            </svg>
          </div>
        </div>
      </div>

  
      {/* Yardım */}
      <div className="flex items-center gap-4 bg-neutral-50 px-4 min-h-14 justify-between" onClick={() => navigate('/help')}>
        <div className="flex items-center gap-4">
          <div className="text-[#141414] flex items-center justify-center rounded-lg bg-[#ededed] shrink-0 size-10">
            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
              <path d="M140,180a12,12,0,1,1-12-12A12,12,0,0,1,140,180ZM128,72c-22.06,0-40,16.15-40,36v4a8,8,0,0,0,16,0v-4c0-11,10.77-20,24-20s24,9,24,20-10.77,20-24,20a8,8,0,0,0-8,8v8a8,8,0,0,0,16,0v-.72c18.24-3.35,32-17.9,32-35.28C168,88.15,150.06,72,128,72Zm104,56A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Z"></path>
            </svg>
          </div>
          <p className="text-[#141414] text-base font-normal leading-normal flex-1 truncate">Yardım</p>
        </div>
        <div className="shrink-0">
          <div className="text-[#141414] flex size-7 items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
              <path d="M181.66,133.66l-80,80a8,8,0,0,1-11.32-11.32L164.69,128,90.34,53.66a8,8,0,0,1,11.32-11.32l80,80A8,8,0,0,1,181.66,133.66Z"></path>
            </svg>
          </div>
        </div>
      </div>

      {/* Çıkış Yap Butonu */}
      <div className="flex px-4 py-3">
        <button 
          onClick={handleOpenDialog}
          className="flex min-w-[84px] max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 px-4 flex-1 bg-[#ededed] text-[#141414] text-sm font-bold leading-normal tracking-[0.015em]"
        >
          <span className="truncate">Çıkış Yap</span>
        </button>
      </div>

      <div className="h-5 bg-neutral-50"></div>

      {/* Çıkış Onay Dialog'u */}
      {openDialog && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-[#F1F0F0] p-6 rounded-2xl shadow-lg w-11/12 max-w-sm">
            <h2 className="text-xl font-bold mb-4 text-center text-[#292929]">Çıkış Yapmak İstiyor Musunuz?</h2>
            
            <div className="flex justify-center space-x-4">
              <button
                onClick={handleCloseDialog}
                className="flex-1 bg-[#4A494A] text-[#F1F0F0] px-4 py-2 rounded-xl hover:bg-[#292929] transition duration-300 ease-in-out font-medium"
              >
                İptal
              </button>
              <button
                onClick={handleConfirmLogout}
                className="flex-1 bg-[#292929] text-[#F1F0F0] px-4 py-2 rounded-xl hover:bg-[#4A494A] transition duration-300 ease-in-out font-medium"
              >
                Çıkış Yap
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;
