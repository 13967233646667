import React from 'react';

const SkillsSelector = ({ skills, selectedSkills, onToggle }) => (
  <div className="grid grid-cols-2 gap-3 mt-4">
    {skills.map(skill => (
      <button
        key={skill}
        onClick={() => onToggle(skill)}
        className={`p-3 rounded-lg text-base ${
          selectedSkills.includes(skill) ? 'bg-black text-white' : 'bg-gray-200 text-gray-800'
        }`}
      >
        {skill}
      </button>
    ))}
  </div>
);

export default SkillsSelector;
