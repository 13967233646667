import React, { useEffect, useState } from 'react';
import { db } from '../../firebaseconf';
import { ref, onValue } from 'firebase/database';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/tr';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
  HiOutlineLocationMarker,
  HiOutlineUserGroup,
  HiOutlineClock,
  HiOutlineInformationCircle
} from 'react-icons/hi';

// Türkçe lokalizasyon
moment.locale('tr');
const localizer = momentLocalizer(moment);

const AdminCalendar = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const projectsRef = ref(db, 'projects');
    onValue(projectsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const formattedEvents = Object.entries(data).map(([id, project]) => ({
          id,
          title: project.title,
          start: new Date(`${project.date}T${project.startTime}`),
          end: new Date(`${project.date}T${project.endTime}`),
          city: project.city,
          location: project.location,
          peopleNeeded: project.peopleNeeded,
          projectType: project.projectType,
          imageUrl: project.imageUrl
        }));
        setEvents(formattedEvents);
      }
      setIsLoading(false);
    });
  }, []);

  const getTotalPeopleNeeded = (peopleNeeded) => {
    return (peopleNeeded?.men || 0) + (peopleNeeded?.women || 0) + (peopleNeeded?.children || 0);
  };

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
  };

  const messages = {
    today: 'Bugün',
    previous: 'Önceki',
    next: 'Sonraki',
    month: 'Ay',
    week: 'Hafta',
    day: 'Gün',
    agenda: 'Ajanda',
    date: 'Tarih',
    time: 'Saat',
    event: 'Etkinlik',
    noEventsInRange: 'Bu aralıkta etkinlik yok',
    showMore: total => `+${total} daha fazla`
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-black">Takvim</h1>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
        {/* Takvim */}
        <div className="lg:col-span-3">
          {isLoading ? (
            <div className="h-[600px] flex items-center justify-center">
              <div className="text-gray-500">Yükleniyor...</div>
            </div>
          ) : (
            <div className="bg-white rounded-xl border border-gray-200 p-4">
              <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 600 }}
                messages={messages}
                onSelectEvent={handleSelectEvent}
                views={['month', 'week', 'day', 'agenda']}
                defaultView="month"
              />
            </div>
          )}
        </div>

        {/* Seçili Etkinlik Detayları */}
        <div className="lg:col-span-1">
          {selectedEvent ? (
            <div className="bg-white rounded-xl border border-gray-200 p-4 space-y-4 sticky top-6">
              <div className="aspect-video bg-gray-100 rounded-lg overflow-hidden">
                {selectedEvent.imageUrl ? (
                  <img 
                    src={selectedEvent.imageUrl} 
                    alt={selectedEvent.title} 
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="w-full h-full flex items-center justify-center bg-gray-200">
                    <HiOutlineInformationCircle size={40} className="text-gray-400" />
                  </div>
                )}
              </div>

              <div>
                <h3 className="text-lg font-semibold text-black">{selectedEvent.title}</h3>
                <span className="inline-block mt-1 px-2 py-1 bg-gray-100 text-gray-600 text-xs rounded-full">
                  {selectedEvent.projectType}
                </span>
              </div>

              <div className="space-y-3 text-sm">
                <div className="flex items-center gap-2 text-gray-600">
                  <HiOutlineClock size={18} />
                  <div>
                    <div>{moment(selectedEvent.start).format('DD MMMM YYYY')}</div>
                    <div>{moment(selectedEvent.start).format('HH:mm')} - {moment(selectedEvent.end).format('HH:mm')}</div>
                  </div>
                </div>

                <div className="flex items-center gap-2 text-gray-600">
                  <HiOutlineLocationMarker size={18} />
                  <div>
                    <div>{selectedEvent.city}</div>
                    <div>{selectedEvent.location}</div>
                  </div>
                </div>

                <div className="flex items-center gap-2 text-gray-600">
                  <HiOutlineUserGroup size={18} />
                  <span>{getTotalPeopleNeeded(selectedEvent.peopleNeeded)} Kişi Gerekli</span>
                </div>
              </div>
            </div>
          ) : (
            <div className="bg-white rounded-xl border border-gray-200 p-4 text-center text-gray-500">
              Detayları görüntülemek için bir etkinlik seçin
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminCalendar;