import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import CVHeader from '../components/CVHeader';

export const TermsOfService = () => {
  return (
    <div className="space-y-6">
      {/* Son Güncelleme */}
      <div className="text-sm text-[var(--grafit-gri)] border-b border-[var(--gri-metalik)]/10 pb-4">
        Son güncelleme tarihi: 1 Mart 2024
      </div>

      {/* Giriş */}
      <section className="space-y-4">
        <h2 className="text-xl font-semibold text-[var(--koyu-komur-gri)]">
          1. Giriş
        </h2>
        <p className="text-[var(--grafit-gri)] leading-relaxed">
          Bu kullanıcı sözleşmesi, Klaket uygulamasını ("Uygulama") kullanımınızı düzenleyen şartları ve koşulları içermektedir. Uygulamayı kullanarak bu sözleşmedeki tüm şartları kabul etmiş sayılırsınız.
        </p>
      </section>

      {/* Hesap Oluşturma ve Güvenlik */}
      <section className="space-y-4">
        <h2 className="text-xl font-semibold text-[var(--koyu-komur-gri)]">
          2. Hesap Oluşturma ve Güvenlik
        </h2>
        <div className="space-y-3">
          <p className="text-[var(--grafit-gri)] leading-relaxed">
            2.1. Uygulamamızda hesap oluşturabilmek için 18 yaşını doldurmuş olmanız gerekmektedir.
          </p>
          <p className="text-[var(--grafit-gri)] leading-relaxed">
            2.2. Hesap bilgilerinizin güvenliğinden siz sorumlusunuz. Şifrenizi kimseyle paylaşmayınız.
          </p>
          <p className="text-[var(--grafit-gri)] leading-relaxed">
            2.3. Hesabınızla yapılan tüm işlemlerden siz sorumlusunuz.
          </p>
        </div>
      </section>

      {/* Kullanım Koşulları */}
      <section className="space-y-4">
        <h2 className="text-xl font-semibold text-[var(--koyu-komur-gri)]">
          3. Kullanım Koşulları
        </h2>
        <div className="space-y-3">
          <p className="text-[var(--grafit-gri)] leading-relaxed">
            3.1. Uygulamayı yasa dışı amaçlarla kullanamazsınız.
          </p>
          <p className="text-[var(--grafit-gri)] leading-relaxed">
            3.2. Diğer kullanıcıların haklarını ihlal edemezsiniz.
          </p>
          <p className="text-[var(--grafit-gri)] leading-relaxed">
            3.3. Uygulamaya zarar verecek faaliyetlerde bulunamazsınız.
          </p>
          <p className="text-[var(--grafit-gri)] leading-relaxed">
            3.4. Platformda paylaşılan içeriklerin telif haklarına saygı göstermelisiniz.
          </p>
        </div>
      </section>

      {/* Fikri Mülkiyet */}
      <section className="space-y-4">
        <h2 className="text-xl font-semibold text-[var(--koyu-komur-gri)]">
          4. Fikri Mülkiyet Hakları
        </h2>
        <div className="space-y-3">
          <p className="text-[var(--grafit-gri)] leading-relaxed">
            4.1. Uygulama içeriğindeki tüm fikri mülkiyet hakları Klaket'e aittir.
          </p>
          <p className="text-[var(--grafit-gri)] leading-relaxed">
            4.2. Kullanıcılar tarafından paylaşılan içeriklerin hakları kullanıcılara aittir.
          </p>
          <p className="text-[var(--grafit-gri)] leading-relaxed">
            4.3. İçeriklerin izinsiz kullanımı ve paylaşımı yasaktır.
          </p>
        </div>
      </section>

      {/* Sorumluluk Reddi */}
      <section className="space-y-4">
        <h2 className="text-xl font-semibold text-[var(--koyu-komur-gri)]">
          5. Sorumluluk Reddi
        </h2>
        <div className="space-y-3">
          <p className="text-[var(--grafit-gri)] leading-relaxed">
            5.1. Uygulama "olduğu gibi" sunulmaktadır.
          </p>
          <p className="text-[var(--grafit-gri)] leading-relaxed">
            5.2. Kullanıcılar arası anlaşmazlıklardan Klaket sorumlu değildir.
          </p>
          <p className="text-[var(--grafit-gri)] leading-relaxed">
            5.3. Hizmet kesintilerinden kaynaklanan zararlardan sorumluluk kabul edilmez.
          </p>
        </div>
      </section>

      {/* Değişiklikler */}
      <section className="space-y-4">
        <h2 className="text-xl font-semibold text-[var(--koyu-komur-gri)]">
          6. Değişiklikler
        </h2>
        <p className="text-[var(--grafit-gri)] leading-relaxed">
          Bu sözleşmede yapılacak değişiklikler, uygulama üzerinden duyurulacaktır. Değişikliklerden sonra uygulamayı kullanmaya devam etmeniz, yeni şartları kabul ettiğiniz anlamına gelir.
        </p>
      </section>

      {/* İletişim */}
      <section className="space-y-4">
        <h2 className="text-xl font-semibold text-[var(--koyu-komur-gri)]">
          7. İletişim
        </h2>
        <p className="text-[var(--grafit-gri)] leading-relaxed">
          Sorularınız için <span className="text-[var(--koyu-komur-gri)] font-medium">info@klaket.com</span> adresinden bizimle iletişime geçebilirsiniz.
        </p>
      </section>
    </div>
  );
};

export default function TermsOfServicePage() {
  const navigate = useNavigate();
  
  return (
    <div className="min-h-screen bg-[var(--kirik-beyaz)]">
      <CVHeader 
        onBack={() => navigate(-1)} 
        title="Kullanıcı Sözleşmesi" 
        showSave={false}
      />
      <div className="max-w-3xl mx-auto p-4 md:p-6">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="bg-white rounded-xl p-6 shadow-sm"
        >
          <TermsOfService />
        </motion.div>
      </div>
    </div>
  );
}
