import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Yönlendirme için
import { FaUser, FaBuilding } from 'react-icons/fa'; // React Icons
import { useAuth } from '../context/AuthContext'; // AuthContext'i içe aktar
import { Typography, Box, Button, Container } from '@mui/material'; // Material-UI bileşenlerini içe aktar
import { motion } from 'framer-motion'; // Framer Motion'u içe aktar
import KlaketLogo from '../assets/logo.svg'; // Klaket logosunu içe aktar

function Home() {
  const navigate = useNavigate();
  const { user } = useAuth(); // Kullanıcı bilgilerini al

  useEffect(() => {
    if (user) {
      navigate('/announcements'); // Kullanıcı girişliyse duyurular sayfasına yönlendir
    }
  }, [user, navigate]);

  const handleSelection = (role) => {
    navigate('/login'); // Oyuncu sayfasına yönlendirme
  };

  return (
    <Container 
      maxWidth={false} 
      disableGutters 
      sx={{
        minHeight: '100vh',
        background: 'linear-gradient(135deg, #FFFFFF 0%, #F1F0F0 100%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 4,
      }}
    >
      <motion.div
        initial={{ opacity: 0, y: -50 }} // Başlangıç durumu
        animate={{ opacity: 1, y: 0 }} // Animasyon durumu
        transition={{ duration: 0.8 }} // Geçiş süresi
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 6 }}>
          <img src={KlaketLogo} alt="Klaket Logo" style={{ width: '80%', maxWidth: '300px' }} />
        </Box>
      </motion.div>

      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 4, mb: 8 }}>
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }} // Başlangıç durumu
          animate={{ opacity: 1, scale: 1 }} // Animasyon durumu
          transition={{ duration: 0.5, delay: 0.3 }} // Geçiş süresi
        >
          <Button
            onClick={() => handleSelection('oyuncu')}
            variant="contained"
            sx={{
              width: 160,
              height: 160,
              backgroundColor: '#292929',
              color: '#F1F0F0',
              '&:hover': { backgroundColor: '#4A494A' },
              borderRadius: 4,
              boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
            }}
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <FaUser size={40} />
              <Typography variant="h6" sx={{ mt: 2, fontWeight: 'bold' }}>
                Oyuncu
              </Typography>
            </Box>
          </Button>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, scale: 0.8 }} // Başlangıç durumu
          animate={{ opacity: 1, scale: 1 }} // Animasyon durumu
          transition={{ duration: 0.5, delay: 0.5 }} // Geçiş süresi
        >
          <Button
            onClick={() => handleSelection('ajans')}
            variant="contained"
            sx={{
              width: 160,
              height: 160,
              backgroundColor: '#292929',
              color: '#F1F0F0',
              '&:hover': { backgroundColor: '#4A494A' },
              borderRadius: 4,
              boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
            }}
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <FaBuilding size={40} />
              <Typography variant="h6" sx={{ mt: 2, fontWeight: 'bold' }}>
                Yapım
              </Typography>
            </Box>
          </Button>
        </motion.div>
      </Box>

      <motion.div
        initial={{ opacity: 0, y: 50 }} // Başlangıç durumu
        animate={{ opacity: 1, y: 0 }} // Animasyon durumu
        transition={{ duration: 0.8, delay: 0.7 }} // Geçiş süresi
      >
        <Box sx={{ textAlign: 'center', pt: 4 }}>
          <Typography variant="body1" sx={{ color: '#292929', fontWeight: 'bold' }}>
            2024 Klaket ©
          </Typography>
        </Box>
      </motion.div>
    </Container>
  );
}

export default Home;
