import React, { useEffect, useState } from 'react';
import { getDatabase, ref, get, update } from 'firebase/database';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  HiOutlineUser,
  HiOutlineMail,
  HiOutlinePhone,
  HiOutlineLocationMarker,
  HiOutlineCalendar,
  HiOutlineIdentification,
  HiOutlineBriefcase,
  HiOutlinePhotograph,
  HiOutlineDocumentText,
  HiOutlineCheck,
  HiOutlineBan,
  HiOutlineArrowLeft,
  HiOutlinePencil,
  HiOutlineEye
} from 'react-icons/hi';

const db = getDatabase();

const translateKey = (key) => {
  const translations = {
    'height': 'Boy',
    'weight': 'Kilo',
    'shoeSize': 'Ayakkabı Numarası',
    'hairColor': 'Saç Rengi',
    'eyeColor': 'Göz Rengi',
    'skinTone': 'Ten Rengi',
    'gender': 'Cinsiyet',
    'birthDate': 'Doğum Tarihi',
    'city': 'Şehir',
    'bodySize': 'Beden',
    'hairStyle': 'Saç Stili',
    'beardStyle': 'Sakal Stili',
    'position': 'Pozisyon'
  };
  return translations[key] || key;
};

const AdminUserDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userRef = ref(db, `users/${id}`);
        const snapshot = await get(userRef);
        
        if (snapshot.exists()) {
          setUser(snapshot.val());
        }
      } catch (error) {
        console.error("Veri çekilirken hata:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [id]);

  const handleStatusUpdate = async (newStatus) => {
    setUpdating(true);
    try {
      const userRef = ref(db, `users/${id}`);
      await update(userRef, {
        status: newStatus,
        updatedAt: new Date().toISOString()
      });
      setUser(prev => ({ ...prev, status: newStatus }));
    } catch (error) {
      console.error("Güncelleme hatası:", error);
    } finally {
      setUpdating(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-black"></div>
      </div>
    );
  }

  if (!user) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <p className="text-lg text-gray-600">Kullanıcı bulunamadı</p>
        <button
          onClick={() => navigate('/admin/users')}
          className="mt-4 px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800"
        >
          Kullanıcı Listesine Dön
        </button>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Üst Bar */}
      <div className="sticky top-0 z-10 bg-white border-b border-gray-200 shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="py-4 flex items-center justify-between">
            <div className="flex items-center gap-4">
              <button
                onClick={() => navigate('/admin/users')}
                className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
              >
                <HiOutlineArrowLeft size={20} />
              </button>
              <h1 className="text-xl font-semibold text-gray-900">Kullanıcı Detayı</h1>
            </div>
            <div className="flex items-center gap-3">
              <button
                onClick={() => handleStatusUpdate(user.status === 'active' ? 'inactive' : 'active')}
                className={`flex items-center px-4 py-2 rounded-lg transition-colors
                  ${user.status === 'active' 
                    ? 'bg-red-50 text-red-700 hover:bg-red-100' 
                    : 'bg-green-50 text-green-700 hover:bg-green-100'}`}
              >
                {user.status === 'active' ? (
                  <>
                    <HiOutlineBan className="mr-2" size={18} />
                    Pasife Al
                  </>
                ) : (
                  <>
                    <HiOutlineCheck className="mr-2" size={18} />
                    Aktife Al
                  </>
                )}
              </button>
              <button
                onClick={() => navigate(`/admin/users/${id}/edit`)}
                className="flex items-center px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors"
              >
                <HiOutlinePencil className="mr-2" size={18} />
                Düzenle
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Ana İçerik */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="grid grid-cols-12 gap-8">
          {/* Sol Kolon - Profil Kartı */}
          <div className="col-span-12 lg:col-span-3">
            <div className="sticky top-24">
              <div className="bg-white rounded-xl shadow-sm p-6">
                <div className="flex flex-col items-center">
                  <div className="w-32 h-32 rounded-full bg-gray-100 flex items-center justify-center overflow-hidden">
                    {user.profileImage ? (
                      <img 
                        src={user.profileImage} 
                        alt={`${user.firstName} ${user.lastName}`}
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <HiOutlineUser size={64} className="text-gray-400" />
                    )}
                  </div>
                  <h2 className="mt-4 text-xl font-semibold">
                    {user.firstName} {user.lastName}
                  </h2>
                  <p className="text-gray-500 text-sm">{user.role || 'Rol Belirtilmemiş'}</p>
                  
                  <div className="mt-6 w-full space-y-4">
                    {user.email && (
                      <div className="flex items-center text-sm">
                        <HiOutlineMail size={20} className="text-gray-400 mr-3" />
                        <span>{user.email}</span>
                      </div>
                    )}
                    {user.phone && (
                      <div className="flex items-center text-sm">
                        <HiOutlinePhone size={20} className="text-gray-400 mr-3" />
                        <span>{user.phone}</span>
                      </div>
                    )}
                    {user.city && (
                      <div className="flex items-center text-sm">
                        <HiOutlineLocationMarker size={20} className="text-gray-400 mr-3" />
                        <span>{user.city}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-4 text-center">
                  <span className="px-3 py-1 bg-gray-100 rounded-full text-sm text-gray-700">
                    {user.profilecv?.globalcv?.position || 'Pozisyon Belirtilmemiş'}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* Sağ Kolon - Detaylar */}
          <div className="col-span-12 lg:col-span-9 space-y-6">
            {/* CV Bilgileri */}
            {user.profilecv?.globalcv && (
              <div className="bg-white rounded-xl shadow-sm overflow-hidden">
                <div className="p-6 border-b border-gray-100">
                  <h3 className="text-lg font-semibold">CV Bilgileri</h3>
                </div>
                <div className="p-6">
                  <div className="grid grid-cols-2 lg:grid-cols-3 gap-6">
                    {Object.entries(user.profilecv.globalcv).map(([key, value]) => {
                      if (key === 'bio' || key === 'description' || typeof value === 'object') return null;
                      return (
                        <div key={key}>
                          <p className="text-sm text-gray-500">{translateKey(key)}</p>
                          <p className="mt-1 font-medium">{value}</p>
                        </div>
                      );
                    })}
                  </div>
                  {user.profilecv.globalcv.bio && (
                    <div className="mt-6">
                      <p className="text-sm text-gray-500">Biyografi</p>
                      <p className="mt-2 text-gray-700">{user.profilecv.globalcv.bio}</p>
                    </div>
                  )}
                </div>
              </div>
            )}

            {/* Fotoğraf Galerisi */}
            {user.profilecv?.kolaj && (
              <div className="bg-white rounded-xl shadow-sm overflow-hidden">
                <div className="p-6 border-b border-gray-100">
                  <h3 className="text-lg font-semibold">Fotoğraf Galerisi</h3>
                </div>
                <div className="p-6">
                  <div className="grid grid-cols-3 gap-4 h-[400px]">
                    {user.profilecv.kolaj.photo1 && (
                      <div className="col-span-2 rounded-xl overflow-hidden">
                        <img 
                          src={user.profilecv.kolaj.photo1} 
                          alt="Ana Fotoğraf"
                          className="w-full h-full object-cover"
                        />
                      </div>
                    )}
                    <div className="space-y-4">
                      {user.profilecv.kolaj.photo2 && (
                        <div className="h-[192px] rounded-xl overflow-hidden">
                          <img 
                            src={user.profilecv.kolaj.photo2} 
                            alt="Fotoğraf 2"
                            className="w-full h-full object-cover"
                          />
                        </div>
                      )}
                      {user.profilecv.kolaj.photo3 && (
                        <div className="h-[192px] rounded-xl overflow-hidden">
                          <img 
                            src={user.profilecv.kolaj.photo3} 
                            alt="Fotoğraf 3"
                            className="w-full h-full object-cover"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Tanıtım Videosu */}
            {user.profilecv?.introVideo && (
              <div className="bg-white rounded-xl shadow-sm overflow-hidden">
                <div className="p-6 border-b border-gray-100">
                  <h3 className="text-lg font-semibold">Tanıtım Videosu</h3>
                </div>
                <div className="aspect-video">
                  <video
                    controls
                    className="w-full h-full object-contain"
                    poster={user.profilecv.introVideoThumbnail}
                  >
                    <source src={user.profilecv.introVideo} type="video/mp4" />
                  </video>
                </div>
              </div>
            )}

            {/* Deneyim ve Eğitim */}
            {(user.profilecv?.experience?.length > 0 || user.profilecv?.education?.length > 0) && (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Deneyim */}
                {user.profilecv?.experience?.length > 0 && (
                  <div className="bg-white rounded-xl shadow-sm overflow-hidden">
                    <div className="p-6 border-b border-gray-100">
                      <h3 className="text-lg font-semibold">Deneyim</h3>
                    </div>
                    <div className="p-6">
                      <div className="space-y-6">
                        {user.profilecv.experience.map((exp, index) => (
                          <div key={index} className="border-l-2 border-gray-200 pl-4">
                            <p className="font-medium">{exp.title}</p>
                            <p className="text-sm text-gray-600">{exp.company}</p>
                            <p className="text-sm text-gray-500">
                              {exp.startDate} - {exp.endDate || 'Devam Ediyor'}
                            </p>
                            {exp.description && (
                              <p className="text-sm text-gray-600 mt-1">{exp.description}</p>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}

                {/* Eğitim */}
                {user.profilecv?.education?.length > 0 && (
                  <div className="bg-white rounded-xl shadow-sm overflow-hidden">
                    <div className="p-6 border-b border-gray-100">
                      <h3 className="text-lg font-semibold">Eğitim</h3>
                    </div>
                    <div className="p-6">
                      <div className="space-y-6">
                        {user.profilecv.education.map((edu, index) => (
                          <div key={index} className="border-l-2 border-gray-200 pl-4">
                            <p className="font-medium">{edu.school}</p>
                            <p className="text-sm text-gray-600">{edu.department}</p>
                            <p className="text-sm text-gray-500">
                              {edu.startYear} - {edu.endYear || 'Devam Ediyor'}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            {/* Yetenekler ve Diller */}
            {(user.profilecv?.skills?.length > 0 || user.profilecv?.languages?.length > 0) && (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Yetenekler */}
                {user.profilecv?.skills?.length > 0 && (
                  <div className="bg-white rounded-xl shadow-sm overflow-hidden">
                    <div className="p-6 border-b border-gray-100">
                      <h3 className="text-lg font-semibold">Yetenekler</h3>
                    </div>
                    <div className="p-6">
                      <div className="flex flex-wrap gap-2">
                        {user.profilecv.skills.map((skill, index) => (
                          <span key={index} className="px-3 py-1 bg-gray-100 rounded-full text-sm">
                            {skill}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                )}

                {/* Diller */}
                {user.profilecv?.languages?.length > 0 && (
                  <div className="bg-white rounded-xl shadow-sm overflow-hidden">
                    <div className="p-6 border-b border-gray-100">
                      <h3 className="text-lg font-semibold">Diller</h3>
                    </div>
                    <div className="p-6">
                      <div className="flex flex-wrap gap-2">
                        {user.profilecv.languages.map((lang, index) => (
                          <span key={index} className="px-3 py-1 bg-gray-100 rounded-full text-sm">
                            {lang}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminUserDetail;
