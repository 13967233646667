import React, { useState } from 'react';
import { getDatabase, ref, update } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaUniversity, FaCreditCard, FaGlobe } from 'react-icons/fa';
import { useAuth } from '../context/AuthContext';
import CVHeader from '../components/CVHeader';

const db = getDatabase();

// Form alanları için sabit tanımlamalar
const FORM_FIELDS = [
  {
    section: "Hesap Bilgileri",
    fields: [
      { name: "accountHolder", label: "Hesap Sahibi", icon: FaCreditCard, required: true },
      { name: "bankName", label: "Banka Adı", icon: FaUniversity, required: true },
      { name: "iban", label: "IBAN", icon: FaGlobe, required: true, format: "iban" }
    ]
  },
  {
    section: "Şube Bilgileri",
    fields: [
      { name: "accountNumber", label: "Hesap Numarası", halfWidth: true },
      { name: "branchCode", label: "Şube Kodu", halfWidth: true },
      { name: "branchName", label: "Şube Adı" },
      { name: "swiftCode", label: "SWIFT Kodu" }
    ]
  }
];

function BankDetail() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [bankData, setBankData] = useState({
    accountHolder: '',
    bankName: '',
    iban: '',
    accountNumber: '',
    branchCode: '',
    branchName: '',
    swiftCode: ''
  });

  // Form işlemleri için yardımcı fonksiyonlar
  const formatIBAN = (value) => {
    const cleaned = value.replace(/\s/g, '');
    const match = cleaned.match(/.{1,4}/g);
    return match ? match.join(' ') : value;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'iban') {
      const formatted = formatIBAN(value.toUpperCase());
      setBankData(prev => ({ ...prev, [name]: formatted }));
    } else {
      setBankData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async () => {
    if (!user) return;
    setLoading(true);
    try {
      await update(ref(db, `users/${user.uid}/bankDetails`), bankData);
      alert('Banka bilgileri başarıyla kaydedildi.');
      navigate(-1);
    } catch (error) {
      console.error('Kayıt hatası:', error);
      alert('Banka bilgileri kaydedilirken bir hata oluştu.');
    } finally {
      setLoading(false);
    }
  };

  // Form alanlarını render eden yardımcı fonksiyon
  const renderField = (field) => {
    const baseInputClass = `w-full py-3 rounded-lg bg-[var(--kirik-beyaz)] border-2 border-transparent 
      focus:border-[var(--grafit-gri)] focus:outline-none transition-colors`;

    return (
      <div key={field.name} className={field.halfWidth ? "col-span-1" : "col-span-2"}>
        <label className="block text-sm font-medium text-[var(--koyu-gumus-gri)] mb-1.5">
          {field.label}
        </label>
        <div className="relative">
          <input
            type="text"
            name={field.name}
            value={bankData[field.name]}
            onChange={handleChange}
            className={`${baseInputClass} ${field.icon ? 'pl-11 pr-4' : 'px-4'}`}
            placeholder={field.label}
            required={field.required}
          />
          {field.icon && (
            <field.icon className="absolute left-4 top-1/2 -translate-y-1/2 text-[var(--grafit-gri)]" />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-[var(--kirik-beyaz)]">
      <CVHeader onBack={() => navigate(-1)} onSave={handleSubmit} title="Banka Bilgileri" />
      
      <div className="max-w-2xl mx-auto p-4">
        <motion.div 
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.1 }}
          className="bg-white rounded-xl p-6 shadow-sm"
        >
          {FORM_FIELDS.map((section, index) => (
            <div key={index} className={`${index > 0 ? 'mt-8' : ''}`}>
              <h3 className="text-[var(--koyu-komur-gri)] font-semibold mb-4">
                {section.section}
              </h3>
              <div className="grid grid-cols-2 gap-4">
                {section.fields.map(renderField)}
              </div>
            </div>
          ))}
        </motion.div>
      </div>
    </div>
  );
}

export default BankDetail;
