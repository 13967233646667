import React from 'react';
import CitySelect from './CitySelect';

const FormField = ({ field, value, onChange, error }) => {
  const labelContent = (
    <label className="block text-sm font-medium text-gray-700 mb-2">
      {field.label}
      {field.required && <span className="text-red-500 ml-1">*</span>}
    </label>
  );

  switch (field.type) {
    case 'multiSelect':
      return (
        <div className="mb-6">
          {labelContent}
          {field.description && (
            <p className="text-sm text-gray-500 mb-3">{field.description}</p>
          )}
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-3">
            {field.options.map(option => (
              <button
                key={option.id}
                type="button"
                onClick={() => {
                  const newValue = value.includes(option.id)
                    ? value.filter(v => v !== option.id)
                    : [...value, option.id];
                  onChange(field.name, newValue);
                }}
                className={`
                  px-4 py-3 rounded-lg text-sm font-medium transition-all duration-200
                  ${value.includes(option.id)
                    ? 'bg-gray-200 text-gray-800 shadow-sm'
                    : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-200'
                  }
                  flex items-center justify-center text-center min-h-[52px]
                  focus:outline-none focus:ring-2 focus:ring-gray-200
                `}
              >
                {option.label}
              </button>
            ))}
          </div>
          {field.required && (!value || value.length === 0) && (
            <p className="text-gray-500 text-sm mt-2">En az bir pozisyon seçmelisiniz</p>
          )}
        </div>
      );

    case 'city':
      return (
        <div className="mb-6">
          {labelContent}
          <CitySelect
            value={value}
            onChange={(value) => onChange(field.name, value)}
            required={field.required}
          />
        </div>
      );

    case 'select':
      return (
        <div className="mb-6">
          {labelContent}
          <select
            name={field.name}
            value={value}
            onChange={(e) => onChange(field.name, e.target.value)}
            className={`
              w-full px-4 py-3 rounded-lg text-sm bg-white transition-colors duration-200
              focus:outline-none focus:ring-2 focus:ring-gray-200
              ${field.required && !value 
                ? 'border-red-300' 
                : 'border border-gray-200 hover:border-gray-300'
              }
            `}
            required={field.required}
          >
            <option value="">Seçiniz</option>
            {field.options.map(option => (
              <option key={option} value={option}>{option}</option>
            ))}
          </select>
        </div>
      );

    default:
      return (
        <div className="mb-6">
          {labelContent}
          <input
            type={field.type}
            name={field.name}
            value={value}
            onChange={(e) => {
              if (field.name === 'tcNo' && !/^\d*$/.test(e.target.value)) return;
              onChange(field.name, e.target.value);
            }}
            placeholder={field.placeholder}
            className={`
              w-full px-4 py-3 rounded-lg text-sm bg-white transition-colors duration-200
              focus:outline-none focus:ring-2 focus:ring-gray-200
              ${field.required && !value 
                ? 'border-red-300' 
                : 'border border-gray-200 hover:border-gray-300'
              }
            `}
            required={field.required}
            pattern={field.pattern}
            maxLength={field.maxLength}
            minLength={field.minLength}
          />
          {field.name === 'tcNo' && value && value.length !== 11 && (
            <p className="text-red-500 text-sm mt-2">TC Kimlik No 11 haneli olmalıdır</p>
          )}
        </div>
      );
  }
};

export default FormField;
