import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebaseconf';
import { createUserWithEmailAndPassword } from "firebase/auth";
import { getDatabase, ref, get, set } from "firebase/database";
import { Container, TextField, Button, Typography, Box, Link, InputAdornment, IconButton, Checkbox, FormControlLabel, Snackbar, Alert, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { ArrowBack, Visibility, VisibilityOff, Close as CloseIcon } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import KlaketLogo from '../assets/logo.svg';
import { motion, AnimatePresence } from 'framer-motion';
import { FaArrowLeft } from 'react-icons/fa';
import { TermsOfService } from './TermsOfService';
import { DataProcessing } from './DataProcessing';

const db = getDatabase();

function PlayerRegister() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [fullName, setFullName] = useState('');
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [isUnder18, setIsUnder18] = useState(false);
  const [guardianFullName, setGuardianFullName] = useState('');
  const [guardianPhone, setGuardianPhone] = useState('');
  const [isAgreed, setIsAgreed] = useState(false);
  const [isDataConsent, setIsDataConsent] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [fullscreenContent, setFullscreenContent] = useState(null);

  const handleRegister = async (e) => {
    e.preventDefault();
    if (!isAgreed || !isDataConsent) {
      setError("Kullanıcı sözleşmesini ve veri işleme onayını kabul etmelisiniz.");
      setOpenSnackbar(true);
      return;
    }

    try {
      const phoneRef = ref(db, 'allPhoneNumbers/' + phone.replace(/\D/g, ''));
      const phoneSnapshot = await get(phoneRef);

      if (phoneSnapshot.exists()) {
        throw new Error("Bu telefon numarası zaten kayıtlı.");
      }

      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const [firstName, lastName] = fullName.split(' ');

      await set(ref(db, `users/${userCredential.user.uid}`), {
        email, 
        phone, 
        firstName: firstName || '', 
        lastName: lastName || '',
        uid: userCredential.user.uid,
        guardian: isUnder18 ? { fullName: guardianFullName, phone: guardianPhone } : null,
        role: 'user' // Varsayılan rol olarak 'user' eklendi
      });

      await set(phoneRef, { phone, uid: userCredential.user.uid });

      navigate('/profile');
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        setError("Bu e-posta adresi zaten kullanımda.");
      } else {
        setError(error.message);
      }
      setOpenSnackbar(true);
    }
  };

  const handlePhoneChange = (e) => {
    let input = e.target.value.replace(/\D/g, ''); // Sadece rakamları al
    console.log('Raw input:', input);

    if (input.startsWith('90')) {
      input = input.slice(2);
    }
    console.log('After removing 90:', input);

    input = input.slice(0, 10); // Maksimum 10 rakam
    console.log('After slicing to 10 digits:', input);

    let formatted = '';
    if (input.length > 0) {
      formatted = '+90 ';
      formatted += input.slice(0, 3);
      if (input.length > 3) {
        formatted += ' ' + input.slice(3, 6);
        if (input.length > 6) {
          formatted += ' ' + input.slice(6, 8);
          if (input.length > 8) {
            formatted += ' ' + input.slice(8);
          }
        }
      }
    }

    console.log('Formatted output:', formatted);
    setPhone(formatted);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpenSnackbar(false);
  };

  const handleLinkClick = (path, e) => {
    e.preventDefault();
    // Yeni sekmede açmak için
    window.open(path, '_blank');
  };

  const handleOpenContent = (content) => {
    setFullscreenContent(content);
  };

  const FullscreenPage = () => (
    <motion.div 
      className="fixed inset-0 bg-white z-50"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
    >
      {/* Header */}
      <div className="flex items-center bg-neutral-50 p-4 pb-2 justify-between sticky top-0 z-10 border-b border-neutral-200">
        <div 
          className="text-[#141414] flex size-12 shrink-0 items-center cursor-pointer" 
          onClick={() => setFullscreenContent(null)}
        >
          <FaArrowLeft size={24} />
        </div>
        <h2 className="text-[#141414] text-lg font-bold leading-tight tracking-[-0.015em] flex-1 text-center">
          {fullscreenContent === 'terms' ? 'Kullanıcı Sözleşmesi' : 'Gizlilik Sözleşmesi'}
        </h2>
        <div className="w-12" /> {/* Denge için boş div */}
      </div>

      {/* Content */}
      <div className="max-w-3xl mx-auto p-4 md:p-6">
        <div className="bg-white rounded-xl space-y-6">
          {fullscreenContent === 'terms' ? (
            <div className="space-y-6">
              <TermsOfService />
            </div>
          ) : (
            <div className="space-y-6">
              <DataProcessing />
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );

  return (
    <Container component={motion.main}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      maxWidth="xs" 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        minHeight: '100vh', 
        justifyContent: 'space-between', 
        py: 4,
        bgcolor: '#F1F0F0', // Klaket 7: Off White
      }}
    >
      <IconButton
        onClick={() => navigate('/login')}
        sx={{ alignSelf: 'flex-start', color: '#292929' }}
        aria-label="giriş sayfası"
      >
        <ArrowBack />
      </IconButton>

      <Box component="form" onSubmit={handleRegister} sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
        <motion.div
          initial={{ scale: 0.5 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
            <img src={KlaketLogo} alt="Klaket Logo" style={{ width: '70%' }} />
          </Box>
        </motion.div>

        <TextField
          variant="outlined"
          required
          fullWidth
          label="Ad ve Soyad"
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
          InputProps={{ sx: { bgcolor: '#F1F0F0' } }}
        />

        <TextField
          variant="outlined"
          required
          fullWidth
          label="Cep Telefonu"
          value={phone}
          onChange={handlePhoneChange}
          InputProps={{ sx: { bgcolor: '#F1F0F0' } }}
        />

        <TextField
          variant="outlined"
          required
          fullWidth
          label="E-Posta"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputProps={{ sx: { bgcolor: '#F1F0F0' } }}
        />

        <TextField
          variant="outlined"
          required
          fullWidth
          label="Şifre"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            sx: { bgcolor: '#F1F0F0' },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <FormControlLabel
          control={<Checkbox checked={isUnder18} onChange={(e) => setIsUnder18(e.target.checked)} />}
          label={<Typography variant="body2" sx={{ fontSize: '0.8rem', color: '#4A494A' }}>18 yaşından küçüğüm</Typography>}
        />

        {isUnder18 && (
          <>
            <TextField
              variant="outlined"
              required
              fullWidth
              label="Veli Adı ve Soyadı"
              value={guardianFullName}
              onChange={(e) => setGuardianFullName(e.target.value)}
              InputProps={{ sx: { bgcolor: '#F1F0F0' } }}
            />
            <TextField
              variant="outlined"
              required
              fullWidth
              label="Veli Telefonu"
              value={guardianPhone}
              onChange={(e) => setGuardianPhone(e.target.value)}
              InputProps={{ sx: { bgcolor: '#F1F0F0' } }}
            />
          </>
        )}

        <div className="flex items-start gap-2">
          <Checkbox 
            checked={isAgreed} 
            onChange={(e) => setIsAgreed(e.target.checked)}
            size="small"
          />
          <Typography 
            variant="body2" 
            sx={{ 
              fontSize: '0.8rem', 
              color: '#4A494A',
              marginTop: '9px'
            }}
          >
            <span 
              onClick={() => handleOpenContent('terms-of-service')}
              className="text-[var(--grafit-gri)] hover:text-[var(--koyu-komur-gri)] underline cursor-pointer"
            >
              Kullanıcı sözleşmesini
            </span>
            {' '}ve{' '}
            <span 
              onClick={() => handleOpenContent('privacy')}
              className="text-[var(--grafit-gri)] hover:text-[var(--koyu-komur-gri)] underline cursor-pointer"
            >
              gizlilik sözleşmesini
            </span>
            {' '}okudum, kabul ediyorum.
          </Typography>
        </div>

        <div className="flex items-start gap-2">
          <Checkbox 
            checked={isDataConsent} 
            onChange={(e) => setIsDataConsent(e.target.checked)}
            size="small"
          />
          <Typography 
            variant="body2" 
            sx={{ 
              fontSize: '0.8rem', 
              color: '#4A494A',
              marginTop: '9px'
            }}
          >
            <span 
              onClick={(e) => handleOpenContent('terms', e)}
              className="text-[var(--grafit-gri)] hover:text-[var(--koyu-komur-gri)] underline cursor-pointer"
            >
              Verilerimin işlenmesine
            </span>
            {' '}onay veriyorum.
          </Typography>
        </div>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ 
            mt: 3, 
            mb: 2,
            bgcolor: '#292929', // Klaket 2: Dark Anthracite
            '&:hover': { bgcolor: '#4A494A' }, // Klaket 4: Graphite Gray
            color: '#F1F0F0', // Klaket 7: Off White
            fontWeight: 'bold',
            py: 1.5,
          }} 
        >
          Kayıt Ol
        </Button>

        <Box textAlign="center">
          <Typography variant="body2" sx={{ color: '#4A494A' }}>
            Zaten bir hesabınız var mı?{' '}
            <Link component={RouterLink} to="/login" sx={{ color: '#292929', textDecoration: 'none', fontWeight: 'bold', '&:hover': { textDecoration: 'underline' } }}>
              Giriş Yap
            </Link>
          </Typography>
        </Box>
      </Box>

      <Typography variant="body2" color="#4A494A" sx={{ mt: 4, borderTop: '1px solid', borderColor: '#4A494A', pt: 2, width: '100%', textAlign: 'center' }}>
        © 2024 Klaket
      </Typography>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%', bgcolor: '#292929', color: '#F1F0F0' }}>
          {error}
        </Alert>
      </Snackbar>

      {/* Fullscreen Content */}
      <AnimatePresence>
        {fullscreenContent && <FullscreenPage />}
      </AnimatePresence>
    </Container>
  );
}

export default PlayerRegister;
