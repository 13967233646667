import React, { useEffect, useState } from 'react';
import { getDatabase, ref, get, update, remove } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import toast from 'react-hot-toast';
import { 
  HiOutlineUser,
  HiOutlineTrash,
  HiOutlineDocumentDownload,
  HiOutlineSearch,
  HiOutlineFilter,
  HiOutlineMail,
  HiOutlinePhone,
  HiOutlineLocationMarker,
  HiOutlineCheck,
  HiOutlineBan,
  HiOutlineEye,
  HiOutlineArrowLeft
} from 'react-icons/hi';
import * as XLSX from 'xlsx';

const db = getDatabase();

const calculateAge = (birthDate) => {
  if (!birthDate) return null;
  const today = new Date();
  const birthDateObj = new Date(birthDate);
  let age = today.getFullYear() - birthDateObj.getFullYear();
  const monthDiff = today.getMonth() - birthDateObj.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
    age--;
  }
  return age;
};

const AdminSelectedUsers = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterCity, setFilterCity] = useState('all');
  const [filterStatus, setFilterStatus] = useState('all');

  useEffect(() => {
    if (!user) return;

    const fetchSelectedUsers = async () => {
      try {
        const usersRef = ref(db, 'users');
        const adminRef = ref(db, `users/${user.uid}/selectedPlayers`);
        
        const [usersSnap, adminSnap] = await Promise.all([
          get(usersRef),
          get(adminRef)
        ]);
        
        if (usersSnap.exists()) {
          let usersData = [];
          
          if (adminSnap.exists()) {
            const selectedPlayerIds = Object.keys(adminSnap.val());
            usersData = Object.entries(usersSnap.val())
              .map(([id, userData]) => ({
                id,
                ...userData,
                age: userData.birthDate ? calculateAge(userData.birthDate) : null
              }))
              .filter(user => selectedPlayerIds.includes(user.id));
          }
          
          setSelectedUsers(usersData);
        }
      } catch (error) {
        console.error("Veri çekerken hata:", error);
        toast.error('Veri yüklenirken bir hata oluştu');
      } finally {
        setLoading(false);
      }
    };

    fetchSelectedUsers();
  }, [user]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-black"></div>
          <p className="mt-4 text-gray-600">Yükleniyor...</p>
        </div>
      </div>
    );
  }

  const handleExportToExcel = () => {
    const excelData = filteredUsers.map((user, index) => ({
      'ID': user.id,
      'Ad Soyad': `${user.firstName} ${user.lastName}`,
      'E-posta': user.email || 'Belirtilmemiş',
      'Telefon': user.phone || 'Belirtilmemiş',
      'TC Kimlik': user.tcNo || 'Belirtilmemiş',
      'Pozisyon': user.position || 'Belirtilmemiş',
      'Yaş': user.age || 'Belirtilmemiş',
      'Şehir': user.city || 'Belirtilmemiş',
      'Durum': user.status || 'Belirtilmemiş',
      'Kayıt Tarihi': new Date(user.createdAt).toLocaleDateString('tr-TR')
    }));

    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Seçili Kullanıcılar");
    XLSX.writeFile(wb, `secili_kullanicilar_${new Date().toLocaleDateString('tr-TR')}.xlsx`);
  };

  const handleStatusUpdate = async (userId, newStatus) => {
    try {
      await update(ref(db, `users/${userId}`), {
        status: newStatus,
        updatedAt: new Date().toISOString()
      });
      
      setSelectedUsers(prev => prev.map(user => 
        user.id === userId ? { ...user, status: newStatus } : user
      ));
    } catch (error) {
      console.error("Güncelleme hatası:", error);
    }
  };

  const handleRemoveUser = async (userId, userName) => {
    if (!user) return;

    try {
      await remove(ref(db, `users/${user.uid}/selectedPlayers/${userId}`));
      setSelectedUsers(prev => prev.filter(u => u.id !== userId));
      
      toast.success(`${userName} listeden kaldırıldı`, {
        style: {
          background: '#000',
          color: '#fff',
          borderRadius: '8px',
        },
        duration: 2000,
        position: 'bottom-right',
      });
    } catch (error) {
      console.error("Silme hatası:", error);
      toast.error('Bir hata oluştu');
    }
  };

  const filteredUsers = selectedUsers.filter(user => {
    const matchesSearch = `${user.firstName} ${user.lastName} ${user.email} ${user.phone}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    
    const matchesCity = filterCity === 'all' || user.city === filterCity;
    const matchesStatus = filterStatus === 'all' || user.status === filterStatus;
    
    return matchesSearch && matchesCity && matchesStatus;
  });

  const cities = [...new Set(selectedUsers.map(user => user.city))].filter(Boolean);

  return (
    <div className="min-h-screen bg-white">
      {/* Üst Bar */}
      <div className="sticky top-0 z-10 bg-white border-b border-gray-200">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="py-4 flex items-center justify-between">
            <div className="flex items-center gap-4">
              <button
                onClick={() => navigate('/admin/users')}
                className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
              >
                <HiOutlineArrowLeft size={20} />
              </button>
              <h1 className="text-xl font-semibold">
                Seçili Kullanıcılar ({filteredUsers.length})
              </h1>
            </div>
            
            <button
              onClick={handleExportToExcel}
              className="flex items-center px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-900 transition-colors"
            >
              <HiOutlineDocumentDownload className="mr-2" size={20} />
              Excel'e Aktar
            </button>
          </div>
        </div>
      </div>

      {/* Filtreler */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <div className="bg-white border border-gray-200 p-4 rounded-lg mb-6">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="relative">
              <HiOutlineSearch className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
              <input
                type="text"
                placeholder="Kullanıcı ara..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-1 focus:ring-black"
              />
            </div>

            <select
              value={filterCity}
              onChange={(e) => setFilterCity(e.target.value)}
              className="px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-1 focus:ring-black"
            >
              <option value="all">Tüm Şehirler</option>
              {cities.map(city => (
                <option key={city} value={city}>{city}</option>
              ))}
            </select>

            <select
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
              className="px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-1 focus:ring-black"
            >
              <option value="all">Tüm Durumlar</option>
              <option value="active">Aktif</option>
              <option value="pending">Beklemede</option>
              <option value="inactive">Pasif</option>
            </select>
          </div>
        </div>

        {/* Kullanıcı Listesi */}
        <div className="space-y-4">
          {filteredUsers.map((user) => (
            <div
              key={user.id}
              className="bg-white border border-gray-200 rounded-lg overflow-hidden hover:border-black transition-colors"
            >
              <div className="p-6">
                <div className="flex items-center gap-6">
                  <div className="w-20 h-20 rounded-lg bg-gray-100 overflow-hidden flex-shrink-0">
                    {user.profileImage ? (
                      <img
                        src={user.profileImage}
                        alt={`${user.firstName} ${user.lastName}`}
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <div className="w-full h-full flex items-center justify-center">
                        <HiOutlineUser className="text-gray-400" size={32} />
                      </div>
                    )}
                  </div>

                  <div className="flex-1 min-w-0">
                    <div className="flex items-center justify-between mb-2">
                      <h3 className="text-lg font-semibold truncate">
                        {user.firstName} {user.lastName}
                      </h3>
                      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                        ${user.status === 'active' ? 'bg-black text-white' : 
                          'bg-gray-100 text-gray-800'}`}>
                        {user.status === 'active' ? 'Aktif' : 'Pasif'}
                      </span>
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                      <div className="space-y-2">
                        <div className="flex items-center text-sm">
                          <HiOutlineMail className="mr-2" size={16} />
                          {user.email}
                        </div>
                        <div className="flex items-center text-sm">
                          <HiOutlinePhone className="mr-2" size={16} />
                          {user.phone || 'Belirtilmemiş'}
                        </div>
                      </div>
                      <div className="space-y-2">
                        <div className="flex items-center text-sm">
                          <HiOutlineLocationMarker className="mr-2" size={16} />
                          {user.city || 'Belirtilmemiş'}
                        </div>
                        <div className="text-sm">
                          {user.age ? `${user.age} yaş` : 'Yaş belirtilmemiş'}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center gap-2">
                    <button
                      onClick={() => navigate(`/admin/users/${user.id}`)}
                      className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
                      title="Görüntüle"
                    >
                      <HiOutlineEye size={20} />
                    </button>
                    <button
                      onClick={() => handleRemoveUser(user.id, `${user.firstName} ${user.lastName}`)}
                      className="p-2 hover:bg-gray-100 rounded-lg transition-colors text-black"
                      title="Listeden Kaldır"
                    >
                      <HiOutlineTrash size={20} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}

          {filteredUsers.length === 0 && (
            <div className="text-center py-12">
              <HiOutlineUser className="mx-auto text-gray-400" size={48} />
              <p className="mt-4 text-gray-600">
                {searchTerm ? 'Arama kriterlerine uygun kullanıcı bulunamadı.' : 'Henüz seçili kullanıcı bulunmuyor.'}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminSelectedUsers;
