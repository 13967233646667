import React, { useEffect, useState } from "react";
import { storage, db } from "../firebaseconf"; // Firebase yapılandırması
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"; // Storage fonksiyonları
import { update, ref as dbRef, get } from "firebase/database"; // Realtime Database fonksiyonları
import { useAuth } from "../context/AuthContext"; // AuthContext'i içe aktar
import { useNavigate } from "react-router-dom";

const ProfilePictureAndName = () => {
  const { user } = useAuth(); // Kullanıcı bilgilerini al
  const userId = user?.uid; // Kullanıcı ID'sini al (optional chaining ile)
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [profileImagePreview, setProfileImagePreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);
  const [currentProfileImage, setCurrentProfileImage] = useState(null); // Mevcut profil resmi için yeni state

  useEffect(() => {
    const fetchProfileData = async () => {
      if (!user) return;

      try {
        // Firebase'deki kullanıcıya ait profil verisini çek
        const profileRef = dbRef(db, "users/" + user.uid); // UID ile profil verisini al
        const snapshot = await get(profileRef);

        if (snapshot.exists()) {
          const data = snapshot.val();
          console.log("Profil Verisi Alındı:", data); // Konsola yazdır
          console.log("Profil Resmi:", data.profileImage);

          // Gelen verileri state'lere ayarla
          setFirstName(data.firstName || ""); // Varsayılan değer olarak boş string
          setLastName(data.lastName || ""); // Varsayılan değer olarak boş string
          // Mevcut profil resmini sakla
          if (data.profileImage) {
            setCurrentProfileImage(data.profileImage);
            setProfileImage(data.profileImage);
          }
        }
      } catch (error) {
        console.error("Profil verisi alınırken hata:", error);
      }
    };

    fetchProfileData(); // Asenkron fonksiyonu çağır
  }, [user]); // user değiştiğinde tetikle

  useEffect(() => {
    return () => {
      // Önizleme için oluşturulan URL'yi bileşen unmount olduğunda temizle
      if (profileImagePreview) {
        URL.revokeObjectURL(profileImagePreview);
      }
    };
  }, [profileImagePreview]);

  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImage(file);
      setProfileImagePreview(URL.createObjectURL(file)); // Önizleme için URL oluştur
    }
  };

  const handleFileUpload = (file, folder) => {
    const storageRef = ref(storage, `${folder}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setUploadProgress(progress);
          console.log("Yükleme %" + progress + " tamamlandı");
        },
        (error) => {
          console.error("Yükleme başarısız:", error);
          reject(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL);
          });
        }
      );
    });
  };

  const handleSubmit = async () => {
    if (!user) {
      alert("Kullanıcı giriş yapmamış.");
      return;
    }

    setIsLoading(true);
    setUploadProgress(0);

    try {
      let profileImageUrl = currentProfileImage; // Varsayılan olarak mevcut resmi kullan

      // Sadece yeni bir resim seçildiyse yükle
      if (profileImage && typeof profileImage === "object") {
        profileImageUrl = await handleFileUpload(profileImage, "profileImages");
      }

      await update(dbRef(db, `users/${userId}`), {
        profileImage: profileImageUrl,
        firstName: firstName,
        lastName: lastName,
      });

      setCurrentProfileImage(profileImageUrl); // Mevcut resmi güncelle
      setProfileImagePreview(null); // Preview'i temizle

      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        navigate('/profile');
      }, 2000);

    } catch (error) {
      console.error("Hata:", error);
      alert("Bir hata oluştu. Lütfen tekrar deneyin.");
    } finally {
      setIsLoading(false);
      setUploadProgress(0);
    }
  };

  return (
    <div className="relative flex size-full min-h-screen flex-col bg-neutral-50 group/design-root overflow-x-hidden" style={{ fontFamily: '"Space Grotesk", "Noto Sans", sans-serif' }}>
      {/* Header */}
      <div className="flex items-center bg-neutral-50 p-4 pb-2 justify-between">
        <div className="text-[#141414] flex size-12 shrink-0 items-center" onClick={() => navigate(-1)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
            <path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path>
          </svg>
        </div>
        <h2 className="text-[#141414] text-lg font-bold leading-tight tracking-[-0.015em] flex-1 text-center">Profili Düzenle</h2>
        <div className="flex w-12 items-center justify-end">
          <button onClick={handleSubmit} disabled={isLoading} className="text-neutral-500 text-base font-bold leading-normal tracking-[0.015em] shrink-0">
            Kaydet
          </button>
        </div>
      </div>

      {/* Yükleme Göstergesi */}
      {isLoading && uploadProgress > 0 && uploadProgress < 100 && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-2xl p-6 max-w-sm w-full mx-4">
            <div className="flex flex-col items-center">
              <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
                <div 
                  className="bg-[#141414] h-2.5 rounded-full transition-all duration-300" 
                  style={{ width: `${uploadProgress}%` }}
                ></div>
              </div>
              <p className="text-[#141414] text-sm">
                Yükleniyor... %{uploadProgress}
              </p>
            </div>
          </div>
        </div>
      )}

      {/* Başarı Mesajı */}
      {showSuccess && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-2xl p-6 max-w-sm w-full mx-4">
            <div className="flex flex-col items-center">
              <svg 
                className="w-16 h-16 text-green-500 mb-4" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth="2" 
                  d="M5 13l4 4L19 7"
                />
              </svg>
              <p className="text-[#141414] text-lg font-medium">
                Profil başarıyla güncellendi
              </p>
            </div>
          </div>
        </div>
      )}

      {/* Profile Image */}
      <div className="flex w-full justify-center bg-neutral-50 p-4">
        <div className="w-32 h-32 overflow-hidden bg-neutral-50 rounded-full">
          {(profileImagePreview || currentProfileImage) ? (
            <div
              className="w-full h-full bg-center bg-no-repeat bg-cover rounded-full"
              style={{ 
                backgroundImage: `url(${profileImagePreview || currentProfileImage})`
              }}
            ></div>
          ) : (
            <div className="w-full h-full flex items-center justify-center bg-[#ededed] rounded-full">
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width="40" 
                height="40" 
                fill="#141414" 
                viewBox="0 0 256 256"
              >
                <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24ZM74.08,197.5a64,64,0,0,1,107.84,0,87.83,87.83,0,0,1-107.84,0ZM96,120a32,32,0,1,1,32,32A32,32,0,0,1,96,120Zm97.76,66.41a79.66,79.66,0,0,0-36.06-28.75,48,48,0,1,0-59.4,0,79.66,79.66,0,0,0-36.06,28.75,88,88,0,1,1,131.52,0Z"></path>
              </svg>
            </div>
          )}
        </div>
      </div>

      {/* Edit Profile Photo Button */}
      <div className="flex px-4 py-3 justify-center">
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="profile-image-upload"
          type="file"
          onChange={handleProfileImageChange}
        />
        <label htmlFor="profile-image-upload" className="w-full max-w-[480px]">
          <button
            className="w-full flex min-w-[84px] cursor-pointer items-center justify-center overflow-hidden rounded-full h-10 px-4 bg-[#ededed] text-[#141414] text-sm font-bold leading-normal tracking-[0.015em]"
            onClick={() => document.getElementById('profile-image-upload').click()}
          >
            <span className="truncate">Profil Fotoğrafını Düzenle</span>
          </button>
        </label>
      </div>

      {/* Name Input */}
      <div className="flex max-w-[480px] flex-wrap items-end gap-4 px-4 py-3">
        <label className="flex flex-col min-w-40 flex-1">
          <p className="text-[#141414] text-base font-medium leading-normal pb-2">Ad</p>
          <input
            className="form-input flex w-full min-w-0 flex-1 resize-none overflow-hidden rounded-xl text-[#141414] focus:outline-0 focus:ring-0 border border-[#dbdbdb] bg-neutral-50 focus:border-[#dbdbdb] h-14 placeholder:text-neutral-500 p-[15px] text-base font-normal leading-normal"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </label>
      </div>

      {/* Surname Input */}
      <div className="flex max-w-[480px] flex-wrap items-end gap-4 px-4 py-3">
        <label className="flex flex-col min-w-40 flex-1">
          <p className="text-[#141414] text-base font-medium leading-normal pb-2">Soyad</p>
          <input
            className="form-input flex w-full min-w-0 flex-1 resize-none overflow-hidden rounded-xl text-[#141414] focus:outline-0 focus:ring-0 border border-[#dbdbdb] bg-neutral-50 focus:border-[#dbdbdb] h-14 placeholder:text-neutral-500 p-[15px] text-base font-normal leading-normal"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </label>
      </div>

      <div className="h-5 bg-neutral-50"></div>
    </div>
  );
};

export default ProfilePictureAndName;
