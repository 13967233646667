import React, { useEffect, useState } from 'react';
import { getDatabase, ref, get } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { FaBell, FaCog, FaLock, FaImage, FaFileAlt, FaVideo, FaUser, FaPlus, FaGift, FaProjectDiagram, FaMoneyBill } from 'react-icons/fa';
import { motion } from 'framer-motion';
import KlaketLogo from '../assets/logo.svg'; // Klaket logosu için

const db = getDatabase();

// Rol çevirisi için yardımcı fonksiyon
const translateRole = (role) => {
  switch (role) {
    case 'admin':
      return 'Yönetici';
    case 'user':
      return 'Kullanıcı';
    case 'production':
      return 'Yapım';
    default:
      return role; // Eğer bilinmeyen bir rol gelirse, olduğu gibi göster
  }
};

function Profile() {
  const { user } = useAuth();
  const [profileData, setProfileData] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfileData = async () => {
      if (user) {
        const userRef = ref(db, `users/${user.uid}`);
        try {
          const snapshot = await get(userRef);
          if (snapshot.exists()) {
            setProfileData(snapshot.val());
          }
        } catch (error) {
          console.error("Profil verisi çekilirken hata oluştu:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchProfileData();
  }, [user]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen text-lg font-semibold">
        Yükleniyor...
      </div>
    );
  }

  const getProfileImage = () => {
    if (profileData.profileImage) {
      return { type: 'image', src: profileData.profileImage };
    } else if (profileData.role === 'production') {
      return { type: 'logo', src: KlaketLogo };
    } else {
      return { type: 'icon', Icon: FaUser };
    }
  };

  const profileImageData = getProfileImage();

  const getMenuItems = () => {
    const baseMenuItems = [
      { label: 'Ayarlar', icon: <FaCog />, path: '/settings' },
    ];

    const userMenuItems = [
      { label: 'Kolaj Yap', icon: <FaImage />, path: '/kolaj', description: 'Zorunlu alan*' },
      { label: 'CV Düzenle', description: 'Zorunlu alan*', icon: <FaFileAlt />, path: '/cv' },
      { label: 'Tanıtım Videosu', icon: <FaVideo />, path: '/intro-video-upload', description: 'Zorunlu alan*' },
      { label: 'Showreel Videosu', icon: <FaVideo />, path: '/showreel-video-upload', description: 'Zorunlu değil' },
      { label: 'Mimik Videosu', icon: <FaVideo />, path: '/mimic-video-upload', description: 'Zorunlu değil' },
      { label: 'Hesap Onayı', icon: <FaLock />, path: '/profile-approval' },
      {label: 'Banka Detayı', icon: <FaMoneyBill />, path: '/bank-detail'}
    ];

    const adminMenuItems = [
      { label: 'Proje Ekle', icon: <FaPlus />, path: '/add-project' },
      { label: 'Proje Yönetimi', icon: <FaProjectDiagram />, path: '/project-management' },
      { label: 'Seçilmiş Kullanıcılar', icon: <FaUser />, path: '/selected-users' },  
      { label: 'Duyuru Yönetimi', icon: <FaBell />, path: '/announcement-page' },
      { label: 'Hesap Onayı', icon: <FaLock />, path: '/admin-approval' },
    ];

    const productionMenuItems = [
      { label: 'Duyuru Yönetimi', icon: <FaBell />, path: '/announcement-page' },
      { label: 'Seçilmiş Kullanıcılar', icon: <FaUser />, path: '/selected-users' },
      { label: 'Hesap Onayı', icon: <FaLock />, path: '/admin-approval' },
    ];

    if (profileData.role === 'admin') {
      return [
        ...baseMenuItems,
        ...adminMenuItems,
      ];
    } else if (profileData.role === 'production') {
      return [
        ...baseMenuItems,
        ...productionMenuItems,
      ];
    } else {
      return [...userMenuItems, ...baseMenuItems];
    }
  };

  const menuItems = getMenuItems();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      className="relative flex min-h-screen flex-col bg-[#FFFFFF] justify-between overflow-x-hidden pb-20"
      style={{ fontFamily: '"Work Sans", "Noto Sans", sans-serif' }}
    >
      <div>
        <motion.div
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2 }}
          className="flex items-center bg-[#FFFFFF] p-4 pb-2 justify-between"
        >
          <div 
            className="text-black flex size-12 shrink-0 items-center justify-center cursor-pointer"
            onClick={() => navigate('/Campaigns')}
          >
            <FaGift size={24} />
          </div>
          <h2 className="text-black text-lg font-bold leading-tight tracking-[-0.015em] flex-1 text-center">
            Profil
          </h2>
          <div className="size-12 shrink-0"></div> {/* Sağ tarafta boş alan bırakmak için */}
        </motion.div>

        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.2, delay: 0.1 }}
          className="flex p-4 @container"
        >
          <div className="flex w-full flex-col gap-4 items-start">
            <div className="flex gap-4 flex-col items-start">
              <motion.div
                onClick={() => navigate('/profile-picture-and-name')}
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ type: "spring", stiffness: 260, damping: 20, delay: 0.6 }}
                className="bg-center bg-no-repeat aspect-square bg-cover rounded-full min-h-32 w-32 flex items-center justify-center"
                style={{
                  backgroundColor: !profileData.profileImage ? '#f0f0f0' : 'transparent',
                }}
              >
                {profileImageData.type === 'image' && (
                  <img src={profileImageData.src} alt="Profile" className="w-full h-full object-cover rounded-full" />
                )}
                {profileImageData.type === 'logo' && (
                  <img src={profileImageData.src} alt="Klaket Logo" className="w-3/4 h-3/4 object-contain" />
                )}
                {profileImageData.type === 'icon' && (
                  <profileImageData.Icon size={48} color="#666" />
                )}
              </motion.div>
              <motion.div
                initial={{ x: -50, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.8 }}
                className="flex flex-col justify-center"
              >
                <p className="text-black text-[22px] font-bold leading-tight tracking-[-0.015em]">
                  {profileData.firstName} {profileData.lastName}
                </p>
                <p className="text-[#6B6B6B] text-base font-normal leading-normal">
                  {profileData.title || translateRole(profileData.role)}
                </p>
              </motion.div>
            </div>
            <motion.button 
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 1 }}
              className="flex min-w-[84px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 px-4 bg-[#EEEEEE] text-black text-sm font-bold leading-normal tracking-[0.015em] w-full max-w-[480px] @[480px]:w-auto"
              onClick={() => navigate('/profile-picture-and-name')}
            >
              <span className="truncate">Profili Düzenle</span>
            </motion.button>
          </div>
        </motion.div>

        {menuItems.map((item, index) => (
          <MenuItem
            key={item.label}
            icon={item.icon}
            label={item.label}
            description={item.description}
            onClick={() => navigate(item.path)}
            delay={1.4 + index * 0.1}
          />
        ))}
      </div>
    </motion.div>
  );
}

function MenuItem({ icon, label, description, onClick, delay }) {
  return (
    <motion.div
      initial={{ x: -20, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.2, delay: delay * 0.5 }}
      className="flex items-center gap-4 bg-[#FFFFFF] px-4 min-h-14 justify-between cursor-pointer"
      onClick={onClick}
    >
      <div className="flex items-center gap-4">
        <div className="text-black flex items-center justify-center rounded-lg bg-[#EEEEEE] shrink-0 size-10">
          {icon}
        </div>
        <div className="flex flex-col justify-center">
          <p className="text-black text-base font-medium leading-normal line-clamp-1">{label}</p>
          {description && (
            <p className="text-[#6B6B6B] text-sm font-normal leading-normal line-clamp-2">
              {description}
            </p>
          )}
        </div>
      </div>
      <div className="shrink-0">
        <div className="text-black flex size-7 items-center justify-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
            <path d="M181.66,133.66l-80,80a8,8,0,0,1-11.32-11.32L164.69,128,90.34,53.66a8,8,0,0,1,11.32-11.32l80,80A8,8,0,0,1,181.66,133.66Z"></path>
          </svg>
        </div>
      </div>
    </motion.div>
  );
}

export default Profile;
