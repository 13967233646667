import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

const Terms = () => {
  const navigate = useNavigate();

  return (
    <motion.div 
      className="min-h-screen bg-white flex flex-col"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <motion.div 
        className="flex items-center bg-white p-4 pb-2 justify-between"
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <motion.div
          className="text-[#141414] flex size-12 shrink-0 items-center cursor-pointer"
          onClick={() => navigate(-1)}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24px"
            height="24px"
            fill="currentColor"
            viewBox="0 0 256 256"
          >
            <path d="M224,128a8,8,0,0,1-8,8H59.31l58.35,58.34a8,8,0,0,1-11.32,11.32l-72-72a8,8,0,0,1,0-11.32l72-72a8,8,0,0,1,11.32,11.32L59.31,120H216A8,8,0,0,1,224,128Z"></path>
          </svg>
        </motion.div>
        <h2 className="text-[#141414] text-lg font-bold leading-tight tracking-[-0.015em] flex-1 text-center pr-12">
          Kullanım Koşulları
        </h2>
      </motion.div>

      <motion.div 
        className="flex-1 p-4 md:p-6 overflow-y-auto"
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <div className="space-y-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.1 }}
          >
            <h2 className="text-xl font-semibold">1. Genel Bilgiler</h2>
            <p>
              Bu kullanım koşulları, [Uygulama Adı] uygulamasını kullanırken
              geçerli olan şartları belirler. Uygulamayı kullanarak bu koşulları
              kabul etmiş olursunuz.
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.2 }}
          >
            <h2 className="text-xl font-semibold">2. Kullanım Hakkı</h2>
            <p>
              [Uygulama Adı], kullanıcılarına uygulamayı kişisel ve ticari olmayan
              amaçlarla kullanma hakkı verir. Uygulamanın herhangi bir kısmını
              izinsiz olarak kopyalamak, dağıtmak veya değiştirmek yasaktır.
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.3 }}
          >
            <h2 className="text-xl font-semibold">3. Kullanıcı Sorumlulukları</h2>
            <p>
              Kullanıcılar, uygulamayı kullanırken geçerli yasalara uymakla
              yükümlüdür. Uygulama üzerinden yapılan tüm işlemlerden kullanıcı
              sorumludur.
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.4 }}
          >
            <h2 className="text-xl font-semibold">4. Gizlilik Politikası</h2>
            <p>
              Kullanıcıların kişisel bilgileri, gizlilik politikamız doğrultusunda
              korunmaktadır. Kişisel bilgilerinizi nasıl kullandığımızı öğrenmek
              için lütfen gizlilik politikamızı inceleyin.
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.5 }}
          >
            <h2 className="text-xl font-semibold">5. Değişiklikler</h2>
            <p>
              [Uygulama Adı], bu kullanım koşullarını dilediği zaman değiştirme
              hakkına sahiptir. Değişiklikler, uygulama içinde yayınlandığı
              tarihten itibaren geçerli olacaktır.
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.6 }}
          >
            <h2 className="text-xl font-semibold">6. İletişim</h2>
            <p>
              Kullanım koşulları hakkında herhangi bir sorunuz varsa, lütfen
              bizimle iletişime geçin: [İletişim Bilgileri].
            </p>
          </motion.div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default Terms;
