import { useState, useEffect } from 'react';
import { Typography, IconButton, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { db, storage } from '../firebaseconf';
import { set, ref as dbRef, onValue } from 'firebase/database';
import { ref as storageRef, uploadBytes, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { useAuth } from '../context/AuthContext';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

function Kolaj() {
  const { user } = useAuth();
  const [photos, setPhotos] = useState([null, null, null]);
  const [photoURLs, setPhotoURLs] = useState([null, null, null]);
  const [showTooltip, setShowTooltip] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({
    current: 0,
    total: 0,
    currentFile: 0
  });
  const [showSuccess, setShowSuccess] = useState(false);

  // Verileri çekmek için useEffect kullan
  useEffect(() => {
    const kolajRef = dbRef(db, `users/${user.uid}/profilecv/kolaj`);
    onValue(kolajRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setPhotoURLs([data.photo1, data.photo2, data.photo3]); // Verileri state'e ata
        setPhotos([data.photo1, data.photo2, data.photo3]); // Önizleme için URL'leri ayarla
      }
    });
  }, [user.uid]);

  const handlePhotoChange = (index) => (e) => {
    const file = e.target.files[0];
    if (file) {
      const newPhotos = [...photos];
      newPhotos[index] = file; // Fotoğraf dosyasını sakla
      setPhotos(newPhotos);

      // Yeni fotoğraf için önizleme URL'sini oluştur
      const newPhotoURLs = [...photoURLs];
      newPhotoURLs[index] = URL.createObjectURL(file);
      setPhotoURLs(newPhotoURLs);
    }
  };

  const uploadPhoto = async (file) => {
    const storageReference = storageRef(storage, `photos/${user.uid}/${Date.now()}_${file.name}`);
    const uploadTask = uploadBytesResumable(storageReference, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setUploadProgress(prev => ({
            ...prev,
            current: progress
          }));
        },
        (error) => {
          console.error('Yükleme başarısız:', error);
          reject(error);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            setUploadProgress(prev => ({
              ...prev,
              currentFile: prev.currentFile + 1
            }));
            resolve(downloadURL);
          } catch (error) {
            reject(error);
          }
        }
      );
    });
  };

  const handleSavePhotos = async () => {
    if (photos.filter(photo => photo).length < 3) {
      alert('Lütfen 3 adet fotoğraf yükleyin.');
      return;
    }

    setIsLoading(true);
    // Sadece File tipinde olan (yeni yüklenen) fotoğrafları say
    const newPhotosCount = photos.filter(photo => photo instanceof File).length;
    setUploadProgress({ current: 0, total: newPhotosCount, currentFile: 0 });

    try {
      const updatedPhotoURLs = await Promise.all(
        photos.map(async (photo, index) => {
          if (photo instanceof File) {
            // Yeni yüklenen fotoğraf
            return await uploadPhoto(photo);
          } else if (typeof photo === 'string') {
            // Mevcut URL'li fotoğraf
            return photo;
          } else {
            // Eğer fotoğraf yoksa veya geçersizse
            return photoURLs[index] || null;
          }
        })
      );

      // Null kontrolü ekle
      if (updatedPhotoURLs.some(url => !url)) {
        throw new Error('Bazı fotoğraflar yüklenemedi');
      }

      await set(dbRef(db, `users/${user.uid}/profilecv/kolaj`), {
        photo1: updatedPhotoURLs[0],
        photo2: updatedPhotoURLs[1],
        photo3: updatedPhotoURLs[2],
      });

      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        navigate('/profile');
      }, 2000);

    } catch (error) {
      console.error('Hata:', error);
      alert('Fotoğraflar kaydedilirken bir hata oluştu.');
    } finally {
      setIsLoading(false);
      setUploadProgress({ current: 0, total: 0, currentFile: 0 });
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { 
        duration: 0.5,
        when: "beforeChildren",
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { 
      y: 0, 
      opacity: 1,
      transition: { duration: 0.5 }
    }
  };

  return (
    <div className="relative flex size-full min-h-screen flex-col bg-neutral-50">
      {/* Header */}
      <div className="flex items-center bg-neutral-50 p-4 pb-2 justify-between">
        <div className="text-[#141414] flex size-12 shrink-0 items-center" onClick={() => navigate(-1)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
            <path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path>
          </svg>
        </div>
        <h2 className="text-[#141414] text-lg font-bold leading-tight tracking-[-0.015em] flex-1 text-center">
          Kolaj Fotoğrafları
        </h2>
        <div className="flex w-12 items-center justify-end">
          <button 
            onClick={handleSavePhotos} 
            disabled={isLoading || photos.filter(photo => photo).length < 3} 
            className={`text-base font-bold leading-normal tracking-[0.015em] shrink-0 ${
              isLoading || photos.filter(photo => photo).length < 3 ? 'text-neutral-300' : 'text-neutral-500'
            }`}
          >
            {isLoading ? 'Yükleniyor...' : 'Kaydet'}
          </button>
        </div>
      </div>

      {/* Yükleme Göstergesi */}
      {isLoading && uploadProgress.total > 0 && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-2xl p-6 max-w-sm w-full mx-4">
            <div className="flex flex-col items-center">
              <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
                <div 
                  className="bg-[#141414] h-2.5 rounded-full transition-all duration-300" 
                  style={{ width: `${uploadProgress.current}%` }}
                ></div>
              </div>
              <p className="text-[#141414] text-sm">
                Fotoğraf Yükleniyor... ({uploadProgress.currentFile + 1}/{uploadProgress.total})
              </p>
              <p className="text-[#141414] text-sm">
                %{uploadProgress.current}
              </p>
            </div>
          </div>
        </div>
      )}

      {/* Başarı Mesajı */}
      {showSuccess && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-2xl p-6 max-w-sm w-full mx-4">
            <div className="flex flex-col items-center">
              <svg 
                className="w-16 h-16 text-green-500 mb-4" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth="2" 
                  d="M5 13l4 4L19 7"
                />
              </svg>
              <p className="text-[#141414] text-lg font-medium">
                Fotoğraflar başarıyla kaydedildi
              </p>
            </div>
          </div>
        </div>
      )}

      {/* Mevcut içerik */}
      <motion.div 
        className="flex-1 flex flex-col items-center justify-center p-4"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div 
          style={{ display: 'flex', width: '80%', gap: '16px', marginBottom: '20px' }}
          variants={itemVariants}
        >
          {/* Sol alan: Tek uzun kutu */}
          <motion.div 
            style={{ flex: 1, border: '1px solid grey', borderRadius: '6px', position: 'relative', cursor: 'pointer', height: '400px' }} 
            onClick={() => document.getElementById('photo-upload-0').click()}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            {photoURLs[0] ? (
              <img
                src={photoURLs[0]}
                alt={`Profil Önizleme 1`}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover', // Resmi divin boyutuna göre kes
                  borderRadius: '6px',
                }}
              />
            ) : (
              <Typography variant="body2" color="textSecondary" style={{ textAlign: 'center', padding: '20px' }}>
                Fotoğraf Yükle
              </Typography>
            )}
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id={`photo-upload-0`}
              type="file"
              onChange={handlePhotoChange(0)}
            />
          </motion.div>

          {/* Sağ alan: İki kutu dikey olarak istiflenmiş */}
          <div style={{ display: 'flex', flexDirection: 'column', flex: 1, gap: '8px' }}>
            <motion.div 
              style={{ flex: 1, border: '1px solid grey', borderRadius: '6px', position: 'relative', cursor: 'pointer', height: '50%', maxHeight: '196px' }} 
              onClick={() => document.getElementById('photo-upload-1').click()}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              {photoURLs[1] ? (
                <img
                  src={photoURLs[1]}
                  alt={`Profil Önizleme 2`}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover', // Resmi divin boyutuna göre kes
                    borderRadius: '6px',
                  }}
                />
              ) : (
                <Typography variant="body2" color="textSecondary" style={{ textAlign: 'center', padding: '20px' }}>
                  Fotoğraf Yükle
                </Typography>
              )}
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id={`photo-upload-1`}
                type="file"
                onChange={handlePhotoChange(1)}
              />
            </motion.div>
            <motion.div 
              style={{ flex: 1, border: '1px solid grey', borderRadius: '6px', position: 'relative', cursor: 'pointer', height: '50%', maxHeight: '196px' }} 
              onClick={() => document.getElementById('photo-upload-2').click()}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              {photoURLs[2] ? (
                <img
                  src={photoURLs[2]}
                  alt={`Profil Önizleme 3`}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover', // Resmi divin boyutuna göre kes
                    borderRadius: '6px',
                  }}
                />
              ) : (
                <Typography variant="body2" color="textSecondary" style={{ textAlign: 'center', padding: '20px' }}>
                  Fotoğraf Yükle
                </Typography>
              )}
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id={`photo-upload-2`}
                type="file"
                onChange={handlePhotoChange(2)}
              />
            </motion.div>
          </div>
        </motion.div>

        {photos.filter((photo) => photo).length < 3 && (
          <motion.div variants={itemVariants}>
            <Typography variant="body2" color="error" className="mt-2">
              Lütfen 3 adet fotoğraf yükleyin.
            </Typography>
          </motion.div>
        )}

        <motion.div 
          className="w-full px-9 flex justify-center mt-4"
          variants={itemVariants}
        >
          <div className="flex flex-col items-center gap-3 bg-white rounded-xl p-4 border border-[#dbdbdb]">
            <p className="font-medium text-[#141414]">Nasıl Fotoğraf Yüklemeliyim?</p>
            <div className="space-y-2 text-sm text-neutral-600">
              <p>• Sol alan: Boydan çekilmiş, net ve kaliteli fotoğrafınız</p>
              <p>• Sağ alanlar: Yüzünüzün net göründüğü portre fotoğraflarınız</p>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
}

export default Kolaj;
