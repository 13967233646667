import React, { useState } from 'react';
import { 
  HiOutlineSearch,
  HiOutlineBell,
  HiOutlineUser,
  HiOutlineLogout,
  HiOutlineCog,
  HiOutlineMoon,
  HiOutlineViewGrid
} from 'react-icons/hi';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const AdminHeader = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showQuickActions, setShowQuickActions] = useState(false);

  const notifications = [
    { id: 1, title: 'Yeni Kullanıcı Kaydı', time: '5 dk önce', type: 'user', unread: true },
    { id: 2, title: 'Proje Güncellemesi', time: '1 saat önce', type: 'project', unread: true },
    { id: 3, title: 'Ödeme Onayı Bekliyor', time: '2 saat önce', type: 'payment', unread: false },
  ];

  const quickActions = [
    { title: 'Yeni Proje', icon: <HiOutlineViewGrid size={20} />, path: '/admin/projects/add' },
    { title: 'Ayarlar', icon: <HiOutlineCog size={20} />, path: '/admin/settings' },
    { title: 'Profil', icon: <HiOutlineUser size={20} />, path: '/admin/profile' },
  ];

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error("Çıkış yapılırken hata:", error);
    }
  };

  return (
    <header className="h-20 bg-white border-b border-gray-100 sticky top-0 z-50">
      <div className="h-full px-6 flex items-center justify-between">
        {/* Search */}
        <div className="flex-1 max-w-xl">
          <div className="relative group">
            <input
              type="text"
              placeholder="Ara..."
              className="w-full px-5 py-3 pl-12 bg-gray-50 border border-transparent rounded-xl 
                text-sm transition-all duration-200 
                focus:outline-none focus:border-black focus:bg-white"
            />
            <HiOutlineSearch className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-400 
              group-focus-within:text-black transition-colors" size={20} />
          </div>
        </div>

        {/* Actions */}
        <div className="flex items-center gap-3">
          {/* Quick Actions */}
          <div className="relative">
            <button
              onClick={() => setShowQuickActions(!showQuickActions)}
              className="p-2.5 rounded-lg hover:bg-gray-50 relative"
            >
              <HiOutlineViewGrid size={22} />
            </button>
            
            {showQuickActions && (
              <div className="absolute right-0 mt-2 w-56 bg-white rounded-xl border border-gray-100 
                shadow-lg py-2 z-50">
                {quickActions.map((action, index) => (
                  <button
                    key={index}
                    onClick={() => navigate(action.path)}
                    className="w-full px-4 py-2.5 flex items-center gap-3 hover:bg-gray-50 text-sm"
                  >
                    {action.icon}
                    <span>{action.title}</span>
                  </button>
                ))}
              </div>
            )}
          </div>

          {/* Theme Toggle */}
          <button className="p-2.5 rounded-lg hover:bg-gray-50">
            <HiOutlineMoon size={22} />
          </button>

          {/* Notifications */}
          <div className="relative">
            <button
              onClick={() => setShowNotifications(!showNotifications)}
              className="p-2.5 rounded-lg hover:bg-gray-50 relative"
            >
              <HiOutlineBell size={22} />
              <span className="absolute top-1.5 right-1.5 w-2 h-2 bg-black rounded-full" />
            </button>

            {showNotifications && (
              <div className="absolute right-0 mt-2 w-80 bg-white rounded-xl border border-gray-100 
                shadow-lg py-2 z-50">
                {notifications.map(notification => (
                  <div
                    key={notification.id}
                    className={`px-4 py-3 hover:bg-gray-50 cursor-pointer flex items-start gap-3
                      ${notification.unread ? 'bg-gray-50' : ''}`}
                  >
                    <div className="flex-1">
                      <p className="text-sm font-medium">{notification.title}</p>
                      <p className="text-xs text-gray-500 mt-0.5">{notification.time}</p>
                    </div>
                    {notification.unread && (
                      <span className="w-2 h-2 bg-black rounded-full mt-1.5" />
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Profile */}
          <div className="relative">
            <button
              onClick={() => setShowUserMenu(!showUserMenu)}
              className="flex items-center gap-3 p-1.5 rounded-lg hover:bg-gray-50"
            >
              <div className="w-8 h-8 rounded-lg bg-gray-100 flex items-center justify-center overflow-hidden">
                {user?.profileImage ? (
                  <img src={user.profileImage} alt="Profile" className="w-full h-full object-cover" />
                ) : (
                  <HiOutlineUser size={20} />
                )}
              </div>
            </button>

            {showUserMenu && (
              <div className="absolute right-0 mt-2 w-56 bg-white rounded-xl border border-gray-100 
                shadow-lg py-2 z-50">
                <div className="px-4 py-2 border-b border-gray-100">
                  <p className="text-sm font-medium">{user?.firstName} {user?.lastName}</p>
                  <p className="text-xs text-gray-500">{user?.email}</p>
                </div>
                <button
                  onClick={handleLogout}
                  className="w-full px-4 py-2.5 flex items-center gap-3 hover:bg-gray-50 text-sm text-red-500"
                >
                  <HiOutlineLogout size={20} />
                  <span>Çıkış Yap</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default AdminHeader;
