import React, { useEffect, useState } from 'react';
import { getDatabase, ref, query, orderByChild, equalTo, onValue } from 'firebase/database'; // Realtime Database fonksiyonlarını ekle
import { useNavigate } from 'react-router-dom'; // Yönlendirme için kullan
import { motion } from 'framer-motion';
import AlertModal from '../components/AlertModal';
import { useAuth } from '../context/AuthContext'; // useAuth'u import ediyoruz
import FilterModal from '../components/FilterModal';

const db = getDatabase(); // Realtime Database'i başlat

const calculateAge = (birthDate) => {
  const today = new Date();
  const birthDateObj = new Date(birthDate);
  let age = today.getFullYear() - birthDateObj.getFullYear();
  const monthDiff = today.getMonth() - birthDateObj.getMonth();
  
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
    age--;
  }
  
  return age;
};

// Maskeleme fonksiyonu ekleyin
const maskName = (name, isAuthorized) => {
  if (isAuthorized) return name;
  
  const names = name.split(' ');
  return names.map(n => {
    if (n.length <= 2) return n;
    return n.slice(0, 2) + '*'.repeat(n.length - 2);
  }).join(' ');
};

const Oyuncular = () => {
  const [originalOyuncular, setOriginalOyuncular] = useState([]); // Orijinal liste için yeni state
  const [filteredOyuncular, setFilteredOyuncular] = useState([]); // Filtrelenmiş liste için state
  const [aramaMetni, setAramaMetni] = useState('');
  const navigate = useNavigate(); // Yönlendirme için kullan
  const [showAlert, setShowAlert] = useState(false);
  const { user } = useAuth(); // user bilgisini alıyoruz
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [activeFilters, setActiveFilters] = useState({});
  const [showFilterAlert, setShowFilterAlert] = useState(false); // Yeni state ekle

  useEffect(() => {
    const oyuncularRef = ref(db, 'users');
    const onayliOyuncularQuery = query(
      oyuncularRef,
      orderByChild('profilecv/approvalStatus'),
      equalTo('approved')
    );

    onValue(onayliOyuncularQuery, (snapshot) => {
      const data = snapshot.val();
      const yuklenenOyuncular = [];
      for (let id in data) {
        const oyuncu = data[id];
        if (oyuncu.profilecv && oyuncu.profilecv.approvalStatus === 'approved' && oyuncu.profilecv.globalcv) {
          const yas = calculateAge(oyuncu.profilecv.globalcv.birthDate);

          yuklenenOyuncular.push({
            id,
            ad: `${oyuncu.firstName} ${oyuncu.lastName}`,
            pozisyon: oyuncu.profilecv.globalcv.position || 'Belirtilmemiş',
            resimUrl: oyuncu.profileImage || oyuncu.profilecv.profileImage || 'varsayilan_resim_url',
            yas: yas,
            sehir: oyuncu.profilecv.globalcv.city || 'Belirtilmemiş',
            gender: oyuncu.profilecv.globalcv.gender,
            hairColor: oyuncu.profilecv.globalcv.hairColor,
            height: oyuncu.profilecv.globalcv.height
          });
        }
      }
      setOriginalOyuncular(yuklenenOyuncular);
      setFilteredOyuncular(yuklenenOyuncular);
    });
  }, []);

  const handleOyuncuClick = (id) => {
    if (!user || (user.role !== 'admin' && user.role !== 'production')) {
      setShowAlert(true);
      return;
    }
    navigate(`/players/${id}`);
  };

  const handleApplyFilters = (filters) => {
    let result = [...originalOyuncular];

    // Yaş filtresi
    if (filters.ageRange.min || filters.ageRange.max) {
      result = result.filter(oyuncu => {
        const yas = oyuncu.yas;
        if (filters.ageRange.min && yas < parseInt(filters.ageRange.min)) return false;
        if (filters.ageRange.max && yas > parseInt(filters.ageRange.max)) return false;
        return true;
      });
    }

    // Cinsiyet filtresi
    if (filters.gender) {
      result = result.filter(oyuncu => oyuncu.gender === filters.gender);
    }

    // Saç rengi filtresi
    if (filters.hairColor) {
      result = result.filter(oyuncu => oyuncu.hairColor === filters.hairColor);
    }

    // Boy filtresi
    if (filters.height.min || filters.height.max) {
      result = result.filter(oyuncu => {
        const boy = parseInt(oyuncu.height);
        if (filters.height.min && boy < parseInt(filters.height.min)) return false;
        if (filters.height.max && boy > parseInt(filters.height.max)) return false;
        return true;
      });
    }

    // Şehir filtresi
    if (filters.city) {
      result = result.filter(oyuncu => 
        oyuncu.sehir.toLowerCase().includes(filters.city.toLowerCase())
      );
    }

    setFilteredOyuncular(result);
  };

  // Arama işlemi için filtreleme
  const filtrelenmisOyuncular = filteredOyuncular.filter(oyuncu =>
    oyuncu.ad.toLowerCase().includes(aramaMetni.toLowerCase())
  );

  const handleFilterClick = () => {
    if (!user || (user.role !== 'admin' && user.role !== 'production')) {
      setShowFilterAlert(true);
      return;
    }
    setShowFilterModal(true);
  };

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="relative flex flex-col min-h-screen bg-neutral-50" 
      style={{ fontFamily: '"Space Grotesk", "Noto Sans", sans-serif' }}
    >
      {/* Başlık */}
      <motion.div 
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        className="flex items-center bg-neutral-50 p-4 pb-2 justify-between"
      >
    
        <h2 className="text-[#141414] text-lg font-bold leading-tight tracking-[-0.015em] flex-1 text-center">Tüm Oyuncular</h2>
        <div className="flex w-12 items-center justify-end">
          <button 
            onClick={handleFilterClick}
            className={`flex max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-full h-12 bg-transparent gap-2 text-base font-bold leading-normal tracking-[0.015em] min-w-0 p-0 ${
              (!user || (user.role !== 'admin' && user.role !== 'production')) 
                ? 'text-[#A2A1A1]' // Yetkisiz kullanıcılar için soluk renk
                : 'text-[#141414]' // Yetkili kullanıcılar için normal renk
            }`}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
              <path d="M64,105V40a8,8,0,0,0-16,0v65a32,32,0,0,0,0,62v49a8,8,0,0,0,16,0V167a32,32,0,0,0,0-62Zm-8,47a16,16,0,1,1,16-16A16,16,0,0,1,56,152Zm80-95V40a8,8,0,0,0-16,0V57a32,32,0,0,0,0,62v97a8,8,0,0,0,16,0V119a32,32,0,0,0,0-62Zm-8,47a16,16,0,1,1,16-16A16,16,0,0,1,128,104Zm104,64a32.06,32.06,0,0,0-24-31V40a8,8,0,0,0-16,0v97a32,32,0,0,0,0,62v17a8,8,0,0,0,16,0V199A32.06,32.06,0,0,0,232,168Zm-32,16a16,16,0,1,1,16-16A16,16,0,0,1,200,184Z"></path>
            </svg>
          </button>
        </div>
      </motion.div>

      <FilterModal
        isOpen={showFilterModal}
        onClose={() => setShowFilterModal(false)}
        onApplyFilters={handleApplyFilters}
      />

      {/* Arama çubuğu */}
      <div className="px-4 py-3 w-full">
        <div className="flex items-center w-full bg-[#ededed] rounded-xl">
          <div className="text-neutral-500 flex items-center justify-center pl-4">
            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
              <path d="M229.66,218.34l-50.07-50.06a88.11,88.11,0,1,0-11.31,11.31l50.06,50.07a8,8,0,0,0,11.32-11.32ZM40,112a72,72,0,1,1,72,72A72.08,72.08,0,0,1,40,112Z"></path>
            </svg>
          </div>
          <input
            placeholder="Oyuncu ara"
            className="flex-1 bg-transparent border-none focus:ring-0 text-[#141414] h-12 px-4 text-base font-normal leading-normal"
            value={aramaMetni}
            onChange={(e) => setAramaMetni(e.target.value)}
          />
        </div>
      </div>

      {/* Oyuncu Listesi */}
      <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 gap-2 p-3 flex-grow">
        {filtrelenmisOyuncular.map((oyuncu) => (
          <motion.div 
            key={oyuncu.id} 
            className="flex flex-col gap-2 text-center pb-2 cursor-pointer" 
            onClick={() => handleOyuncuClick(oyuncu.id)}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <div className="px-2">
              <div
                className="w-full bg-center bg-no-repeat aspect-square bg-cover rounded-full"
                style={{ backgroundImage: `url(${oyuncu.resimUrl || 'varsayilan_resim_url'})` }}
              ></div>
            </div>
            <div>
              <p className="text-[#141414] text-sm font-medium leading-tight truncate px-1">
                {maskName(oyuncu.ad, user?.role === 'admin' || user?.role === 'production')}
              </p>
              <p className="text-neutral-500 text-xs font-normal leading-tight truncate px-1">
                {oyuncu.yas} yaş • {oyuncu.sehir}
              </p>
            </div>
          </motion.div>
        ))}
      </div>

      <div className="h-5 bg-neutral-50"></div>

      <AlertModal
        isOpen={showFilterAlert}
        onClose={() => setShowFilterAlert(false)}
        message="Filtreleme özelliğini kullanmak için yetkiniz bulunmamaktadır."
      />

      <AlertModal
        isOpen={showAlert}
        onClose={() => setShowAlert(false)}
        message="Oyuncu detaylarını görüntülemek için yetkiniz bulunmamaktadır."
      />
    </motion.div>
  );
};

export default Oyuncular;
