import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

const CVHeader = ({ 
  onBack, 
  onSave, 
  title = "CV Düzenle",
  saveText = "Kaydet",
  showSave = true,
  loading = false 
}) => (
  <motion.div 
    className="flex items-center bg-neutral-50 p-4 pb-2 justify-between sticky top-0 z-10 border-b border-neutral-200"
    initial={{ y: -20, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    transition={{ duration: 0.3 }}
  >
    <motion.div 
      className="text-[#141414] flex size-12 shrink-0 items-center cursor-pointer"
      onClick={onBack}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
    >
      <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width="24px" 
        height="24px" 
        fill="currentColor" 
        viewBox="0 0 256 256"
      >
        <path d="M224,128a8,8,0,0,1-8,8H59.31l58.35,58.34a8,8,0,0,1-11.32,11.32l-72-72a8,8,0,0,1,0-11.32l72-72a8,8,0,0,1,11.32,11.32L59.31,120H216A8,8,0,0,1,224,128Z" />
      </svg>
    </motion.div>

    <motion.h2 
      className="text-[#141414] text-lg font-bold leading-tight tracking-[-0.015em] flex-1 text-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.2 }}
    >
      {title}
    </motion.h2>

    {showSave && (
      <motion.div 
        className="flex w-12 items-center justify-end"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3 }}
      >
        <button 
          onClick={onSave}
          disabled={loading}
          className={`text-neutral-500 text-base font-bold leading-normal tracking-[0.015em] shrink-0
            ${loading ? 'opacity-50 cursor-not-allowed' : 'hover:text-neutral-700 active:text-neutral-800'}
            transition-colors duration-200`}
        >
          {loading ? (
            <motion.div
              animate={{ rotate: 360 }}
              transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
              className="w-5 h-5 border-2 border-neutral-500 border-t-transparent rounded-full"
            />
          ) : (
            saveText
          )}
        </button>
      </motion.div>
    )}
  </motion.div>
);

CVHeader.propTypes = {
  onBack: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  title: PropTypes.string,
  saveText: PropTypes.string,
  showSave: PropTypes.bool,
  loading: PropTypes.bool
};

export default CVHeader;
