import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getDatabase, ref, get } from 'firebase/database';
import { FaMapMarkerAlt, FaClock, FaUsers, FaCalendarAlt, FaFilm, FaPalette, FaEye, FaRulerVertical, FaWeight, FaShoePrints, FaMale, FaFemale, FaChild } from 'react-icons/fa';

const db = getDatabase();

const ProjectDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const projectRef = ref(db, `projects/${id}`);
        const snapshot = await get(projectRef);
        if (snapshot.exists()) {
          setProject({ id, ...snapshot.val() });
        } else {
          console.log('Proje bulunamadı');
        }
      } catch (error) {
        console.error("Veri alınırken hata oluştu:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProjectDetails();
  }, [id]);

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Yükleniyor...</div>;
  }

  if (!project) {
    return <div className="flex justify-center items-center h-screen">Proje bulunamadı.</div>;
  }

  return (
    <div
      className="relative flex min-h-screen flex-col bg-[#FFFFFF] justify-between overflow-x-hidden pb-24"
      style={{ fontFamily: '"Work Sans", "Noto Sans", sans-serif' }}
    >
      <div>
        <div className="flex items-center bg-[#FFFFFF] p-4 pb-2 justify-between">
          <div
            className="text-black flex size-12 shrink-0 items-center cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24px"
              height="24px"
              fill="currentColor"
              viewBox="0 0 256 256"
            >
              <path d="M224,128a8,8,0,0,1-8,8H59.31l58.35,58.34a8,8,0,0,1-11.32,11.32l-72-72a8,8,0,0,1,0-11.32l72-72a8,8,0,0,1,11.32,11.32L59.31,120H216A8,8,0,0,1,224,128Z"></path>
            </svg>
          </div>
          <h2 className="text-black text-lg font-bold leading-tight tracking-[-0.015em] flex-1 text-center pr-12">
            Proje Detayları
          </h2>
        </div>

        {/* Project Image */}
        <div className="w-full aspect-video mb-4">
          <img 
            src={project.imageUrl || 'https://via.placeholder.com/400x225'} 
            alt={project.title} 
            className="w-full h-full object-fit"
          />
        </div>

        <h1 className="text-black text-[22px] font-bold leading-tight tracking-[-0.015em] px-4 text-left pb-3">
          {project.title}
        </h1>

        {/* Project Type and Date */}
        <div className="flex items-center gap-4 bg-[#FFFFFF] px-4 min-h-[72px] py-2">
          <div className="text-black flex items-center justify-center rounded-lg bg-[#EEEEEE] shrink-0 size-12">
            <FaFilm size={24} />
          </div>
          <div className="flex flex-col justify-center">
            <p className="text-black text-base font-medium leading-normal line-clamp-1">Proje Türü</p>
            <p className="text-[#6B6B6B] text-sm font-normal leading-normal line-clamp-2">{project.projectType}</p>
          </div>
        </div>

        {/* Project Date and Time */}
        <div className="flex items-center gap-4 bg-[#FFFFFF] px-4 min-h-[72px] py-2">
          <div className="text-black flex items-center justify-center rounded-lg bg-[#EEEEEE] shrink-0 size-12">
            <FaCalendarAlt size={24} />
          </div>
          <div className="flex flex-col justify-center">
            <p className="text-black text-base font-medium leading-normal line-clamp-1">Tarih ve Saat</p>
            <p className="text-[#6B6B6B] text-sm font-normal leading-normal line-clamp-2">
              {project.date} {project.startTime && project.endTime ? `${project.startTime} - ${project.endTime}` : ''}
            </p>
          </div>
        </div>

        {/* Project Location */}
        <div className="flex items-center gap-4 bg-[#FFFFFF] px-4 min-h-[72px] py-2">
          <div className="text-black flex items-center justify-center rounded-lg bg-[#EEEEEE] shrink-0 size-12">
            <FaMapMarkerAlt size={24} />
          </div>
          <div className="flex flex-col justify-center">
            <p className="text-black text-base font-medium leading-normal line-clamp-1">Konum</p>
            <p className="text-[#6B6B6B] text-sm font-normal leading-normal line-clamp-2">
              {project.city && project.location ? `${project.city}, ${project.location}` : project.city || project.location}
            </p>
          </div>
        </div>

        {/* People Needed */}
        <div className="flex items-center gap-4 bg-[#FFFFFF] px-4 min-h-[72px] py-2">
          <div className="text-black flex items-center justify-center rounded-lg bg-[#EEEEEE] shrink-0 size-12">
            <FaUsers size={24} />
          </div>
          <div className="flex flex-col justify-center">
            <p className="text-black text-base font-medium leading-normal line-clamp-1">İhtiyaç Duyulan Kişi Sayısı</p>
            <div className="flex flex-wrap gap-2">
              {project.peopleNeeded?.men > 0 && (
                <p className="text-[#6B6B6B] text-sm font-normal leading-normal">
                  <FaMale className="inline mr-1" /> Erkek: {project.peopleNeeded.men}
                </p>
              )}
              {project.peopleNeeded?.women > 0 && (
                <p className="text-[#6B6B6B] text-sm font-normal leading-normal">
                  <FaFemale className="inline mr-1" /> Kadın: {project.peopleNeeded.women}
                </p>
              )}
              {project.peopleNeeded?.children > 0 && (
                <p className="text-[#6B6B6B] text-sm font-normal leading-normal">
                  <FaChild className="inline mr-1" /> Çocuk: {project.peopleNeeded.children}
                </p>
              )}
            </div>
          </div>
        </div>

        {/* Description */}
        <h2 className="text-black text-[22px] font-bold leading-tight tracking-[-0.015em] px-4 pb-3 pt-5">
          Açıklama
        </h2>
        <p className="text-black text-base font-normal leading-normal pb-3 pt-1 px-4">
          {project.description}
        </p>

        {/* Actor Requirements */}
        <h2 className="text-black text-[22px] font-bold leading-tight tracking-[-0.015em] px-4 pb-3 pt-5">
          Oyuncu Gereksinimleri
        </h2>

        {project.requirements && (
          <div className="grid grid-cols-2 gap-4 px-4 mb-6">
            {project.requirements.hairColor && (
              <div className="flex items-center gap-2">
                <FaPalette size={20} />
                <p className="text-[#6B6B6B] text-sm">Saç Rengi: {project.requirements.hairColor}</p>
              </div>
            )}
            {project.requirements.eyeColor && (
              <div className="flex items-center gap-2">
                <FaEye size={20} />
                <p className="text-[#6B6B6B] text-sm">Göz Rengi: {project.requirements.eyeColor}</p>
              </div>
            )}
            {project.requirements.height && (
              <div className="flex items-center gap-2">
                <FaRulerVertical size={20} />
                <p className="text-[#6B6B6B] text-sm">Boy: {project.requirements.height} cm</p>
              </div>
            )}
            {project.requirements.weight && (
              <div className="flex items-center gap-2">
                <FaWeight size={20} />
                <p className="text-[#6B6B6B] text-sm">Kilo: {project.requirements.weight} kg</p>
              </div>
            )}
            {project.requirements.shoeSize && (
              <div className="flex items-center gap-2">
                <FaShoePrints size={20} />
                <p className="text-[#6B6B6B] text-sm">Ayakkabı Numarası: {project.requirements.shoeSize}</p>
              </div>
            )}
          </div>
        )}

        {/* Sign Up Button */}
        <div className="flex px-4 py-3 mb-6">
          <button className="flex min-w-[84px] max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-12 px-5 flex-1 bg-black text-[#FFFFFF] text-base font-bold leading-normal tracking-[0.015em]">
            <span className="truncate">Kayıt ol</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;
