import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MdViewList, MdCampaign, MdGroup, MdAccountCircle } from 'react-icons/md';

const BottomNavBar = () => {
  const location = useLocation();

  const navItems = [
    { path: '/projects', icon: MdViewList, label: 'Projects' },
    { path: '/announcements', icon: MdCampaign, label: 'Announcements' },
    { path: '/players', icon: MdGroup, label: 'Players' },
    { path: '/profile', icon: MdAccountCircle, label: 'Profile' },
  ];

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-neutral-50">
      <div className="flex gap-2 border-t border-[#ededed] bg-neutral-50 px-4 pb-3 pt-2">
        {navItems.map((item) => (
          <Link
            key={item.path}
            to={item.path}
            className={`flex flex-1 flex-col items-center justify-end gap-1 ${
              location.pathname === item.path
                ? 'text-[#141414]'
                : 'text-neutral-500'
            }`}
          >
            <div className={`flex h-8 items-center justify-center ${
              location.pathname === item.path ? 'text-[#141414]' : 'text-neutral-500'
            }`}>
              <item.icon size="24px" />
            </div>
          
          </Link>
        ))}
      </div>
      <div className="h-5 bg-neutral-50"></div>
    </div>
  );
};

export default BottomNavBar;
