import React, { useState, useEffect } from 'react';
import { db } from '../firebaseconf';
import { ref, get, update } from 'firebase/database';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const USER_ROLES = {
  ADMIN: 'admin',
  USER: 'user',
  PRODUCTION: 'production'
};

function UserManagement() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      const usersRef = ref(db, 'users');
      const snapshot = await get(usersRef);
      if (snapshot.exists()) {
        const usersData = snapshot.val();
        const usersArray = Object.keys(usersData).map(key => ({
          id: key,
          ...usersData[key]
        }));
        setUsers(usersArray);
      }
      setLoading(false);
    };

    fetchUsers();
  }, []);

  const handleRoleChange = async (userId, newRole) => {
    try {
      const userRef = ref(db, `users/${userId}`);
      await update(userRef, { role: newRole });
      setUsers(users.map(u => u.id === userId ? { ...u, role: newRole } : u));
      alert('Kullanıcı rolü başarıyla güncellendi.');
    } catch (error) {
      console.error('Rol güncelleme hatası:', error);
      alert('Rol güncellenirken bir hata oluştu.');
    }
  };

  const handleSave = () => {
    // Burada tüm değişiklikleri kaydetme işlemi yapılabilir
    alert('Değişiklikler kaydedildi.');
  };

  if (loading) {
    return <div className="text-center mt-8">Yükleniyor...</div>;
  }

  return (
    <div className="relative flex size-full min-h-screen flex-col bg-white group/design-root overflow-x-hidden" style={{ fontFamily: "'Be Vietnam Pro', 'Noto Sans', sans-serif" }}>
      <div className="flex items-center bg-neutral-50 p-4 pb-2 justify-between">
        <div className="text-[#141414] flex size-12 shrink-0 items-center" onClick={() => navigate(-1)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
            <path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path>
          </svg>
        </div>
        <h2 className="text-[#141414] text-lg font-bold leading-tight tracking-[-0.015em] flex-1 text-center">Kullanıcı Yönetimi</h2>
        <div className="flex w-12 items-center justify-end">
          <button onClick={handleSave} className="text-neutral-500 text-base font-bold leading-normal tracking-[0.015em] shrink-0">
            Kaydet
          </button>
        </div>
      </div>

      <div className="container mx-auto p-4">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
          {users.map(user => (
            <div key={user.id} className="bg-white rounded-lg shadow-md p-4">
              <h2 className="text-lg font-semibold mb-2">
                {user.firstName} {user.lastName}
              </h2>
              <p className="text-gray-600 mb-2">Mevcut Rol: {user.role || 'Rol atanmamış'}</p>
              <select
                value={user.role || ''}
                onChange={(e) => handleRoleChange(user.id, e.target.value)}
                className="w-full border rounded px-2 py-1"
              >
                <option value="">Rol seçin</option>
                {Object.values(USER_ROLES).map(role => (
                  <option key={role} value={role}>{role}</option>
                ))}
              </select>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default UserManagement;
