import React, { useState, useEffect } from 'react';
import { getDatabase, ref, get, update } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { motion } from 'framer-motion';
import { FaSpinner, FaUser, FaEye } from 'react-icons/fa';

const db = getDatabase();

function AdminApproval() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [pendingUsers, setPendingUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [rejectReason, setRejectReason] = useState('');
  const [rejectingUserId, setRejectingUserId] = useState(null);

  useEffect(() => {
    const checkAdminAndFetchUsers = async () => {
      if (!user) {
        navigate('/login');
        return;
      }

      const userRef = ref(db, `users/${user.uid}`);
      const userSnapshot = await get(userRef);
      const userData = userSnapshot.val();

      if (!userData || userData.role !== 'admin') {
        navigate('/');
        return;
      }

      const fetchPendingUsers = async () => {
        const usersRef = ref(db, 'users');
        try {
          const snapshot = await get(usersRef);
          if (snapshot.exists()) {
            const users = [];
            snapshot.forEach((childSnapshot) => {
              const userData = childSnapshot.val();
              const profilecv = userData.profilecv || {};
              
              const approvalStatus = profilecv.approvalStatus || userData.approvalStatus;
              
              if (approvalStatus === 'pending') {
                users.push({ 
                  id: childSnapshot.key, 
                  firstName: userData.firstName || '',
                  lastName: userData.lastName || '',
                  email: userData.email || '',
                  profileImage: userData.profileImage || '',
                  playerDetails: userData.playerDetails || {},
                  ...profilecv 
                });
              }
            });
            setPendingUsers(users);
          }
        } catch (error) {
          console.error("Kullanıcı verileri çekilirken hata oluştu:", error);
          setError("Kullanıcı bilgileri yüklenirken bir hata oluştu.");
        } finally {
          setLoading(false);
        }
      };

      fetchPendingUsers();
    };

    checkAdminAndFetchUsers();
  }, [user, navigate]);

  const handleApproval = async (userId, isApproved) => {
    try {
      const userRef = ref(db, `users/${userId}/profilecv`);
      await update(userRef, {
        approvalStatus: isApproved ? 'approved' : 'rejected',
        approvalDate: new Date().toISOString()
      });
      setPendingUsers(pendingUsers.filter(user => user.id !== userId));
      setSelectedUser(null);
    } catch (error) {
      console.error("Onay işlemi sırasında hata oluştu:", error);
      setError("Onay işlemi sırasında bir hata oluştu. Lütfen daha sonra tekrar deneyin.");
    }
  };

  // Detayları göster butonuna tıklandığında çağrılacak fonksiyon
  const handleViewDetails = (userId) => {
    navigate(`/players/${userId}`);
  };

  const handleRejectClick = (userId) => {
    setRejectingUserId(userId);
    setShowRejectModal(true);
  };

  const handleRejectConfirm = async () => {
    if (!rejectReason.trim()) {
      setError("Lütfen red sebebini belirtin");
      return;
    }

    try {
      const userRef = ref(db, `users/${rejectingUserId}/profilecv`);
      await update(userRef, {
        approvalStatus: 'rejected',
        approvalDate: new Date().toISOString(),
        rejectionReason: rejectReason
      });
      setPendingUsers(pendingUsers.filter(user => user.id !== rejectingUserId));
      setShowRejectModal(false);
      setRejectReason('');
      setRejectingUserId(null);
    } catch (error) {
      console.error("Red işlemi sırasında hata oluştu:", error);
      setError("Red işlemi sırasında bir hata oluştu. Lütfen daha sonra tekrar deneyin.");
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-[#F1F0F0]">
        <FaSpinner className="animate-spin text-4xl text-[#292929]" />
      </div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="min-h-screen bg-[#F1F0F0]"
    >
      <motion.div 
        className="sticky top-0 z-10 flex items-center bg-white p-4 justify-between shadow-sm border-b border-[#A2A1A1]/20"
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <motion.div
          className="text-[#1F1F1F] flex size-12 shrink-0 items-center cursor-pointer"
          onClick={() => navigate(-1)}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24px"
            height="24px"
            fill="currentColor"
            viewBox="0 0 256 256"
          >
            <path d="M224,128a8,8,0,0,1-8,8H59.31l58.35,58.34a8,8,0,0,1-11.32,11.32l-72-72a8,8,0,0,1,0-11.32l72-72a8,8,0,0,1,11.32,11.32L59.31,120H216A8,8,0,0,1,224,128Z"></path>
          </svg>
        </motion.div>
        <h2 className="text-[#1F1F1F] text-lg font-bold leading-tight tracking-[-0.015em] flex-1 text-center pr-12">
          Hesap Onaylama Paneli
        </h2>
      </motion.div>

      {/* Red Modalı */}
      {showRejectModal && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <motion.div 
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            className="bg-white rounded-lg p-6 max-w-md w-full mx-4"
          >
            <h3 className="text-lg font-semibold text-[#1F1F1F] mb-4">Red Sebebi</h3>
            <textarea
              value={rejectReason}
              onChange={(e) => setRejectReason(e.target.value)}
              placeholder="Lütfen red sebebini belirtin..."
              className="w-full p-3 rounded-lg bg-[#F1F0F0] border-none focus:ring-2 focus:ring-[#4A494A] text-[#1F1F1F] placeholder-[#A2A1A1] min-h-[120px] mb-4"
              rows="4"
            />
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => {
                  setShowRejectModal(false);
                  setRejectReason('');
                  setRejectingUserId(null);
                }}
                className="px-4 py-2 rounded-lg bg-[#F1F0F0] text-[#1F1F1F] hover:bg-[#A2A1A1]/20 transition-colors"
              >
                İptal
              </button>
              <button
                onClick={handleRejectConfirm}
                className="px-4 py-2 rounded-lg bg-[#292929] text-white hover:bg-[#4A494A] transition-colors"
              >
                Reddet
              </button>
            </div>
          </motion.div>
        </div>
      )}

      <div className="max-w-4xl mx-auto p-3 sm:p-6">
        {error && (
          <p className="text-red-500 mb-4 text-sm">{error}</p>
        )}

        {pendingUsers.length === 0 ? (
          <p className="text-[#4A494A] text-center p-4">Onay bekleyen kullanıcı bulunmamaktadır.</p>
        ) : (
          <div className="space-y-4">
            {pendingUsers.map((user) => (
              <motion.div 
                key={user.id} 
                className="border border-[#A2A1A1]/20 p-4 sm:p-6 rounded-lg bg-white shadow-sm"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
              >
                <div className="flex items-start justify-between mb-6">
                  <div className="flex items-center space-x-4">
                    <div className="w-16 h-16 rounded-full overflow-hidden bg-[#F1F0F0] flex-shrink-0">
                      {user.profileImage ? (
                        <img 
                          src={user.profileImage} 
                          alt={`${user.firstName} ${user.lastName}`}
                          className="w-full h-full object-cover"
                        />
                      ) : (
                        <div className="w-full h-full flex items-center justify-center">
                          <FaUser className="text-[#A2A1A1] text-2xl" />
                        </div>
                      )}
                    </div>
                    <div>
                      <h2 className="text-lg font-semibold text-[#1F1F1F]">
                        {user.firstName} {user.lastName}
                      </h2>
                      <p className="text-sm text-[#4A494A]">{user.email}</p>
                    </div>
                  </div>

                  <button
                    onClick={() => handleViewDetails(user.id)}
                    className="flex items-center space-x-2 text-[#4A494A] hover:text-[#1F1F1F] transition-colors bg-[#F1F0F0] px-4 py-2 rounded-lg"
                  >
                    <FaEye />
                  </button>
                </div>

                <div className="flex justify-end space-x-3 mt-4 border-t border-[#A2A1A1]/20 pt-4">
                  <button
                    onClick={() => handleApproval(user.id, true)}
                    className="bg-[#292929] text-white px-6 py-2 rounded-lg hover:bg-[#4A494A] transition-colors"
                  >
                    Onayla
                  </button>
                  <button
                    onClick={() => handleRejectClick(user.id)}
                    className="bg-[#F1F0F0] text-[#1F1F1F] px-6 py-2 rounded-lg hover:bg-[#A2A1A1]/20 transition-colors"
                  >
                    Reddet
                  </button>
                </div>
              </motion.div>
            ))}
          </div>
        )}
      </div>
    </motion.div>
  );
}

export default AdminApproval;
