import React, { useState, useEffect } from 'react';
import { getDatabase, ref, query, orderByChild, equalTo, get, remove } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { motion } from 'framer-motion';
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';

const ProjectManagement = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjects = async () => {
      const db = getDatabase();
      const projectsRef = ref(db, 'projects');
      const userProjectsQuery = query(projectsRef, orderByChild('userId'), equalTo(user.uid));

      try {
        const snapshot = await get(userProjectsQuery);
        if (snapshot.exists()) {
          const projectsData = snapshot.val();
          const projectsList = Object.keys(projectsData).map(key => ({
            id: key,
            ...projectsData[key]
          }));
          setProjects(projectsList);
        } else {
          setProjects([]);
        }
      } catch (error) {
        console.error("Projeler yüklenirken hata oluştu:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, [user.uid]);

  const handleEdit = (projectId) => {
    navigate(`/edit-project/${projectId}`);
  };

  const handleDelete = async (projectId) => {
    if (window.confirm("Bu projeyi silmek istediğinizden emin misiniz?")) {
      const db = getDatabase();
      const projectRef = ref(db, `projects/${projectId}`);
      try {
        await remove(projectRef);
        setProjects(projects.filter(project => project.id !== projectId));
        alert("Proje başarıyla silindi.");
      } catch (error) {
        console.error("Proje silinirken hata oluştu:", error);
        alert("Proje silinirken bir hata oluştu.");
      }
    }
  };

  const getProjectTypeText = (type) => {
    switch (type) {
      case 'advert':
        return 'Reklam Filmi';
      case 'localSeries':
        return 'Yerli Dizi';
      case 'foreignSeries':
        return 'Yabancı Dizi';
      case 'localMovie':
        return 'Yerli Film';
      case 'foreignMovie':
        return 'Yabancı Film';
      default:
        return type;
    }
  };

  if (loading) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="flex items-center justify-center min-h-screen text-lg font-semibold"
      >
        Yükleniyor...
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="relative flex min-h-screen flex-col bg-neutral-50 overflow-x-hidden pb-20"
      style={{ fontFamily: '"Work Sans", "Noto Sans", sans-serif' }}
    >
      <div className="flex items-center bg-neutral-50 p-4 pb-2 justify-between">
        <div className="text-[#141414] flex size-12 shrink-0 items-center cursor-pointer" onClick={() => navigate(-1)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
            <path d="M224,128a8,8,0,0,1-8,8H59.31l58.35,58.34a8,8,0,0,1-11.32,11.32l-72-72a8,8,0,0,1,0-11.32l72-72a8,8,0,0,1,11.32,11.32L59.31,120H216A8,8,0,0,1,224,128Z"></path>
          </svg>
        </div>
        <h2 className="text-[#141414] text-lg font-bold leading-tight tracking-[-0.015em] flex-1 text-center">Proje Yönetimi</h2>
        <div className="size-12 shrink-0"></div>
      </div>

      <div className="px-4 py-6">
        <motion.button
          whileTap={{ scale: 0.95 }}
          className="w-full bg-neutral-100 text-[#141414] font-bold py-3 px-4 rounded-xl flex items-center justify-center gap-2"
          onClick={() => navigate('/add-project')}
        >
          <FaPlus /> Yeni Proje Ekle
        </motion.button>
      </div>

      {projects.length === 0 ? (
        <p className="text-center text-gray-500 mt-8">Henüz hiç proje eklemediniz.</p>
      ) : (
        <ul className="space-y-4 px-4">
          {projects.map(project => (
            <motion.li
              key={project.id}
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -20, opacity: 0 }}
              className="bg-white shadow-md rounded-xl p-4"
            >
              <div className="flex items-center space-x-4">
                <div className="w-20 h-20 bg-gray-200 rounded-lg overflow-hidden">
                  {project.imageUrl ? (
                    <img src={project.imageUrl} alt={project.title} className="w-full h-full object-cover" />
                  ) : (
                    <div className="w-full h-full flex items-center justify-center text-gray-400">
                      Resim Yok
                    </div>
                  )}
                </div>
                <div className="flex-1">
                  <h2 className="text-xl font-semibold text-[#141414]">{project.title}</h2>
                  <p className="text-gray-600">{getProjectTypeText(project.projectType)}</p>
                  <p className="text-sm text-gray-500">
                    {project.date && `${project.date} • `}
                    {project.city && project.location ? `${project.city}, ${project.location}` : project.city || project.location}
                  </p>
                </div>
              </div>
              <div className="mt-4 flex justify-end space-x-2">
                <motion.button
                  whileTap={{ scale: 0.95 }}
                  onClick={() => handleEdit(project.id)}
                  className="bg-neutral-100 text-[#141414] px-4 py-2 rounded-lg flex items-center gap-2"
                >
                  <FaEdit /> Düzenle
                </motion.button>
                <motion.button
                  whileTap={{ scale: 0.95 }}
                  onClick={() => handleDelete(project.id)}
                  className="bg-neutral-100 text-[#141414] px-4 py-2 rounded-lg flex items-center gap-2"
                >
                  <FaTrash /> Sil
                </motion.button>
              </div>
            </motion.li>
          ))}
        </ul>
      )}
    </motion.div>
  );
};

export default ProjectManagement;
