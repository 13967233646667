import React, { useEffect, useState } from 'react';
import { db } from '../../firebaseconf';
import { ref, onValue, push, remove, update } from 'firebase/database';
import { 
  HiOutlineSearch,
  HiOutlinePencil,
  HiOutlineTrash,
  HiOutlineEye,
  HiOutlineUserGroup,
  HiOutlineClock
} from 'react-icons/hi';
import Modal from 'react-modal';

const AdminAnnouncements = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    content: ''
  });

  useEffect(() => {
    const announcementsRef = ref(db, 'announcements');
    onValue(announcementsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const announcementsArray = Object.entries(data).map(([id, announcement]) => ({
          id,
          ...announcement
        }));
        setAnnouncements(announcementsArray);
      }
      setIsLoading(false);
    });
  }, []);

  const filteredAnnouncements = announcements.filter(announcement =>
    announcement.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    announcement.content?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCreate = async (e) => {
    e.preventDefault();
    try {
      const newAnnouncement = {
        title: formData.title,
        content: formData.content,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        okuyanlar: [],
        status: 'active'
      };

      const announcementsRef = ref(db, 'announcements');
      await push(announcementsRef, newAnnouncement);
      
      setIsCreateModalOpen(false);
      setFormData({ title: '', content: '' });
    } catch (error) {
      console.error('Duyuru oluşturma hatası:', error);
    }
  };

  const handleEdit = (announcement) => {
    setSelectedAnnouncement(announcement);
    setFormData({
      title: announcement.title,
      content: announcement.content
    });
    setIsEditModalOpen(true);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      await update(ref(db, `announcements/${selectedAnnouncement.id}`), {
        ...selectedAnnouncement,
        ...formData,
        updatedAt: new Date().toISOString()
      });
      setIsEditModalOpen(false);
      setSelectedAnnouncement(null);
      setFormData({ title: '', content: '' });
    } catch (error) {
      console.error('Announcement update error:', error);
    }
  };

  const handleDelete = (announcement) => {
    setSelectedAnnouncement(announcement);
    setIsDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      await remove(ref(db, `announcements/${selectedAnnouncement.id}`));
      setIsDeleteModalOpen(false);
      setSelectedAnnouncement(null);
    } catch (error) {
      console.error('Announcement deletion error:', error);
    }
  };

  const customStyles = {
    content: {
      maxWidth: '500px',
      width: '90%',
      padding: '24px',
      margin: 'auto',
      border: '1px solid #e5e5e5',
      borderRadius: '8px',
      backgroundColor: '#ffffff'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      zIndex: 1000
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-black">Duyuru Yönetimi</h1>
        <button 
          onClick={() => setIsCreateModalOpen(true)}
          className="px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800"
        >
          Yeni Duyuru
        </button>
      </div>

      <div className="relative">
        <HiOutlineSearch className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500" size={20} />
        <input
          type="text"
          placeholder="Duyuru ara..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:border-black"
        />
      </div>

      {isLoading ? (
        <div className="text-center py-12 text-gray-500">Yükleniyor...</div>
      ) : (
        <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-gray-50 border-b border-gray-200">
                <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Başlık</th>
                <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">İçerik</th>
                <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Tarih</th>
                <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Okunma</th>
                <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">İşlemler</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {filteredAnnouncements.map((announcement) => (
                <tr key={announcement.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 font-medium">{announcement.title}</td>
                  <td className="px-6 py-4 text-gray-600">
                    {announcement.content.length > 50 
                      ? `${announcement.content.substring(0, 50)}...` 
                      : announcement.content}
                  </td>
                  <td className="px-6 py-4 text-gray-600">
                    {new Date(announcement.createdAt).toLocaleDateString('tr-TR')}
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center gap-1 text-gray-600">
                      <HiOutlineEye size={16} />
                      <span>{announcement.okuyanlar?.length || 0}</span>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex gap-2">
                      <button 
                        onClick={() => handleEdit(announcement)}
                        className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
                        title="Düzenle"
                      >
                        <HiOutlinePencil size={18} className="text-gray-600" />
                      </button>
                      <button 
                        onClick={() => handleDelete(announcement)}
                        className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
                        title="Sil"
                      >
                        <HiOutlineTrash size={18} className="text-gray-600" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Yeni Duyuru Modalı */}
      <Modal
        isOpen={isCreateModalOpen}
        onRequestClose={() => setIsCreateModalOpen(false)}
        style={customStyles}
        contentLabel="Yeni Duyuru"
      >
        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-bold">Yeni Duyuru</h2>
            <button 
              onClick={() => setIsCreateModalOpen(false)}
              className="p-2 hover:bg-gray-100 rounded-lg"
            >
              ✕
            </button>
          </div>
          <form onSubmit={handleCreate} className="space-y-4">
            <div>
              <label className="block text-sm font-medium mb-1">Başlık</label>
              <input
                type="text"
                value={formData.title}
                onChange={(e) => setFormData({...formData, title: e.target.value})}
                className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:border-black"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">İçerik</label>
              <textarea
                value={formData.content}
                onChange={(e) => setFormData({...formData, content: e.target.value})}
                className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:border-black h-32 resize-none"
                required
              />
            </div>
            <div className="flex justify-end gap-3 pt-4 border-t border-gray-200">
              <button
                type="button"
                onClick={() => setIsCreateModalOpen(false)}
                className="px-4 py-2 border border-gray-200 text-gray-600 rounded-lg hover:bg-gray-50"
              >
                İptal
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800"
              >
                Oluştur
              </button>
            </div>
          </form>
        </div>
      </Modal>

      {/* Düzenleme Modalı */}
      <Modal
        isOpen={isEditModalOpen}
        onRequestClose={() => setIsEditModalOpen(false)}
        style={customStyles}
        contentLabel="Duyuru Düzenle"
      >
        <div className="space-y-4">
          <h2 className="text-xl font-bold text-black">Duyuru Düzenle</h2>
          <form onSubmit={handleUpdate} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Başlık</label>
              <input
                type="text"
                value={formData.title}
                onChange={(e) => setFormData({...formData, title: e.target.value})}
                className="mt-1 w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:border-black"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">İçerik</label>
              <textarea
                value={formData.content}
                onChange={(e) => setFormData({...formData, content: e.target.value})}
                className="mt-1 w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:border-black h-32"
                required
              />
            </div>
            <div className="flex justify-end gap-3">
              <button
                type="button"
                onClick={() => setIsEditModalOpen(false)}
                className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg"
              >
                İptal
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800"
              >
                Güncelle
              </button>
            </div>
          </form>
        </div>
      </Modal>

      {/* Silme Modalı */}
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
        style={customStyles}
        contentLabel="Duyuru Sil"
      >
        <div className="space-y-4">
          <h2 className="text-xl font-bold text-black">Duyuruyu Sil</h2>
          <p className="text-gray-600">
            "{selectedAnnouncement?.title}" duyurusunu silmek istediğinizden emin misiniz?
            Bu işlem geri alınamaz.
          </p>
          <div className="flex justify-end gap-3">
            <button
              onClick={() => setIsDeleteModalOpen(false)}
              className="px-4 py-2 border border-gray-200 text-gray-600 hover:bg-gray-50 rounded-lg"
            >
              İptal
            </button>
            <button
              onClick={confirmDelete}
              className="px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800"
            >
              Sil
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AdminAnnouncements;
