import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import CVHeader from '../components/CVHeader';

export const DataProcessing = () => {
  return (
    <div className="space-y-6">
      {/* Son Güncelleme */}
      <div className="text-sm text-[var(--grafit-gri)] border-b border-[var(--gri-metalik)]/10 pb-4">
        Son güncelleme tarihi: 1 Mart 2024
      </div>

      {/* Veri Sorumlusu */}
      <section className="space-y-4">
        <h2 className="text-xl font-semibold text-[var(--koyu-komur-gri)]">
          1. Veri Sorumlusu
        </h2>
        <p className="text-[var(--grafit-gri)] leading-relaxed">
          Klaket olarak kişisel verilerinizin güvenliği konusunda azami hassasiyet göstermekteyiz. 6698 sayılı Kişisel Verilerin Korunması Kanunu ("KVKK") uyarınca, kişisel verileriniz aşağıda açıklanan kapsamda işlenebilecektir.
        </p>
      </section>

      {/* Toplanan Veriler */}
      <section className="space-y-4">
        <h2 className="text-xl font-semibold text-[var(--koyu-komur-gri)]">
          2. Toplanan Kişisel Veriler
        </h2>
        <div className="space-y-3">
          <p className="text-[var(--grafit-gri)] leading-relaxed">
            Aşağıdaki kişisel verileriniz tarafımızca toplanmaktadır:
          </p>
          <ul className="list-disc pl-6 space-y-2 text-[var(--grafit-gri)]">
            <li>Kimlik bilgileri (ad, soyad, T.C. kimlik numarası)</li>
            <li>İletişim bilgileri (e-posta, telefon, adres)</li>
            <li>Eğitim ve iş deneyimi bilgileri</li>
            <li>Fotoğraf ve video içerikleri</li>
            <li>Banka hesap bilgileri</li>
            <li>Kullanıcı davranış verileri</li>
          </ul>
        </div>
      </section>

      {/* İşleme Amaçları */}
      <section className="space-y-4">
        <h2 className="text-xl font-semibold text-[var(--koyu-komur-gri)]">
          3. Kişisel Verilerin İşlenme Amaçları
        </h2>
        <div className="space-y-3">
          <p className="text-[var(--grafit-gri)] leading-relaxed">
            Kişisel verileriniz aşağıdaki amaçlarla işlenmektedir:
          </p>
          <ul className="list-disc pl-6 space-y-2 text-[var(--grafit-gri)]">
            <li>Üyelik işlemlerinin gerçekleştirilmesi</li>
            <li>Hizmetlerimizin sunulması ve geliştirilmesi</li>
            <li>İş ve staj başvurularının değerlendirilmesi</li>
            <li>Yasal yükümlülüklerin yerine getirilmesi</li>
            <li>İletişim faaliyetlerinin yürütülmesi</li>
            <li>Güvenliğin sağlanması</li>
          </ul>
        </div>
      </section>

      {/* Veri Güvenliği */}
      <section className="space-y-4">
        <h2 className="text-xl font-semibold text-[var(--koyu-komur-gri)]">
          4. Kişisel Veri Güvenliği
        </h2>
        <p className="text-[var(--grafit-gri)] leading-relaxed">
          Kişisel verilerinizin güvenliğini sağlamak için teknik ve idari tedbirler alınmaktadır. Bu kapsamda:
        </p>
        <ul className="list-disc pl-6 space-y-2 text-[var(--grafit-gri)]">
          <li>SSL şifreleme teknolojisi kullanılmaktadır</li>
          <li>Düzenli güvenlik değerlendirmeleri yapılmaktadır</li>
          <li>Çalışanlarımıza veri güvenliği eğitimleri verilmektedir</li>
          <li>Erişim yetkileri sınırlandırılmıştır</li>
        </ul>
      </section>

      {/* Haklarınız */}
      <section className="space-y-4">
        <h2 className="text-xl font-semibold text-[var(--koyu-komur-gri)]">
          5. KVKK Kapsamındaki Haklarınız
        </h2>
        <p className="text-[var(--grafit-gri)] leading-relaxed">
          KVKK'nın 11. maddesi uyarınca sahip olduğunuz haklar:
        </p>
        <ul className="list-disc pl-6 space-y-2 text-[var(--grafit-gri)]">
          <li>Kişisel verilerinizin işlenip işlenmediğini öğrenme</li>
          <li>Kişisel verileriniz işlenmişse bilgi talep etme</li>
          <li>İşlenme amacını ve amacına uygun kullanılıp kullanılmadığını öğrenme</li>
          <li>Yurt içinde / yurt dışında aktarıldığı üçüncü kişileri bilme</li>
          <li>Eksik / yanlış işlenmişse düzeltilmesini isteme</li>
          <li>KVKK'nın 7. maddesinde öngörülen şartlar çerçevesinde silinmesini isteme</li>
        </ul>
      </section>

      {/* İletişim */}
      <section className="space-y-4">
        <h2 className="text-xl font-semibold text-[var(--koyu-komur-gri)]">
          6. İletişim
        </h2>
        <p className="text-[var(--grafit-gri)] leading-relaxed">
          Kişisel verilerinizle ilgili taleplerinizi <span className="text-[var(--koyu-komur-gri)] font-medium">kvkk@klaket.com</span> adresine iletebilir veya şirket merkezimize yazılı olarak başvurabilirsiniz.
        </p>
      </section>
    </div>
  );
};

export default function DataProcessingPage() {
  const navigate = useNavigate();
  
  return (
    <div className="min-h-screen bg-[var(--kirik-beyaz)]">
      <CVHeader 
        onBack={() => navigate(-1)} 
        title="Kişisel Verilerin İşlenmesi" 
        showSave={false}
      />
      <div className="max-w-3xl mx-auto p-4 md:p-6">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="bg-white rounded-xl p-6 shadow-sm"
        >
          <DataProcessing />
        </motion.div>
      </div>
    </div>
  );
}
