import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { motion } from 'framer-motion';

const FAQ = () => {
  const navigate = useNavigate();
  const [openIndex, setOpenIndex] = useState(null);

  const faqData = [
    {
      question: "Uygulamayı nasıl kullanabilirim?",
      answer: "Uygulamayı kullanmak için önce kayıt olmanız gerekmektedir. Kayıt olduktan sonra, ana sayfadan istediğiniz özelliklere erişebilirsiniz."
    },
    {
      question: "Şifremi unuttum, ne yapmalıyım?",
      answer: "Giriş sayfasında 'Şifremi Unuttum' seçeneğine tıklayarak, kayıtlı e-posta adresinize şifre sıfırlama bağlantısı gönderebilirsiniz."
    },
    {
      question: "Profilimi nasıl güncelleyebilirim?",
      answer: "Profil sayfanıza giderek 'Düzenle' butonuna tıklayabilir ve bilgilerinizi güncelleyebilirsiniz."
    },
    {
      question: "Uygulama ücretli mi?",
      answer: "Uygulamamız temel özellikleri ücretsiz sunmaktadır. Ancak, bazı premium özellikler için ücretli abonelik gerekebilir."
    },
    {
      question: "Hesabımı nasıl silebilirim?",
      answer: "Hesap ayarları sayfasından 'Hesabı Sil' seçeneğini kullanarak hesabınızı kalıcı olarak silebilirsiniz. Bu işlem geri alınamaz."
    }
  ];

  const toggleQuestion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <motion.div 
      className="min-h-screen bg-white flex flex-col"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <motion.div 
        className="flex items-center bg-white p-4 pb-2 justify-between"
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <motion.div
          className="text-[#141414] flex size-12 shrink-0 items-center cursor-pointer"
          onClick={() => navigate(-1)}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24px"
            height="24px"
            fill="currentColor"
            viewBox="0 0 256 256"
          >
            <path d="M224,128a8,8,0,0,1-8,8H59.31l58.35,58.34a8,8,0,0,1-11.32,11.32l-72-72a8,8,0,0,1,0-11.32l72-72a8,8,0,0,1,11.32,11.32L59.31,120H216A8,8,0,0,1,224,128Z"></path>
          </svg>
        </motion.div>
        <h2 className="text-[#141414] text-lg font-bold leading-tight tracking-[-0.015em] flex-1 text-center pr-12">
          Sıkça Sorulan Sorular
        </h2>
      </motion.div>

      <div className="space-y-4">
        {faqData.map((faq, index) => (
          <div key={index} className="border-b border-gray-200 p-4 px-5">
            <button
              className="w-full text-left flex justify-between items-center"
              onClick={() => toggleQuestion(index)}
            >
              <span className="font-semibold">{faq.question}</span>
              {openIndex === index ? <FaChevronUp /> : <FaChevronDown />}
            </button>
            {openIndex === index && (
              <p className="mt-2 text-gray-600">{faq.answer}</p>
            )}
          </div>
        ))}
      </div>
    </motion.div>
  );
};

export default FAQ;
