import React, { useState } from 'react';
import { auth } from '../firebaseconf'; // Firebase auth'u içe aktar
import { useNavigate } from 'react-router-dom'; // Yönlendirme için kullan
import { signInWithEmailAndPassword } from 'firebase/auth'; // Firebase giriş fonksiyonunu içe aktar
import { Container, TextField, Button, Typography, Link, Box, InputAdornment, IconButton, Snackbar, Alert } from '@mui/material'; // Material-UI bileşenlerini içe aktar
import { ArrowBack, Visibility, VisibilityOff } from '@mui/icons-material'; // İkonları doğrudan içe aktar
import { Link as RouterLink } from 'react-router-dom'; // react-router-dom'dan Link'i içe aktar
import KlaketLogo from '../assets/logo.svg'; // Klaket logosunu içe aktar
import { motion } from 'framer-motion';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false); // Şifreyi göster/gizle durumu
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const navigate = useNavigate(); // Yönlendirme için kullan

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/profile'); // Giriş başarılıysa profil sayfasına yönlendir
    } catch (error) {
      let errorMessage = 'Giriş yapılırken bir hata oluştu.';
      if (error.code === 'auth/user-not-found') {
        errorMessage = 'Bu e-posta adresiyle kayıtlı bir kullanıcı bulunamadı.';
      } else if (error.code === 'auth/wrong-password') {
        errorMessage = 'Şifreniz yanlış. Lütfen tekrar deneyin.';
      } else if (error.code === 'auth/invalid-email') {
        errorMessage = 'Geçersiz e-posta adresi.';
      }
      setError(errorMessage);
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <Container component={motion.main}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      maxWidth="xs" 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        height: '100vh', 
        justifyContent: 'space-between', 
        py: 4,
        bgcolor: '#F1F0F0', // Klaket 7: Off White
      }}
    >
      <IconButton
        onClick={() => navigate('/')} // Ana sayfaya yönlendir
        sx={{ alignSelf: 'flex-start', color: '#292929' }} // Klaket 2: Dark Anthracite
        aria-label="ana sayfa"
      >
        <ArrowBack />
      </IconButton>
    
      <Box component="form" onSubmit={handleLogin} sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
        <motion.div
          initial={{ scale: 0.5 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
            <img src={KlaketLogo} alt="Klaket Logo" style={{ width: '70%' }} />
          </Box>
        </motion.div>

        <TextField
          variant="outlined"
          required
          fullWidth
          label="E-posta adresi"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputProps={{
            sx: { borderRadius: 2, bgcolor: '#F1F0F0' }
          }}
        />
        
        <TextField
          variant="outlined"
          required
          fullWidth
          label="Şifre"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            sx: { borderRadius: 2, bgcolor: '#F1F0F0' },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ 
            mt: 2, 
            mb: 2, 
            bgcolor: '#292929', // Klaket 2: Dark Anthracite
            '&:hover': { bgcolor: '#4A494A' }, // Klaket 4: Graphite Gray
            color: '#F1F0F0', // Klaket 7: Off White
            fontWeight: 'bold',
            py: 1.5,
            borderRadius: 2,
            textTransform: 'none',
            fontSize: '1rem'
          }} 
        >
          Giriş Yap
        </Button>

        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
          <Link component={RouterLink} to="/reset-password" variant="body2" sx={{ color: '#4A494A', textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}>
            Şifremi Unuttum
          </Link>
          <Typography variant="body2" sx={{ color: '#4A494A' }}>
            Hesabınız yok mu?{' '}
            <Link component={RouterLink} to="/player-register" sx={{ color: '#292929', textDecoration: 'none', fontWeight: 'bold', '&:hover': { textDecoration: 'underline' } }}>
              Kayıt Ol
            </Link>
          </Typography>
        </Box>
      </Box>

      <Typography variant="body2" color="#4A494A" sx={{ mt: 4, borderTop: '1px solid', borderColor: '#4A494A', pt: 2, width: '100%', textAlign: 'center' }}>
        © 2024 Klaket
      </Typography>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%', bgcolor: '#292929', color: '#F1F0F0' }}>
          {error}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Login;
