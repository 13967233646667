import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaEnvelope, FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { motion } from 'framer-motion';

const Contact = () => {
  const navigate = useNavigate();

  return (
    <motion.div 
      className="min-h-screen bg-white flex flex-col"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <motion.div 
        className="flex items-center bg-white p-4 pb-2 justify-between"
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <motion.div
          className="text-[#141414] flex size-12 shrink-0 items-center cursor-pointer"
          onClick={() => navigate(-1)}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24px"
            height="24px"
            fill="currentColor"
            viewBox="0 0 256 256"
          >
            <path d="M224,128a8,8,0,0,1-8,8H59.31l58.35,58.34a8,8,0,0,1-11.32,11.32l-72-72a8,8,0,0,1,0-11.32l72-72a8,8,0,0,1,11.32,11.32L59.31,120H216A8,8,0,0,1,224,128Z"></path>
          </svg>
        </motion.div>
        <h2 className="text-[#141414] text-lg font-bold leading-tight tracking-[-0.015em] flex-1 text-center pr-12">
          İletişim
        </h2>
      </motion.div>

      <motion.div 
        className="flex-1 p-4 md:p-6 overflow-y-auto"
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <motion.p 
          className="mb-4"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.3 }}
        >
          Bizimle iletişime geçmek için aşağıdaki bilgileri kullanabilirsiniz:
        </motion.p>

        <motion.h2 
          className="text-xl font-semibold mb-2"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.4 }}
        >
          E-posta Adresimiz
        </motion.h2>
        <motion.p 
          className="mb-4 flex items-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.5 }}
        >
          <FaEnvelope className="mr-2 text-[var(--koyu-komur-gri)]" />
          <a href="mailto:info@example.com" className="text-stone-500 underline">
            info@klaket.com
          </a>
        </motion.p>

        <motion.h2 
          className="text-xl font-semibold mb-2"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.6 }}
        >
          Sosyal Medya Hesaplarımız
        </motion.h2>
        <motion.ul 
          className="list-disc list-inside mb-4"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.7 }}
        >
          <li className="flex items-center">
            <FaFacebook className="mr-2 text-[var(--koyu-komur-gri)]" />
            <a href="https://www.facebook.com/yourpage" target="_blank" rel="noopener noreferrer" className="text-stone-500 underline">
              Facebook
            </a>
          </li>
          <li className="flex items-center">
            <FaTwitter className="mr-2 text-[var(--koyu-komur-gri)]" />
            <a href="https://www.twitter.com/yourpage" target="_blank" rel="noopener noreferrer" className="text-stone-500 underline">
              Twitter
            </a>
          </li>
          <li className="flex items-center">
            <FaInstagram className="mr-2 text-[var(--koyu-komur-gri)]" />
            <a href="https://www.instagram.com/yourpage" target="_blank" rel="noopener noreferrer" className="text-stone-500 underline">
              Instagram
            </a>
          </li>
          <li className="flex items-center">
            <FaLinkedin className="mr-2 text-[var(--koyu-komur-gri)]" />
            <a href="https://www.linkedin.com/in/yourpage" target="_blank" rel="noopener noreferrer" className="text-stone-500 underline">
              LinkedIn
            </a>
          </li>
        </motion.ul>

        <motion.p 
          className="mb-4"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.8 }}
        >
          Herhangi bir sorunuz veya öneriniz varsa, yukarıdaki iletişim bilgilerini kullanarak bizimle iletişime geçebilirsiniz.
        </motion.p>
      </motion.div>
    </motion.div>
  );
};

export default Contact;
