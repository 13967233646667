import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../firebaseconf';
import { signOut } from 'firebase/auth';
import { getDatabase, ref, get } from 'firebase/database';

const AuthContext = createContext();
const db = getDatabase();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (firebaseUser) => {
      if (firebaseUser) {
        try {
          const userRef = ref(db, `users/${firebaseUser.uid}`);
          const snapshot = await get(userRef);
          
          if (snapshot.exists()) {
            const userData = snapshot.val();
            setUser({
              uid: firebaseUser.uid,
              role: userData.role || 'user',
              firstName: userData.firstName || '',
              lastName: userData.lastName || '',
              email: userData.email || firebaseUser.email,
              profileImage: userData.profileImage || null,
              title: userData.title || null,
              isApproved: userData.isApproved || false,
              createdAt: userData.createdAt || null,
              lastLoginAt: userData.lastLoginAt || null,
              phoneNumber: userData.phoneNumber || null,
              // Diğer kullanıcı bilgileri buraya eklenebilir
            });
          } else {
            setUser({
              uid: firebaseUser.uid,
              email: firebaseUser.email,
              role: 'user',
            });
          }
        } catch (error) {
          console.error("Kullanıcı bilgileri alınırken hata:", error);
          setUser({
            uid: firebaseUser.uid,
            email: firebaseUser.email,
            role: 'user',
          });
        }
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const logout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      // Çıkış sonrası temizlik işlemleri buraya eklenebilir
      return true;
    } catch (error) {
      console.error("Çıkış yapılırken hata:", error);
      return false;
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-[#F1F0F0]">
        <div className="animate-spin rounded-full h-8 w-8 border-2 border-[#4A494A] border-t-transparent"></div>
      </div>
    );
  }

  const contextValue = {
    user,
    logout,
    isAdmin: user?.role === 'admin',
    isProduction: user?.role === 'production',
    isUser: user?.role === 'user',
    isAuthenticated: !!user,
    isApproved: user?.isApproved || false,
    loading
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

// Yardımcı hook'lar
export const useIsAdmin = () => {
  const { user } = useAuth();
  return user?.role === 'admin';
};

export const useIsProduction = () => {
  const { user } = useAuth();
  return user?.role === 'production';
};

export const useIsApproved = () => {
  const { user } = useAuth();
  return user?.isApproved || false;
};

export const useUserRole = () => {
  const { user } = useAuth();
  return user?.role || null;
};
