import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebaseconf';
import { ref, push } from 'firebase/database';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { HiOutlineCamera, HiOutlineX } from 'react-icons/hi';

const ProjectAdd = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    date: '',
    startTime: '',
    endTime: '',
    location: '',
    city: '',
    budget: '',
    projectType: '',
    status: 'active',
    images: [],
    requirements: {
      gender: '',
      ageMin: '',
      ageMax: '',
      height: '',
      weight: '',
      hairColor: '',
      eyeColor: '',
      experience: '',
      languages: [],
      skills: []
    },
    contact: {
      name: '',
      email: '',
      phone: ''
    }
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setFormData(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleImageUpload = async (e) => {
    const files = Array.from(e.target.files);
    if (files.length + formData.images.length > 5) {
      alert('En fazla 5 görsel yükleyebilirsiniz');
      return;
    }

    setLoading(true);
    const storage = getStorage();
    const uploadPromises = files.map(async (file) => {
      const imageRef = storageRef(storage, `projects/${Date.now()}_${file.name}`);
      await uploadBytes(imageRef, file);
      return getDownloadURL(imageRef);
    });

    try {
      const urls = await Promise.all(uploadPromises);
      setFormData(prev => ({
        ...prev,
        images: [...prev.images, ...urls]
      }));
    } catch (error) {
      console.error('Görsel yükleme hatası:', error);
    } finally {
      setLoading(false);
    }
  };

  const removeImage = (index) => {
    setFormData(prev => ({
      ...prev,
      images: prev.images.filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const projectRef = ref(db, 'projects');
      await push(projectRef, {
        ...formData,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      });
      navigate('/admin/projects');
    } catch (error) {
      console.error('Proje ekleme hatası:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-full mx-auto">
      <div className="flex items-center justify-between mb-8">
        <h1 className="text-2xl font-semibold">Yeni Proje Ekle</h1>
        <button
          onClick={() => navigate('/admin/projects')}
          className="px-4 py-2 text-sm border border-gray-200 rounded-lg hover:bg-gray-50"
        >
          Geri Dön
        </button>
      </div>

      <form onSubmit={handleSubmit} className="space-y-8">
        {/* Temel Bilgiler */}
        <section className="bg-white rounded-xl p-6 shadow-sm">
          <h2 className="text-lg font-medium mb-6">Temel Bilgiler</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium mb-2">Proje Başlığı</label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                className="w-full px-4 py-2.5 border border-gray-200 rounded-lg focus:outline-none focus:border-black"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">Proje Tipi</label>
              <select
                name="projectType"
                value={formData.projectType}
                onChange={handleInputChange}
                className="w-full px-4 py-2.5 border border-gray-200 rounded-lg focus:outline-none focus:border-black"
                required
              >
                <option value="">Seçiniz</option>
                <option value="film">Film</option>
                <option value="dizi">Dizi</option>
                <option value="reklam">Reklam</option>
                <option value="katalog">Katalog</option>
                <option value="diger">Diğer</option>
              </select>
            </div>
            <div className="md:col-span-2">
              <label className="block text-sm font-medium mb-2">Proje Açıklaması</label>
              <textarea
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                rows="4"
                className="w-full px-4 py-2.5 border border-gray-200 rounded-lg focus:outline-none focus:border-black"
                required
              />
            </div>
          </div>
        </section>

        {/* Zaman ve Lokasyon */}
        <section className="bg-white rounded-xl p-6 shadow-sm">
          <h2 className="text-lg font-medium mb-6">Zaman ve Lokasyon</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div>
              <label className="block text-sm font-medium mb-2">Tarih</label>
              <input
                type="date"
                name="date"
                value={formData.date}
                onChange={handleInputChange}
                className="w-full px-4 py-2.5 border border-gray-200 rounded-lg focus:outline-none focus:border-black"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">Başlangıç Saati</label>
              <input
                type="time"
                name="startTime"
                value={formData.startTime}
                onChange={handleInputChange}
                className="w-full px-4 py-2.5 border border-gray-200 rounded-lg focus:outline-none focus:border-black"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">Bitiş Saati</label>
              <input
                type="time"
                name="endTime"
                value={formData.endTime}
                onChange={handleInputChange}
                className="w-full px-4 py-2.5 border border-gray-200 rounded-lg focus:outline-none focus:border-black"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">Şehir</label>
              <input
                type="text"
                name="city"
                value={formData.city}
                onChange={handleInputChange}
                className="w-full px-4 py-2.5 border border-gray-200 rounded-lg focus:outline-none focus:border-black"
                required
              />
            </div>
            <div className="md:col-span-2">
              <label className="block text-sm font-medium mb-2">Adres</label>
              <input
                type="text"
                name="location"
                value={formData.location}
                onChange={handleInputChange}
                className="w-full px-4 py-2.5 border border-gray-200 rounded-lg focus:outline-none focus:border-black"
                required
              />
            </div>
          </div>
        </section>

        {/* Görseller */}
        <section className="bg-white rounded-xl p-6 shadow-sm">
          <h2 className="text-lg font-medium mb-6">Proje Görselleri</h2>
          <div className="space-y-4">
            <div className="flex flex-wrap gap-4">
              {formData.images.map((url, index) => (
                <div key={index} className="relative group">
                  <img src={url} alt="" className="w-24 h-24 object-cover rounded-lg" />
                  <button
                    type="button"
                    onClick={() => removeImage(index)}
                    className="absolute -top-2 -right-2 p-1 bg-black text-white rounded-full 
                      opacity-0 group-hover:opacity-100 transition-opacity"
                  >
                    <HiOutlineX size={16} />
                  </button>
                </div>
              ))}
              {formData.images.length < 5 && (
                <label className="w-24 h-24 flex items-center justify-center border-2 border-dashed 
                  border-gray-200 rounded-lg cursor-pointer hover:bg-gray-50 transition-colors">
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={handleImageUpload}
                    className="hidden"
                  />
                  <HiOutlineCamera size={24} className="text-gray-400" />
                </label>
              )}
            </div>
            <p className="text-sm text-gray-500">En fazla 5 görsel yükleyebilirsiniz</p>
          </div>
        </section>

        {/* Gereksinimler */}
        <section className="bg-white rounded-xl p-6 shadow-sm">
          <h2 className="text-lg font-medium mb-6">Oyuncu Gereksinimleri</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium mb-2">Cinsiyet</label>
              <select
                name="requirements.gender"
                value={formData.requirements.gender}
                onChange={handleInputChange}
                className="w-full px-4 py-2.5 border border-gray-200 rounded-lg focus:outline-none focus:border-black"
              >
                <option value="">Seçiniz</option>
                <option value="male">Erkek</option>
                <option value="female">Kadın</option>
                <option value="both">Farketmez</option>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">Deneyim</label>
              <select
                name="requirements.experience"
                value={formData.requirements.experience}
                onChange={handleInputChange}
                className="w-full px-4 py-2.5 border border-gray-200 rounded-lg focus:outline-none focus:border-black"
              >
                <option value="">Seçiniz</option>
                <option value="beginner">Başlangıç</option>
                <option value="intermediate">Orta</option>
                <option value="advanced">İleri</option>
                <option value="professional">Profesyonel</option>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">Minimum Yaş</label>
              <input
                type="number"
                name="requirements.ageMin"
                value={formData.requirements.ageMin}
                onChange={handleInputChange}
                className="w-full px-4 py-2.5 border border-gray-200 rounded-lg focus:outline-none focus:border-black"
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">Maksimum Yaş</label>
              <input
                type="number"
                name="requirements.ageMax"
                value={formData.requirements.ageMax}
                onChange={handleInputChange}
                className="w-full px-4 py-2.5 border border-gray-200 rounded-lg focus:outline-none focus:border-black"
              />
            </div>
          </div>
        </section>

        {/* İletişim Bilgileri */}
        <section className="bg-white rounded-xl p-6 shadow-sm">
          <h2 className="text-lg font-medium mb-6">İletişim Bilgileri</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div>
              <label className="block text-sm font-medium mb-2">İletişim Kişisi</label>
              <input
                type="text"
                name="contact.name"
                value={formData.contact.name}
                onChange={handleInputChange}
                className="w-full px-4 py-2.5 border border-gray-200 rounded-lg focus:outline-none focus:border-black"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">E-posta</label>
              <input
                type="email"
                name="contact.email"
                value={formData.contact.email}
                onChange={handleInputChange}
                className="w-full px-4 py-2.5 border border-gray-200 rounded-lg focus:outline-none focus:border-black"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">Telefon</label>
              <input
                type="tel"
                name="contact.phone"
                value={formData.contact.phone}
                onChange={handleInputChange}
                className="w-full px-4 py-2.5 border border-gray-200 rounded-lg focus:outline-none focus:border-black"
                required
              />
            </div>
          </div>
        </section>

        {/* Submit Button */}
        <div className="flex justify-end gap-4">
          <button
            type="button"
            onClick={() => navigate('/admin/projects')}
            className="px-6 py-3 text-sm border border-gray-200 rounded-xl hover:bg-gray-50"
          >
            İptal
          </button>
          <button
            type="submit"
            disabled={loading}
            className="px-6 py-3 bg-black text-white rounded-xl hover:bg-gray-800 disabled:opacity-50"
          >
            {loading ? 'Kaydediliyor...' : 'Projeyi Kaydet'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ProjectAdd;
