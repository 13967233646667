import React, { useEffect, useState } from 'react';
import { getDatabase, ref, get, update, remove } from 'firebase/database';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaSpinner, FaArrowLeft, FaPhone, FaEnvelope, FaMapMarkerAlt, FaHome, FaSearch, FaBell, FaComments, FaUser, FaPlay } from 'react-icons/fa';
import { useAuth } from '../context/AuthContext';

const db = getDatabase();

function formatPhoneNumber(phoneNumber) {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return phoneNumber;
}

const translateKey = (key) => {
  const translations = {
    'beardStyle': 'Sakal Stili',
    'birthDate': 'Doğum Tarihi',
    'bodySize': 'Beden',
    'city': 'Şehir',
    'department': 'Bölüm',
    'endYear': 'Bitiş Yılı',
    'eyeColor': 'Göz Rengi',
    'gender': 'Cinsiyet',
    'hairColor': 'Saç Rengi',
    'hairStyle': 'Saç Stili',
    'height': 'Boy',
    'institution': 'Kurum',
    'shoeSize': 'Ayakkabı Numarası',
    'skinTone': 'Ten Rengi',
    'startYear': 'Başlangıç Yılı',
    'weight': 'Kilo'
  };

  return translations[key] || key.replace(/([A-Z])/g, ' $1').trim();
};

// Değerleri formatlama fonksiyonu
const formatValue = (key, value) => {
  if (key === 'height') return `${value} cm`;
  if (key === 'weight') return `${value} kg`;
  if (key === 'birthDate') {
    const date = new Date(value);
    return date.toLocaleDateString('tr-TR');
  }
  return value;
};

const PlayerDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [player, setPlayer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSelected, setIsSelected] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    const fetchPlayerData = async () => {
      if (!user) {
        navigate('/login');
        return;
      }

      try {
        // Oyuncunun bilgilerini al
        const playerRef = ref(db, `users/${id}`);
        const playerSnapshot = await get(playerRef);
        
        // Seçili oyuncular listesini kontrol et
        const selectedRef = ref(db, `users/${user.uid}/selectedPlayers/${id}`);
        const selectedSnapshot = await get(selectedRef);
        
        if (playerSnapshot.exists()) {
          setPlayer(playerSnapshot.val());
          setIsSelected(selectedSnapshot.exists());
        }
      } catch (error) {
        console.error("Veri çekilirken hata oluştu:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPlayerData();
  }, [id, user, navigate]);

  const handleSelectPlayer = async () => {
    if (!user || isUpdating) return;

    setIsUpdating(true);
    try {
      const selectedRef = ref(db, `users/${user.uid}/selectedPlayers/${id}`);
      
      if (isSelected) {
        // Seçili ise kaldır
        await remove(selectedRef);
        setIsSelected(false);
      } else {
        // Seçili değilse ekle
        const playerData = {
          id: id,
          firstName: player.firstName,
          lastName: player.lastName,
          profileImage: player.profileImage || player.profilecv?.profileImage,
          position: player.profilecv?.globalcv?.position || 'Pozisyon belirtilmemiş',
          birthDate: player.profilecv?.globalcv?.birthDate,
          city: player.profilecv?.globalcv?.city,
          tcno: player.profilecv?.globalcv?.tcNo || player.tcno || 'Belirtilmemiş',
          phone: player.phone || player.profilecv?.globalcv?.phone || 'Belirtilmemiş'
        };
        await update(selectedRef, playerData);
        setIsSelected(true);
      }
    } catch (error) {
      console.error("İşlem başarısız:", error);
      alert('Bir hata oluştu. Lütfen tekrar deneyin.');
    } finally {
      setIsUpdating(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <FaSpinner className="animate-spin text-4xl text-blue-500" />
      </div>
    );
  }

  if (!user) {
    return null;
  }

  if (user.role !== 'admin' && user.role !== 'production') {
    return null;
  }

  if (!player) {
    return <div className="text-center p-8">Oyuncu bulunamadı.</div>;
  }

  return (
    <div className="relative flex min-h-screen flex-col bg-neutral-50 justify-between overflow-x-hidden font-sans pb-16" >
      <div>
        <div className="flex items-center bg-neutral-50 p-4 pb-2 justify-between">
          <div className="text-[#141414] flex size-12 shrink-0 items-center cursor-pointer" onClick={() => navigate(-1)}>
            <FaArrowLeft size={24} />
          </div>
          <h2 className="text-[#141414] text-lg font-bold leading-tight tracking-[-0.015em] flex-1 text-center pr-12">{player.firstName} {player.lastName}</h2>
        </div>
        <div className="flex p-4">
          <div className="flex w-full flex-col gap-4 items-start">
            <div className="flex gap-4 flex-col items-start">
              <div
                className="bg-center bg-no-repeat aspect-square bg-cover rounded-full min-h-32 w-32"
                style={{ backgroundImage: `url(${player.profileImage || player.profilecv?.profileImage || 'https://via.placeholder.com/150'})` }}
              ></div>
              <div className="flex flex-col justify-center">
                <p className="text-[#141414] text-[22px] font-bold leading-tight tracking-[-0.015em]">
                  {player.firstName} {player.lastName}
                </p>
                <p className="text-neutral-500 text-base font-normal leading-normal">
                  {player.profilecv?.globalcv?.position || 'Pozisyon belirtilmemiş'}
                </p>
                
                {/* İletişim bilgilerini sadece admin görebilir */}
                {user && user.role === 'admin' && (
                  <>
                    {player.phone && (
                      <p className="text-neutral-500 text-sm font-normal leading-normal flex items-center mt-1">
                        <span className="bg-gray-200 p-1 rounded-full mr-2">
                          <FaPhone className="text-stone-500" size={14} />
                        </span>
                        <a href={`tel:${player.phone}`} className="hover:text-blue-500 transition-colors">
                          {formatPhoneNumber(player.phone)}
                        </a>
                      </p>
                    )}
                    {player.email && (
                      <p className="text-neutral-500 text-sm font-normal leading-normal flex items-center mt-1">
                        <span className="bg-gray-200 p-1 rounded-full mr-2">
                          <FaEnvelope className="text-stone-500" size={14} />
                        </span>
                        <a href={`mailto:${player.email}`} className="hover:text-blue-500 transition-colors">
                          {player.email}
                        </a>
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
            {user && (user.role === 'production' || user.role === 'admin') && (
              <button 
                onClick={handleSelectPlayer}
                disabled={isUpdating}
                className={`flex items-center justify-center rounded-lg px-4 py-2 transition-colors
                  ${isSelected 
                    ? 'bg-[#4A494A] text-white hover:bg-[#1F1F1F]' 
                    : 'bg-[#F1F0F0] text-[#1F1F1F] hover:bg-[#A2A1A1]/20'
                  } ${isUpdating ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
              >
                {isUpdating ? (
                  <FaSpinner className="animate-spin" />
                ) : (
                  isSelected ? 'Seçimi Kaldır' : 'Oyuncuyu Seç'
                )}
              </button>
            )}
          
          </div>
        </div>
        {player.profilecv?.globalcv && (
          <div className="bg-white rounded-lg shadow-md p-4 mx-4 my-6">
            <h2 className="text-xl font-bold mb-4 text-gray-800">CV Bilgileri</h2>
            <div className="grid grid-cols-2 gap-4">
              {Object.entries(player.profilecv.globalcv).map(([key, value]) => {
                // Özel durumlar için kontroller
                if (key === 'bio' || key === 'description') return null; // Biyografiyi ayrı göstereceğiz
                if (typeof value === 'object') return null; // Nesne tipindeki değerleri atlıyoruz

                return (
                  <div key={key} className="flex flex-col">
                    <p className="text-gray-600 text-sm font-medium">
                      {translateKey(key)}
                    </p>
                    <p className="text-gray-800 font-semibold">
                      {formatValue(key, value) || 'Belirtilmemiş'}
                    </p>
                  </div>
                );
              })}
            </div>
            {(player.profilecv.globalcv.bio || player.profilecv.globalcv.description) && (
              <div className="mt-6">
                <p className="text-gray-600 text-sm font-medium">Biyografi</p>
                <p className="text-gray-800 mt-2">{player.profilecv.globalcv.bio || player.profilecv.globalcv.description}</p>
              </div>
            )}
          </div>
        )}
        <h2 className="text-[#141414] text-[22px] font-bold leading-tight tracking-[-0.015em] px-4 pb-3 pt-5">Fotoğraflar</h2>
        <div className="p-4">
          <div className="grid grid-cols-3 gap-4 h-[300px]">
            {player.profilecv?.kolaj?.photo1 && (
              <div 
                className="w-full h-full bg-center bg-no-repeat bg-cover rounded-xl col-span-2"
                style={{ backgroundImage: `url(${player.profilecv.kolaj.photo1})` }}
              ></div>
            )}
            <div className="grid grid-rows-2 gap-4">
              {player.profilecv?.kolaj?.photo2 && (
                <div 
                  className="w-full h-full bg-center bg-no-repeat bg-cover rounded-xl"
                  style={{ backgroundImage: `url(${player.profilecv.kolaj.photo2})` }}
                ></div>
              )}
              {player.profilecv?.kolaj?.photo3 && (
                <div 
                  className="w-full h-full bg-center bg-no-repeat bg-cover rounded-xl"
                  style={{ backgroundImage: `url(${player.profilecv.kolaj.photo3})` }}
                ></div>
              )}
            </div>
          </div>
        </div>
        {player.profilecv?.introVideo && (
          <>
            <h2 className="text-[#141414] text-[22px] font-bold leading-tight tracking-[-0.015em] px-4 pb-3 pt-5">Tanıtım Videosu</h2>
            <div className="p-4">
              <div className="relative aspect-video rounded-xl overflow-hidden">
                <video
                  className="w-full h-full object-cover"
                  poster={player.profilecv.introVideoThumbnail} // Eğer bir thumbnail varsa
                  controls
                >
                  <source src={player.profilecv.introVideo} type="video/mp4" />
                  Tarayıcınız video etiketini desteklemiyor.
                </video>
              </div>
            </div>
          </>
        )}
      </div>
       
    </div>
  );
};

export default PlayerDetail;
